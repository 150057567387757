import { PositionRelative } from 'cdk'
import styled from 'styled-components'
import { APP_BAR_HEIGHT } from '../AppBar/constants'

export const FlowWrapper = styled(PositionRelative).attrs({
  $grow: 1,
  $shrink: 1,
  $overflow: 'hidden',
  $maxHeight: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
})`
  height: calc(100vh - 144px);
`

FlowWrapper.displayName = 'FormFlowWrapper'
