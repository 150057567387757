import { endOfDay, startOfDay } from 'date-fns/fp'
import { endOfWeek, startOfWeek } from 'dates'
import { pipe } from 'fp-ts/function'
import { View } from 'react-big-calendar'

export const getCalendarDateRange = (date: number | Date, view: View = 'week'): Interval => {
  if (view === 'day') {
    return {
      end: pipe(date, endOfDay),
      start: pipe(date, startOfDay),
    }
  }

  return {
    end: pipe(date, endOfWeek),
    start: pipe(date, startOfWeek),
  }
}
