import { useCallback } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { Avatar, Text } from 'ui'
import { Flex, Pressable } from 'ui-deprecated'
import { DateTime } from '~/components/DateTime'
import { RecurrencyTime } from '~/components/RecurrencyTime'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useModals } from '~/domains/modals'
import { TherapistProfileModal } from '~/domains/therapist/components/TherapistProfileModal'
import { TranslationId } from '~/i18n/types'
import { TherapyTherapyPathType } from '~/types/graphql'
import { getRoute } from '~/utils/getRoute'
import { useTherapySession } from '../../../hooks/useTherapySession'
import { EditAndDeleteAppointmentButtons } from './EditAndDeleteAppointmentButtons'

type AppointmentDetailsProps = {
  canManage?: boolean
}

export const AppointmentDetails = ({ canManage = false }: AppointmentDetailsProps) => {
  const { endAt, repeatEveryWeek, startAt, therapist, therapyPathType } = useTherapySession()
  const { open } = useModals()
  const { pathname } = useLocation()

  const therapySessionTypeMap: Record<
    TherapyTherapyPathType,
    Extract<TranslationId, `therapySessions.item.therapySessionType.${string}`>
  > = {
    COUPLES_PSYCHOTHERAPY: 'therapySessions.item.therapySessionType.couples',
    MYSELF_PSYCHIATRY: 'therapySessions.item.therapySessionType.psychiatry',
    MYSELF_PSYCHOTHERAPY: 'therapySessions.item.therapySessionType.individual',
    PATH_ASSERTIVE_COMMUNICATION: 'therapySessions.item.therapySessionType.assertiveCommunication',
    PATH_COACHING: 'therapySessions.item.therapySessionType.coaching',
    PATH_SEXOLOGY: 'therapySessions.item.therapySessionType.sexology', // FIXME: https://linear.app/serenis/issue/PRD-5756/excludetherapypathtype-path-sexology-app-web
    PATH_SLEEP: 'therapySessions.item.therapySessionType.sleep',
    UNDERAGE_PSYCHOTHERAPY: 'therapySessions.item.therapySessionType.individual',
  }

  const therapySessionTypeTranslationId = therapySessionTypeMap[therapyPathType ?? 'MYSELF_PSYCHOTHERAPY']

  const openTherapistModal = useCallback(() => {
    open(`therapistProfile:${therapist?.id}`)
  }, [therapist?.id, open])

  const isTherapiesPath = !!matchPath(pathname, { path: [getRoute('/therapies')] })

  return (
    <Flex direction="row">
      {!isTherapiesPath && (
        <Flex>
          <Avatar image={therapist?.therapist?.profileImage?.s} name={therapist?.fullName} size="sm" />
        </Flex>
      )}
      <Flex pl={isTherapiesPath ? 0 : 16} shrink={1}>
        <Flex pb={4}>
          <TranslationMarkdown colorName="grey-11" id={therapySessionTypeTranslationId} kind="paragraph" />
        </Flex>

        {!isTherapiesPath && (
          <Pressable
            align="flex-start"
            colorName="purple08"
            colorNameHover="purple08"
            data-test-id="therapist-profile-link"
            onClick={openTherapistModal}
          >
            <Text colorName="purple-08" fontWeight="600" kind="paragraph" textDecoration="underline">
              {therapist?.fullName}
            </Text>
          </Pressable>
        )}

        {!!startAt && !!endAt && (
          <Flex pt={4}>
            <DateTime endAt={endAt} startAt={startAt} />
          </Flex>
        )}
        {repeatEveryWeek && startAt && (
          <Flex pt={4}>
            <RecurrencyTime repeatEveryWeek={repeatEveryWeek} startAt={startAt} />
          </Flex>
        )}
        {canManage && (
          <Flex pt={16}>
            <EditAndDeleteAppointmentButtons />
          </Flex>
        )}
      </Flex>

      <TherapistProfileModal therapyPathType={therapyPathType ?? null} />
    </Flex>
  )
}
