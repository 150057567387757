import { gql, useQuery } from '@apollo/client'
import { MatchedTherapistsForCurrentUserQuery } from '~/types/graphql'

const QUERY = gql`
  query matchedTherapistsForCurrentUser {
    matchedTherapistsForCurrentUser {
      id
      matchedTherapistIds
      suggestedTherapistsIds
      suggestedTherapistsIdsChanged
    }
  }
`

export const useMatchedTherapistsForCurrentUser = () => {
  const { data, error, loading } = useQuery<MatchedTherapistsForCurrentUserQuery>(QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  const matchedTherapistIds = data?.matchedTherapistsForCurrentUser?.matchedTherapistIds || []

  const suggestedTherapistsIds = data?.matchedTherapistsForCurrentUser?.suggestedTherapistsIds || []

  const suggestedTherapistsIdsChanged = data?.matchedTherapistsForCurrentUser?.suggestedTherapistsIdsChanged || false

  return {
    error,
    loading,
    matchedTherapistIds,
    suggestedTherapistsIds,
    suggestedTherapistsIdsChanged,
  }
}
