import { ReactNode, useCallback, useEffect, useState } from 'react'
import { Switch, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Flex } from 'ui-deprecated'

type Props = {
  children: ReactNode
}

type State = 'fade-in' | 'fade-out'

const Transition = styled(Flex).attrs({
  grow: 1,
  shrink: 1,
})`
  @keyframes fade-in {
    from {
      opacity: 0;
      transform: translate(24px, 0);
    }

    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  @keyframes fade-out {
    from {
      opacity: 1;
      transform: translate(0, 0);
    }

    to {
      opacity: 0;
      transform: translate(24px, 0);
    }
  }

  &.fade-in {
    animation: 0.3s fade-in forwards;
  }

  &.fade-out {
    animation: 0.3s fade-out forwards;
  }
`

export const FlowMemoryRouterTransition = ({ children }: Props) => {
  const location = useLocation()

  const [displayLocation, setDisplayLocation] = useState(location)

  const [transition, setTransition] = useState<State>('fade-in')

  useEffect(() => {
    if (location !== displayLocation) {
      setTransition('fade-out')
    }
  }, [displayLocation, location])

  const onAnimationEnd = useCallback(() => {
    if (transition === 'fade-in') {
      return
    }

    setTransition('fade-in')
    setDisplayLocation(location)
  }, [location, transition])

  return (
    <Transition className={transition} onAnimationEnd={onAnimationEnd} pb={0}>
      <Switch location={displayLocation}>{children}</Switch>
    </Transition>
  )
}
