import { colors } from '@serenis-health/tokens'
import { Theme } from 'react-select'
import { SelectSize } from '~/components/SelectNew/types'

export const theme =
  (size: SelectSize) =>
  (theme: Theme): Theme => ({
    borderRadius: 8,
    colors: {
      ...theme.colors,
      danger: colors.coral08,
      neutral0: colors.white,
      neutral20: colors.grey04,
      neutral50: colors.grey10,
      primary: colors.purple08,
      primary25: colors.purple02,
    },
    spacing: {
      baseUnit: 8,
      controlHeight: size === 'medium' ? 60 : 48,
      menuGutter: 8,
    },
  })
