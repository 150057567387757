import { Icon } from 'icons'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'ui'
import { Flex, OverflowAuto, Pressable } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useEmailVerificationContext } from '../hooks/useEmailVerificationContext'
import { getEmailVerificationRoute } from '../utils/getEmailVerificationRoute'

export const EmailVerificationModalDefaultRoute = () => {
  const { email } = useCurrentUser()
  const { emailVerificationRequest } = useEmailVerificationContext()
  const history = useHistory()

  const onRetry = useCallback(async () => {
    await emailVerificationRequest(email)
  }, [email, emailVerificationRequest])

  const onEdit = useCallback(() => {
    history.push(getEmailVerificationRoute('/edit'))
  }, [history])

  return (
    <OverflowAuto>
      <Text fontWeight="600" kind="h2">
        <Translation id="emailVerification.default.title" />
      </Text>

      <Flex pt={16}>
        <TranslationMarkdown
          colorName="black"
          id="emailVerification.default.description1"
          kind="paragraph"
          values={{ email }}
        />
      </Flex>

      <Flex pt={16}>
        <Text fontWeight="400" kind="paragraph">
          <Translation id="emailVerification.default.description2" />
        </Text>
      </Flex>

      <Flex pt={32}>
        <Pressable onClick={onRetry}>
          <Flex align="center" borderColor="purple06" borderRadius={8} borderSize={1} direction="row" p={16}>
            <Icon colorName="grey-11" name="mail" size={32} />

            <Flex align="flex-start" grow={1} pl={16}>
              <Text fontWeight="600" kind="paragraph">
                <Translation id="emailVerification.default.retry.title" />
              </Text>

              <Flex pt={8}>
                <Text colorName="purple-08" fontWeight="600" kind="footnote" textDecoration="underline">
                  <Translation id="emailVerification.default.retry.subtitle" />
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Pressable>
      </Flex>

      <Flex pt={16}>
        <Pressable onClick={onEdit}>
          <Flex align="center" borderColor="purple06" borderRadius={8} borderSize={1} direction="row" p={16}>
            <Icon colorName="grey-11" name="edit-pencil" size={32} />

            <Flex align="flex-start" grow={1} pl={16}>
              <Text fontWeight="600" kind="paragraph">
                <Translation id="emailVerification.default.edit.title" />
              </Text>

              <Flex pt={8}>
                <Text colorName="purple-08" fontWeight="600" kind="footnote" textDecoration="underline">
                  <Translation id="emailVerification.default.edit.subtitle" />
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Pressable>
      </Flex>
    </OverflowAuto>
  )
}
