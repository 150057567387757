import { Icon } from 'icons'
import { Badge } from 'ui'
import { useIntercomState } from '~/domains/intercom/hooks/useIntercomState'

export const IntercomWithBadge = () => {
  const { unreadCount } = useIntercomState()

  return (
    <Badge sup={unreadCount}>
      <Icon colorName="grey-08" name="intercom" size={20} />
    </Badge>
  )
}
