import { useCallback, useRef } from 'react'

export const useScrollIntoViewOnce = () => {
  const scrolled = useRef(false)
  const scrollElementRef = useRef<HTMLDivElement | null>(null)

  const scrollIntoViewOnce = useCallback(() => {
    if (scrolled.current) {
      return
    }

    scrolled.current = true

    scrollElementRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [scrolled, scrollElementRef])

  return {
    scrolled,
    scrollElementRef,
    scrollIntoViewOnce,
  }
}
