import { matchPath, useParams } from 'react-router-dom'
import { getRoute } from '~/utils/getRoute'
import { useRootHistory } from './useRootHistory'

type Param = {
  patientId: string
}

export const usePatientId = () => {
  const { patientId } = useParams<Param>()

  const { location } = useRootHistory()

  const match = matchPath<Partial<Param>>(location.pathname, {
    path: [
      getRoute('/chat/:patientId'),
      getRoute('/chat/:patientId/detail'),
      getRoute('/patients/:patientId/diagnosis'),
      getRoute('/patients/:patientId/diagnosis/edit'),
      getRoute('/patients/:patientId/diary'),
      getRoute('/patients/:patientId/journaling'),
      getRoute('/patients/:patientId/therapy-sessions'),
      getRoute('/patients/:patientId/therapy-session/:therapyId/schedule'),
    ],
  })

  return patientId ?? match?.params?.patientId
}
