import { useCallback } from 'react'
import { Text } from 'ui'
import { Flex, Pressable } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { useLanguage } from '../hooks/useLanguage'

export const MenuLanguageSwitcher = () => {
  const { language, setLanguage } = useLanguage()

  const onSetItalianLanguage = useCallback(() => setLanguage('it'), [setLanguage])
  const onSetEnglishLanguage = useCallback(() => setLanguage('en'), [setLanguage])

  const enSelected = language === 'en'
  const itSelected = language === 'it'

  return (
    <Flex align="center" direction="row" justify="center">
      <Pressable autoWidth onClick={onSetItalianLanguage}>
        <Text colorName="purple-08" fontWeight={itSelected ? '600' : '400'} kind="paragraph">
          <Translation id="generic.language.long.it" />
        </Text>
      </Pressable>
      <Flex px={8}>
        <Text colorName="purple-08" kind="paragraph">
          |
        </Text>
      </Flex>
      <Pressable autoWidth onClick={onSetEnglishLanguage}>
        <Text colorName="purple-08" fontWeight={enSelected ? '600' : '400'} kind="paragraph">
          <Translation id="generic.language.long.en" />
        </Text>
      </Pressable>
    </Flex>
  )
}
