import { gql, useQuery } from '@apollo/client'
import { AvailableTimeSlotsQuery, AvailableTimeSlotsQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query availableTimeSlots($userIds: [String!]!, $startAt: Date!, $endAt: Date!) {
    availableTimeSlots(userIds: $userIds, startAt: $startAt, endAt: $endAt) {
      id
      endAt
      startAt
      userId
    }
  }
`

type Param = {
  endAt: Date
  startAt: Date
  userIds: string[]
}

// FIXME: https://app.clickup.com/t/31zrng0
// We are slicing matchedTherapistIds to a maximum of 100 to avoid computation complexity
export const useAvailableTimeSlots = ({ endAt, startAt, userIds }: Param) => {
  const { data, error, loading, refetch, updateQuery } = useQuery<
    AvailableTimeSlotsQuery,
    AvailableTimeSlotsQueryVariables
  >(QUERY, {
    variables: {
      endAt,
      startAt,
      userIds: userIds.slice(0, 99),
    },
    fetchPolicy: 'cache-first',
  })

  return {
    error: !!error,
    items: data?.availableTimeSlots || [],
    loading,
    refetch,
    updateQuery,
  }
}
