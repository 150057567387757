import { noop } from 'functions'
import { parse } from 'query-string'
import { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { CenteredLoader } from '~/components/CenteredLoader'
import { useEmailVerificationContext } from '~/domains/emailVerification/hooks/useEmailVerificationContext'
import { getRoute } from '~/utils/getRoute'

let timeout: number | undefined

export const EmailVerificationVerifyPageView = () => {
  const { emailVerificationVerify } = useEmailVerificationContext()
  const { search } = useLocation()
  const history = useHistory()
  const params = parse(search)

  const goToBooking = useCallback(() => {
    history.push(getRoute('/booking'))
  }, [history])

  useEffect(() => {
    const verify = async () => {
      if (!(typeof params?.userId === 'string' && typeof params?.code === 'string')) {
        history.push(getRoute('/booking'))

        return
      }

      await emailVerificationVerify({
        codeToVerify: params.code,
        userId: params.userId,
        onFailure: goToBooking,
        onSuccess: goToBooking,
      })
    }

    timeout = window.setTimeout(() => {
      verify().catch(noop)
    }, 100) // Note: we need to prevent to trigger this effect multiple times since something at higher level is mounting navigation routes twice

    return () => {
      window.clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <CenteredLoader />
}
