import { Spacing } from '@serenis-health/tokens'
import { ReactNode } from 'react'
import { PageLayout } from 'ui-deprecated'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'

type Props = {
  children: ReactNode
  maxWidth: '1260px' | '400px' | '640px' | '840px' | '1024px'
  pb?: Spacing
  px?: Spacing
  variantPb?: Spacing
}

export const MainPageLayout = ({ children, maxWidth, pb = 32, px = 16, variantPb = 112 }: Props) => {
  const { isVariant1 } = useFeatureFlagsByUserId()

  const isRestylePatientNavbarVariant = isVariant1('ff_restyle_patient_navbar')

  return (
    <PageLayout
      background="white"
      grow={1}
      maxWidth={maxWidth}
      mdPb={pb}
      pb={isRestylePatientNavbarVariant ? variantPb : pb}
      px={px}
    >
      {children}
    </PageLayout>
  )
}
