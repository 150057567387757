import { colors, typographyFlavours } from '@serenis-health/tokens'
import { GroupBase, StylesConfig } from 'react-select'
import { SelectOption, SelectSize } from '~/components/SelectNew/types'

export const styles = (
  hasError: boolean,
  size: SelectSize,
): StylesConfig<SelectOption, boolean, GroupBase<SelectOption>> => ({
  control: (base, { hasValue, menuIsOpen }) => ({
    ...base,
    boxShadow: 'none',
    ...(hasValue &&
      !menuIsOpen && {
        borderColor: colors.grey04,
      }),
    ...(hasError && {
      borderColor: colors.coral10,
      ':focus': {
        borderColor: colors.coral10,
      },
    }),
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: size === 'medium' ? 16 : 8,
    pointerEvents: 'none',
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: 0,
    paddingRight: 8,
  }),
  indicatorSeparator: (base, { isMulti, hasValue, selectProps: { menuIsOpen } }) => ({
    ...base,
    backgroundColor: colors.purple06,
    margin: 0,
    ...(menuIsOpen && {
      backgroundColor: colors.purple08,
    }),
    ...((!isMulti || !hasValue) && {
      display: 'none',
    }),
  }),
  input: (base) => ({
    ...base,
    fontSize: typographyFlavours.label.fontSize,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 200 }), // Note: Modals, Popovers, Tooltips, SelectNew must have the same zIndex.
  multiValue: (base) => ({
    ...base,
    backgroundColor: colors.purple02,
    fontSize: typographyFlavours.label.fontSize,
    lineHeight: '16px',
    paddingBottom: 4,
    paddingTop: 4,
    borderRadius: 12,
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: colors.black,
    fontSize: 12,
    padding: 0,
    paddingLeft: 8,
  }),
  multiValueRemove: (base) => ({
    ...base,
    ':hover': {
      backgroundColor: colors.purple02,
    },
    paddingLeft: 12,
  }),
  option: (base, { isFocused, isSelected }) => ({
    ...base,
    fontSize: typographyFlavours.label.fontSize,
    ...(isFocused &&
      !isSelected && {
        color: colors.purple08,
      }),
    ':active': {
      backgroundColor: colors.purple04,
      color: colors.purple08,
    },
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: typographyFlavours.label.fontSize,
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: typographyFlavours.label.fontSize,
  }),
})
