import { Flex } from 'ui-deprecated'
import { useCalendar } from '../hooks/useCalendar'
import { Day } from '../types'
import { CalendarDay } from './CalendarDay'
import { CalendarSkeleton } from './CalendarSkeleton'

type Props = {
  onSelect: (day: Day) => void
}

export const CalendarDays = ({ onSelect }: Props) => {
  const { days, loading } = useCalendar()

  if (loading) {
    return <CalendarSkeleton />
  }

  return (
    <Flex direction="row" grow={1} shrink={1} wrap="wrap">
      {days.map((day, index) => (
        <CalendarDay key={index} day={day} onSelect={onSelect} />
      ))}
    </Flex>
  )
}
