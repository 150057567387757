'use client'

import { INoiseCancellation } from '@stream-io/audio-filters-web'
import {
  BackgroundFiltersProvider,
  Call,
  LogLevel,
  MemberRequest,
  NoiseCancellationProvider,
  StreamCall,
  StreamVideo,
  StreamVideoClient,
  TranslationsMap,
} from '@stream-io/video-react-sdk'
import { FormatDateEnum, format } from 'dates'
import { pipe } from 'fp-ts/function'
import { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react'
import { useTrackEvent } from '~/domains/analytics/hooks/useTrackEvent'
import { useToasts } from '~/hooks/useToasts'
import { useLanguage } from '~/i18n/hooks/useLanguage'
import { getTranslation } from '~/i18n/hooks/useTranslation'
import { getEnv } from '~/utils/getEnv'
import { VideocallStyles } from './VideocallStyles'
import { useTherapySessionLive } from './useTherapySessionLive'
import { videocallToSentryLogger } from './videocallToSentryLogger'

const en = getTranslation('en')
const it = getTranslation('it')

const translationsOverrides: TranslationsMap = {
  en: {
    'Call Latency': en('videocall.stats.latency'),
    'Call performance': en('videocall.stats.other'),
    Good: en('videocall.stats.good'),
    Ok: en('videocall.stats.ok'),
    Bad: en('videocall.stats.bad'),
    'Review the key data points below to assess call performance': en('videocall.stats.other.description'),
    'Very high latency values may reduce call qualeny, cause lag, and make the call less enjoyable.': en(
      'videocall.stats.latency.description',
    ),
    'You are muted. Unmute to speak.': en('videocall.youAreMuted'),
  },
  it: {
    'Call Latency': it('videocall.stats.latency'),
    'Call performance': it('videocall.stats.other'),
    Good: it('videocall.stats.good'),
    Ok: it('videocall.stats.ok'),
    Bad: it('videocall.stats.bad'),
    'Review the key data points below to assess call performance': it('videocall.stats.other.description'),
    'Very high latency values may reduce call quality, cause lag, and make the call less enjoyable.': it(
      'videocall.stats.latency.description',
    ),
    'You are muted. Unmute to speak.': it('videocall.youAreMuted'),
  },
}

export type BackgroundImageFilter = 'BRAND_1' | 'BRAND_2' | 'LOGO_1' | 'LOGO_2'

export const backgrounds: Record<BackgroundImageFilter, `/background/${string}.jpg`> = {
  BRAND_1: '/background/brand-1.jpg',
  BRAND_2: '/background/brand-2.jpg',
  LOGO_1: '/background/logo-1.jpg',
  LOGO_2: '/background/logo-2.jpg',
}

type VideocallProviderProps = PropsWithChildren<{
  callId: string
  currentUser: {
    id: string
    firstName: string
    profileImage?: {
      s: string
      m: string
      l: string
    } | null
  }
  logLevel?: LogLevel
  members?: MemberRequest[]
  startAt?: Date
  streamToken: string
}>

export const VideocallProvider = ({
  callId,
  children,
  currentUser,
  logLevel = 'info',
  members,
  startAt,
  streamToken,
}: VideocallProviderProps) => {
  const { language } = useLanguage()
  const { addToast } = useToasts()
  const trackFailure = useTrackEvent('FAILURE')

  const [loading, setLoading] = useState(true)
  const [noiseCancellation, setNoiseCancellation] = useState<INoiseCancellation>()

  const backgroundItems = Object.values(backgrounds)
  const loader = useRef<Promise<void>>()

  const [client] = useState(() => {
    const client = new StreamVideoClient({
      apiKey: getEnv('STREAM_CHAT_KEY'),
      user: {
        image: currentUser.profileImage?.s,
        id: currentUser.id,
        name: currentUser.firstName,
      },
      token: streamToken,
      options: {
        logger: videocallToSentryLogger,
        logLevel,
      },
    })

    window.inHouseVideocall = window.inHouseVideocall || {}
    window.inHouseVideocall.client = client

    return client
  })

  const [call, setCall] = useState<Call | undefined>()

  useEffect(() => {
    if (!client) {
      return
    }

    const c = client.call('default', callId)

    c.getOrCreate({
      data: {
        members,
        ...(startAt ? { starts_at: pipe(startAt, format(FormatDateEnum.ATOM)) } : {}),
      },
    })
      .then(() => {
        setCall(c)

        window.inHouseVideocall = window.inHouseVideocall || {}
        window.inHouseVideocall.call = c
      })
      .catch((error) => {
        trackFailure({
          name: 'videocall.call.get-or-create',
          payload: {
            userId: currentUser.id,
            error: String(error),
          },
        })

        addToast({
          translationId: 'generic.error',
          type: 'alert',
        })
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client])

  useEffect(() => {
    const load = (loader.current || Promise.resolve())
      .then(() => import('@stream-io/audio-filters-web'))
      .then(({ NoiseCancellation }) => {
        setNoiseCancellation(new NoiseCancellation())
      })
      .finally(() => {
        setLoading(false)
      })

    return () => {
      loader.current = load.then(() => setNoiseCancellation(undefined))
    }
  }, [])

  if (!client || !call || loading) {
    return null
  }

  return (
    <>
      <StreamVideo client={client} language={language} translationsOverrides={translationsOverrides}>
        <StreamCall call={call}>
          <BackgroundFiltersProvider backgroundImages={backgroundItems}>
            {noiseCancellation ? (
              <NoiseCancellationProvider noiseCancellation={noiseCancellation}>{children}</NoiseCancellationProvider>
            ) : (
              children
            )}
          </BackgroundFiltersProvider>
        </StreamCall>
      </StreamVideo>
      <VideocallStyles />
    </>
  )
}

type TherapySessionVideocallProviderProps = PropsWithChildren

export const TherapySessionVideocallProvider = ({ children }: TherapySessionVideocallProviderProps) => {
  const { currentUser, id: therapySessionId, patient, startAt, streamToken, therapist } = useTherapySessionLive()

  const members = useMemo(
    () => [
      {
        user_id: patient.id,
        role: 'call_member',
      },
      {
        user_id: therapist.id,
        role: 'host',
        custom: {
          image: therapist.profileImage?.s,
        },
      },
    ],
    [patient.id, therapist.id, therapist.profileImage?.s],
  )

  return (
    <VideocallProvider
      callId={therapySessionId}
      currentUser={currentUser}
      members={members}
      startAt={startAt}
      streamToken={streamToken}
    >
      {children}
    </VideocallProvider>
  )
}
