import { memo } from 'react'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { useBreakpoints } from '~/hooks/useBreakpoints'
import { PatientTabNavigationHorizontal } from './PatientTabNavigationHorizontal'
import { PatientTabNavigationHorizontalVariant } from './PatientTabNavigationHorizontalVariant'
import { PatientTabNavigationVertical } from './PatientTabNavigationVertical'
import { TabNavigationMenuProvider } from './hooks/useTabNavigationMenu'
import { NavigationProps } from './types'

export const PatientTabNavigation = memo(({ direction }: NavigationProps) => {
  const { isMedium, isSmallOnly } = useBreakpoints()

  const horizontal = isSmallOnly && direction === 'horizontal'
  const vertical = isMedium && direction === 'vertical'

  const { isVariant1 } = useFeatureFlagsByUserId()

  if (!horizontal && !vertical) {
    return null
  }

  const isRestylePatientNavbarVariant = isVariant1('ff_restyle_patient_navbar')

  if (isRestylePatientNavbarVariant) {
    return (
      <TabNavigationMenuProvider>
        {horizontal && <PatientTabNavigationHorizontalVariant />}
        {vertical && <PatientTabNavigationVertical />}
      </TabNavigationMenuProvider>
    )
  }

  return (
    <TabNavigationMenuProvider>
      {horizontal && <PatientTabNavigationHorizontal />}
      {vertical && <PatientTabNavigationVertical />}
    </TabNavigationMenuProvider>
  )
})

PatientTabNavigation.displayName = 'PatientTabNavigation'
