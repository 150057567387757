import { IFlagsmith } from 'flagsmith/types'
import { FeatureName, FeatureValue } from '~/domains/featureFlags/types'

export const useFeatureFlagsClient = (client: IFlagsmith) => {
  const getAllFlags = (): Record<FeatureName, FeatureValue> | null => {
    try {
      const flags = client.getAllFlags()

      if (!flags) {
        return null
      }

      return Object.entries(flags).reduce(
        (accumulator, [key, { value }]) => ({
          ...accumulator,
          [key]: value,
        }),
        {} as Record<FeatureName, FeatureValue>,
      )
    } catch (error) {
      return null
    }
  }

  const isControl = (feature: FeatureName) => client.getValue(feature) === 'control'

  const isVariant = (feature: FeatureName) => String(client.getValue(feature)).includes('variant')

  const isVariant1 = (feature: FeatureName) => String(client.getValue(feature)).includes('variant-1')

  const isVariant2 = (feature: FeatureName) => String(client.getValue(feature)).includes('variant-2')

  return {
    ...client,
    getAllFlags,
    isControl,
    isVariant,
    isVariant1,
    isVariant2,
  }
}
