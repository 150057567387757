import { memo } from 'react'
import styled from 'styled-components'
import { Flex } from 'ui-deprecated'
import { TimeSlotItem } from './TimeSlotItem'

type Props = {
  timeSlots: Date[]
  onTimeSlotSelected: (timeSlot: Date) => void
  selectedTimeSlot: Date | null
}

const Container = styled(Flex)`
  max-width: 100%;
  margin: 0 -8px;
`

export const TimeSlotList = memo(({ timeSlots, onTimeSlotSelected, selectedTimeSlot }: Props) => (
  <Container direction="row" justify="flex-start" wrap="wrap">
    {timeSlots.map((timeSlot) => (
      <TimeSlotItem
        key={String(timeSlot.getTime())}
        onClick={onTimeSlotSelected}
        selectedTimeSlot={selectedTimeSlot}
        timeSlot={timeSlot}
      />
    ))}
  </Container>
))
