import { PatientTabMenu } from '~/domains/navigation/TabNavigation/PatientTabMenu'
import { MenuHorizontalWrapperVariant } from './components/MenuHorizontalWrapperVariant'
import { TabNavigationHorizontalContainer } from './components/TabNavigationHorizontalContainer'
import { TabNavigationHorizontalWrapper } from './components/TabNavigationHorizontalWrapper'
import { useTabNavigationMenu } from './hooks/useTabNavigationMenu'
import { RouteChatMenuItem } from './routes/ChatMenuItem'
import { RouteHomeMenuItem } from './routes/HomeMenuItem'
import { RouteJournalingMenuItem } from './routes/JournalingMenuItem'
import { RouteSettingsMenuItem } from './routes/SettingsMenuItem'
import { RouteTherapiesMenuItem } from './routes/TherapiesMenuItem'

export const PatientTabNavigationHorizontalVariant = () => {
  const { open } = useTabNavigationMenu()

  return (
    <TabNavigationHorizontalWrapper>
      <TabNavigationHorizontalContainer>
        <RouteHomeMenuItem direction="horizontal" />

        <RouteTherapiesMenuItem direction="horizontal" />

        <RouteChatMenuItem direction="horizontal" />

        <RouteJournalingMenuItem direction="horizontal" />

        <RouteSettingsMenuItem direction="horizontal" />
      </TabNavigationHorizontalContainer>

      {open && (
        <MenuHorizontalWrapperVariant background="white">
          <PatientTabMenu />
        </MenuHorizontalWrapperVariant>
      )}
    </TabNavigationHorizontalWrapper>
  )
}
