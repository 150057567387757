import { addDays, addMonths, addSeconds, endOfDay, endOfMonth, isAfter, isSameMonth, toDate } from 'date-fns/fp'
import { isAfterOrEqual, isBeforeOrEqual, nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAvailableTimeSlots } from '~/domains/availabilities/calendar/hooks/useAvailableTimeSlots'
import { getCalendarAvailableTimeSlotFromAvailabilities } from '~/domains/availabilities/calendar/utils/getCalendarAvailableTimeSlotFromAvailabilities'
import { TherapyTherapyPathType } from '~/types/graphql'
import { getTherapySessionCancellationPolicyInSeconds } from '~/utils/getTherapySessionCancellationPolicyInSeconds'

export const useCalendarAvailabilities = (
  therapistIds: string[],
  startAt: Date,
  therapyPathType: TherapyTherapyPathType | null,
) => {
  const [initialLoading, setInitialLoading] = useState(true)

  const rangeEndOfMonth = pipe(startAt, endOfMonth)
  const rangeEndAtByStartAt = pipe(startAt, addDays(5), endOfDay)

  const endAt = pipe(rangeEndOfMonth, isAfterOrEqual(rangeEndAtByStartAt))
    ? rangeEndOfMonth
    : pipe(rangeEndOfMonth, addMonths(1), endOfMonth)

  const [calendarBoundaries, setCalendarBoundaries] = useState({
    endAt,
    startAt,
  })

  const { items, loading, refetch } = useAvailableTimeSlots({
    userIds: therapistIds,
    startAt: calendarBoundaries.startAt,
    endAt: calendarBoundaries.endAt,
  })

  const dailyAvailableTimeSlots = useMemo(
    () =>
      getCalendarAvailableTimeSlotFromAvailabilities(items).filter((date) =>
        pipe(
          date,
          isAfter(pipe(nowInMilliseconds(), addSeconds(getTherapySessionCancellationPolicyInSeconds(therapyPathType)))),
        ),
      ),
    [items, therapyPathType],
  )

  const [actualStart] = dailyAvailableTimeSlots

  useEffect(() => {
    if (!initialLoading) {
      return
    }

    if (loading) {
      return
    }

    setInitialLoading(false)
  }, [initialLoading, loading, setInitialLoading])

  const goTo = useCallback(
    (newStartDate: Date) => {
      if (loading) {
        return
      }

      const today = pipe(nowInMilliseconds(), toDate)
      const isInThePast = pipe(newStartDate, isBeforeOrEqual(today))
      const isCurrentMonth = isSameMonth(newStartDate, today)

      if (isInThePast && !isCurrentMonth) {
        return
      }

      if (isInThePast && isCurrentMonth) {
        setCalendarBoundaries({
          startAt: today,
          endAt: endOfMonth(newStartDate),
        })

        refetch({
          userIds: therapistIds,
          startAt: today,
          endAt: endOfMonth(newStartDate),
        })

        return
      }

      setCalendarBoundaries({
        startAt: newStartDate,
        endAt: endOfMonth(newStartDate),
      })

      refetch({
        userIds: therapistIds,
        startAt: newStartDate,
        endAt: endOfMonth(newStartDate),
      })
    },
    [loading, refetch, therapistIds],
  )

  return {
    availabilities: dailyAvailableTimeSlots,
    end: calendarBoundaries.endAt,
    goTo,
    initialLoading,
    loading,
    start: actualStart,
  }
}
