import { colors } from '@serenis-health/tokens'
import styled from 'styled-components'
import { Text } from 'ui'
import { Flex, media } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { ConventionCodeDash } from '~/domains/convention/components/ConventionCodeDash'

const Name = styled(Flex)`
  border-top: 1px solid ${colors.purple08};
  border-bottom: 1px solid ${colors.purple08};
  border-left: 1px solid ${colors.purple08};
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  ${media.medium`
    min-width: 160px;
  `}
`

const Description = styled(Flex)`
  border-top: 1px solid ${colors.purple08};
  border-right: 1px solid ${colors.purple08};
  border-bottom: 1px solid ${colors.purple08};
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
`

type Props = {
  status: 'REQUESTED' | 'ACTIVE'
}

export const ReferralCode = ({ status }: Props) => (
  <Flex background={status === 'REQUESTED' ? 'purple02' : 'purple08'} borderRadius={16} direction="row">
    <Name justify="center" px={16} shrink={1}>
      <Text
        colorName={status === 'REQUESTED' ? 'purple-08' : 'white'}
        fontWeight="600"
        kind="caption"
        textAlign="center"
      >
        <Translation id="referralCode.name.variant" />
      </Text>
    </Name>

    <ConventionCodeDash status={status === 'REQUESTED' ? 'COMPLETED' : 'ACTIVE'} />

    <Description grow={1} justify="center" px={16} shrink={1}>
      <Text colorName={status === 'REQUESTED' ? 'black' : 'white'} kind="caption">
        <Translation id="referralCode.description.variant" />
      </Text>
    </Description>
  </Flex>
)
