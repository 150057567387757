import { PositionAbsolute, PositionRelative } from 'cdk'
import { BORDER_RADIUS_MD, TIME_150 } from 'design-tokens'
import styled from 'styled-components'

type Attrs = {
  value: number
}

const Track = styled(PositionAbsolute).attrs({
  $top: 0,
  $left: 0,
  $backgroundColorName: 'violet-50',
  $borderRadius: BORDER_RADIUS_MD,
})<Attrs>`
  width: ${({ value }) => value}%;
  height: 4px;
  transition: width ${TIME_150} ease;
  will-change: width;
`

const Container = styled(PositionRelative).attrs({
  $backgroundColorName: 'violet-40',
})`
  width: 100%;
  height: 4px;
`

type Props = {
  total: number
  value: number
}

export const ProgressBar = ({ total, value }: Props) => (
  <Container>
    <Track value={(value / total) * 100} />
  </Container>
)

ProgressBar.displayName = 'ProgressBar'
