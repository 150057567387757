import { differenceInMinutes, secondsToMinutes, setHours, setMinutes } from 'date-fns/fp'
import { nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import { isNeitherNullNorUndefined } from 'functions'
import {
  CalendarAvailabilityEndAtFormValues,
  CalendarAvailabilityStartAtFormValues,
} from '~/domains/availabilities/calendar/components/CalendarAvailabilityFormSelect'
import { TranslationId } from '~/i18n/types'
import { getTherapySessionAvailabilityDurationInSeconds } from '~/utils/getTherapySessionAvailabilityDurationInSeconds'

type Param = Partial<CalendarAvailabilityStartAtFormValues> & Partial<CalendarAvailabilityEndAtFormValues>

export const isAvailabilityRangeAllowed = ({
  endAtHours,
  endAtMinutes,
  startAtHours,
  startAtMinutes,
}: Param):
  | Extract<
      TranslationId,
      | 'forms.errors.availability.required'
      | 'forms.errors.availability.lessThanMininumDuration'
      | 'forms.errors.availability.isNotModuloOfDuration'
    >
  | boolean => {
  if (!isNeitherNullNorUndefined(endAtHours) || !isNeitherNullNorUndefined(endAtMinutes)) {
    return true
  }

  if (!isNeitherNullNorUndefined(startAtHours) || !isNeitherNullNorUndefined(startAtMinutes)) {
    return 'forms.errors.availability.required'
  }

  const endAt = pipe(nowInMilliseconds(), setHours(endAtHours), setMinutes(endAtMinutes))
  const startAt = pipe(nowInMilliseconds(), setHours(startAtHours), setMinutes(startAtMinutes))

  const minutesOfDifference = pipe(endAt, differenceInMinutes(startAt))
  const therapySessionOnlineDurationInMinutes = pipe(getTherapySessionAvailabilityDurationInSeconds(), secondsToMinutes)

  if (minutesOfDifference < therapySessionOnlineDurationInMinutes) {
    return 'forms.errors.availability.lessThanMininumDuration'
  }

  if (minutesOfDifference % therapySessionOnlineDurationInMinutes > 0) {
    return 'forms.errors.availability.isNotModuloOfDuration'
  }

  return true
}
