import { Icon } from 'icons'
import { useCallback } from 'react'
import { Text } from 'ui'
import { Flex, OverflowHidden, Pressable } from 'ui-deprecated'
import { CardBoxNoStroke } from '~/components/CardBoxNoStroke'
import { TertiaryButtonIconOnly } from '~/components/TertiaryButton/TertiaryButtonIconOnly'
import { Translation } from '~/components/Translation'
import { useModals } from '~/domains/modals'
import { DesktopModuleBackground } from './DesktopModuleBackground'
import { HeartHug } from './HeartHug'
import { StickerModuleModal } from './StickerModuleModal'

export const StickerModule = () => {
  const { open } = useModals()

  const handleOnPress = useCallback(() => {
    open('stickerModule')
  }, [open])

  return (
    <>
      <Pressable onClick={handleOnPress}>
        <CardBoxNoStroke align="center" background="white" direction="row" p={0}>
          <Flex align="center" direction="row" grow={1} shrink={1}>
            <Flex basis="78px" mdHide pl={16} py={8}>
              <HeartHug />
            </Flex>
            <Flex align="flex-start" px={16} py={8}>
              <Text fontWeight="600" kind="paragraph" textAlign="left">
                <Translation id="stickerModule.title" />
              </Text>
              <Flex pt={4}>
                <Text fontWeight="400" kind="paragraph" textAlign="left">
                  <Translation id="stickerModule.body" />
                </Text>
              </Flex>
            </Flex>
            <OverflowHidden align="flex-end" grow={1} hide mdHide={false} shrink={1}>
              <DesktopModuleBackground />
            </OverflowHidden>
          </Flex>
          <Flex pr={16}>
            <TertiaryButtonIconOnly onClick={handleOnPress}>
              <Icon name="arrow-right" size={24} />
            </TertiaryButtonIconOnly>
          </Flex>
        </CardBoxNoStroke>
      </Pressable>
      <StickerModuleModal />
    </>
  )
}
