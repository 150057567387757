import { useMemo } from 'react'
import { PartnershipSource } from '~/types/graphql'
import { useCurrentUserNullable } from './useCurrentUser'
import { useSessionStorageParams } from './useSessionStorageParams'

const partnerships = ['mysc-srns'] as const

type Partnership = (typeof partnerships)[number]

const partnershipByUtmSource: Record<Partnership, PartnershipSource> = {
  'mysc-srns': 'MY_SECRET_CASE',
}

const isValidPartnership = (partnership: string): partnership is Partnership =>
  (partnerships as unknown as string[]).includes(partnership)

export const usePartnership = () => {
  const user = useCurrentUserNullable()
  const storageParam = useSessionStorageParams()

  const utmSource = useMemo(() => {
    if (!('utm_source' in storageParam)) {
      return null
    }

    if (typeof storageParam.utm_source !== 'string') {
      return null
    }

    return storageParam.utm_source.toLowerCase().trim()
  }, [storageParam])

  return useMemo(() => {
    if (utmSource && isValidPartnership(utmSource)) {
      return partnershipByUtmSource[utmSource]
    }

    return user.partnershipSource ?? null
  }, [user.partnershipSource, utmSource])
}
