import { useInbox } from '@trycourier/react-inbox'
import { IInboxMessagePreview } from '@trycourier/react-provider'
import { format, parseISO } from 'date-fns/fp'
import { FormatDateEnum } from 'dates'
import { BORDER_RADIUS_CIRCLE, COLOR_RED_60 } from 'design-tokens'
import { pipe } from 'fp-ts/function'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Text } from 'ui'
import { Flex, PositionAbsolute, PositionRelative, Pressable } from 'ui-deprecated'
import { usePopoverContextNullable } from '~/components/FloatingUI/Popover/PopoverContext'
import { Markdown } from '~/components/Markdown'

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: ${BORDER_RADIUS_CIRCLE};
  background-color: ${COLOR_RED_60};
`

type NotificationProps = {
  message: IInboxMessagePreview
  preview: boolean
}

export const Notification = ({ message, preview }: NotificationProps) => {
  const { markMessageRead, unpinMessage } = useInbox()
  const popoverContextNullable = usePopoverContextNullable()
  const history = useHistory()

  const handleReadMessage = useCallback(
    (message: IInboxMessagePreview) => () => {
      markMessageRead(message.messageId)

      if (message.pinned) {
        unpinMessage(message.messageId)
      }

      const action = message.actions?.[0]

      if (action) {
        history.push(action.href)
        popoverContextNullable?.close()
      }
    },
    [history, markMessageRead, popoverContextNullable, unpinMessage],
  )

  return (
    <Pressable autoWidth onClick={handleReadMessage(message)}>
      <PositionRelative background={message.read ? 'white' : 'purple02'} p={16}>
        <Text colorName="grey-11" kind="caption" textAlign="left">
          {pipe(message.created, parseISO, format(FormatDateEnum.LONG_DAY_DATE_MONTH_NAME))}
        </Text>
        <Flex pt={4}>
          <Text fontWeight="600" kind="caption" textAlign="left">
            {message.title}
          </Text>
        </Flex>
        {message.preview && (
          <Flex pt={4}>
            <Markdown ellipsis={preview} kind="caption" textAlign="left">
              {message.preview}
            </Markdown>
          </Flex>
        )}
        {!!message.actions?.[0] && (
          <Flex pt={4}>
            <Text colorName="purple-08" fontWeight="600" kind="caption" textAlign="left" textDecoration="underline">
              {message.actions[0].content}
            </Text>
          </Flex>
        )}

        {!message.read && (
          <PositionAbsolute right="16px" top="16px">
            <Dot />
          </PositionAbsolute>
        )}
      </PositionRelative>
    </Pressable>
  )
}
