import { useMergeRefs } from '@floating-ui/react'
import { forwardRef, HTMLProps, ReactNode } from 'react'
import { Pressable } from 'ui-deprecated'
import { useTooltipContext } from './TooltipContext'

type Props = {
  children: ReactNode
}

export const TooltipTrigger = forwardRef<HTMLElement, HTMLProps<HTMLElement> & Props>(
  ({ children, ...props }, propRef) => {
    const { open, getReferenceProps, refs } = useTooltipContext()
    const ref = useMergeRefs([refs.setReference, propRef])
    const referenceProps = getReferenceProps(props)

    return (
      <Pressable ref={ref} autoWidth onClick={open} {...referenceProps}>
        {children}
      </Pressable>
    )
  },
)
