import { colors } from '@serenis-health/tokens'
import { useAmp } from 'next/amp'
import { memo } from 'react'
import styled from 'styled-components'
import { getAutoSrcSet } from '~/components/Image/getAutoSrcSet'

type AutoSrcSetProps = {
  autoSrcSet: true
  srcSet?: never
}

type SrcSetProps = {
  autoSrcSet?: false
  srcSet?: string
}

type Props = {
  alt?: string
  height: number
  layout?: 'fixed' | 'responsive'
  loading?: 'eager' | 'lazy'
  src: string
  width: number
} & (AutoSrcSetProps | SrcSetProps)

const Img = styled.img`
  max-width: 100%;
  height: auto;
  color: ${colors.transparent};
  text-indent: -9999px;
`

export const Image = memo(
  ({ alt = '', autoSrcSet = false, height, loading = 'lazy', layout = 'fixed', src, srcSet, width }: Props) => {
    const isAmp = useAmp()

    if (isAmp) {
      return <amp-img alt={alt} height={height} layout={layout} src={src} title={alt} width={width} />
    }

    const autoSrc = autoSrcSet ? getAutoSrcSet(src) : srcSet

    return <Img alt={alt} height={height} loading={loading} src={src} srcSet={autoSrc} title={alt} width={width} />
  },
)

Image.displayName = 'Image'
