import { TranslationId } from '~/i18n/types'
import { ConventionCode } from '../types/conventionCode'

export const getDescriptionTranslationId = (
  status: ConventionCode['status'],
  { therapySessionsAvailable }: Pick<ConventionCode['convention'], 'therapySessionsAvailable'>,
): Extract<TranslationId, `conventionCode.description.${string}`> => {
  switch (status) {
    case 'ACTIVE':
      return therapySessionsAvailable >= 1000
        ? 'conventionCode.description.activated.unlimited'
        : 'conventionCode.description.activated.first'
    case 'INACTIVE':
      return therapySessionsAvailable >= 1000
        ? 'conventionCode.description.created.unlimited'
        : 'conventionCode.description.created'
    case 'EXPIRED':
    case 'COMPLETED':
    default:
      return 'conventionCode.description.completed'
  }
}
