import { ComponentProps } from 'react'
import { Markdown } from '~/components/Markdown'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useTranslation } from '~/i18n/hooks/useTranslation'
import { TherapySchoolOrientation } from '~/types/graphql'
import { getOrientationTranslationId } from '~/utils/getOrientationTranslationId'

type Props = {
  orientation: TherapySchoolOrientation
  textKind?: ComponentProps<typeof Markdown>['kind']
  textAlign?: ComponentProps<typeof Markdown>['textAlign']
}

export const TherapistOrientation = ({
  orientation: therapistOrientation,
  textKind = 'paragraph',
  textAlign = 'left',
}: Props) => {
  const orientation = useTranslation(getOrientationTranslationId(therapistOrientation))

  return (
    <TranslationMarkdown
      colorName="grey-11"
      id="therapist.orientation"
      kind={textKind}
      textAlign={textAlign}
      values={{ orientation }}
    />
  )
}
