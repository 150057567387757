import { NonSelectable } from 'ui-deprecated'
import { CalendarDays } from './components/CalendarDays'
import { CalendarHeader } from './components/CalendarHeader'
import { CalendarNavigation } from './components/CalendarNavigation'
import { Day } from './types'

type Props = {
  onNext: () => void
  onPrevious: () => void
  onSelect: (day: Day) => void
}

export const View = ({ onNext, onPrevious, onSelect }: Props) => (
  <NonSelectable>
    <CalendarNavigation onNext={onNext} onPrevious={onPrevious} />

    <CalendarHeader />

    <CalendarDays onSelect={onSelect} />
  </NonSelectable>
)
