import { colors } from '@serenis-health/tokens'
import styled from 'styled-components'
import { Flex, media } from 'ui-deprecated'

export const ConventionCodeName = styled(Flex).attrs({
  displayName: 'ConventionCodeName',
  basis: '30%',
  lgBasis: '240px',
  mdBasis: '180px',
})`
  border-top: 1px solid ${colors.purple08};
  border-bottom: 1px solid ${colors.purple08};
  border-left: 1px solid ${colors.purple08};
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;

  ${media.medium`
    min-width: 160px;
  `}
`
