import { ComponentProps } from 'react'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { ReactHookFormTextFieldNew } from '~/domains/react-hook-form'

type Props = Pick<ComponentProps<typeof ReactHookFormTextFieldNew>, 'helperText'>

export const TextFieldNewHelperText = ({ helperText }: Props) => (
  <Flex pt={8}>
    <Text colorName="grey-11" fontWeight="400" kind="caption">
      {helperText}
    </Text>
  </Flex>
)
