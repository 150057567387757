import { EmailVerificationContextProvider } from '~/domains/emailVerification/hooks/useEmailVerificationContext'
import { EmailVerificationVerifyPageView } from '~/domains/emailVerification/routes/EmailVerificationVerifyPageView'
import { PageNoop } from '~/routes/PageNoop'
import { Page } from '~/routes/types'

export const VerifyEmailPage: Page = {
  exact: true,
  id: 'verify.email',
  paths: ['/verify/email'],
  options: {
    isVisibleAppBar: false,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: false,
    isVisibleTabNavigation: false,
    seoKey: 'verify.email',
    title: null,
  },
  PageGuard: PageNoop,
  PageLoaded: PageNoop,
  PageProvider: EmailVerificationContextProvider,
  PageView: EmailVerificationVerifyPageView,
}
