import { TherapistTabMenu } from './TherapistTabMenu'
import { MenuHorizontalWrapperVariant } from './components/MenuHorizontalWrapperVariant'
import { TabNavigationHorizontalContainer } from './components/TabNavigationHorizontalContainer'
import { TabNavigationHorizontalWrapper } from './components/TabNavigationHorizontalWrapper'
import { useTabNavigationMenu } from './hooks/useTabNavigationMenu'
import { RouteAgendaMenuItem } from './routes/AgendaMenuItem'
import { RouteCalendarMenuItem } from './routes/CalendarMenuItem'
import { RouteChatMenuItem } from './routes/ChatMenuItem'
import { RoutePatientManagementMenuItem } from './routes/PatientManagementMenuItem'
import { RouteSettingsMenuItem } from './routes/SettingsMenuItem'

export const TherapistTabNavigationHorizontalVariant = () => {
  const { open } = useTabNavigationMenu()

  return (
    <TabNavigationHorizontalWrapper>
      <TabNavigationHorizontalContainer>
        <RouteAgendaMenuItem direction="horizontal" />

        <RouteCalendarMenuItem direction="horizontal" />

        <RouteChatMenuItem direction="horizontal" />

        <RoutePatientManagementMenuItem direction="horizontal" />

        <RouteSettingsMenuItem direction="horizontal" />
      </TabNavigationHorizontalContainer>

      {open && (
        <MenuHorizontalWrapperVariant background="white">
          <TherapistTabMenu />
        </MenuHorizontalWrapperVariant>
      )}
    </TabNavigationHorizontalWrapper>
  )
}
