import { addHours, addSeconds, startOfDay } from 'date-fns/fp'
import { nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import { getTherapySessionAvailabilityDurationInSeconds } from '~/utils/getTherapySessionAvailabilityDurationInSeconds'

export const firstAllowedAvailabilityStartAt = pipe(nowInMilliseconds(), startOfDay, addHours(6))

export const lastAllowedAvailabilityStartAt = pipe(nowInMilliseconds(), startOfDay, addHours(22))

export const firstAllowedWeeklyAvailabilityStartAt = pipe(nowInMilliseconds(), startOfDay, addHours(7))

export const lastAllowedWeeklyAvailabilityEndAt = pipe(
  lastAllowedAvailabilityStartAt,
  addSeconds(getTherapySessionAvailabilityDurationInSeconds()),
)
