import { useMemo } from 'react'
import styled from 'styled-components'
import { Link, Text } from 'ui'
import { Flex, MaxWidth840px } from 'ui-deprecated'
import { Image } from '~/components/Image'
import { useIsNativeApp } from '~/domains/appNative/hooks/useIsNativeApp'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { useBreakpoints } from '~/hooks/useBreakpoints'
import { getEnv } from '~/utils/getEnv'
import { Translation } from '../Translation'
import { onda1024, onda1440, ondaMobile } from './background'

type BackgroundProps = {
  size: boolean
  source: string
}

const Background = styled(Flex)<BackgroundProps>`
  min-height: ${({ size }) => (size ? '431px' : '319px')};
  background-image: ${({ source }) => source};
  background-size: cover;
  background-repeat: no-repeat;
`

const BannerContent = styled(Flex)`
  max-width: 428px;
`

const getBackgroundSource = (isSmallOnly: boolean, isMediumOnly: boolean) => {
  if (isSmallOnly) {
    return ondaMobile
  }

  if (isMediumOnly) {
    return onda1024
  }

  return onda1440
}

const getMockupSource = (isMediumOnly: boolean) =>
  isMediumOnly ? 'appHookBanner/mockup-414.png' : 'appHookBanner/mockup-350.png'

export const MobileHookBanner = () => {
  const isNative = useIsNativeApp()

  const { isMediumOnly, isSmallOnly } = useBreakpoints()

  const background = useMemo(() => getBackgroundSource(isSmallOnly, isMediumOnly), [isMediumOnly, isSmallOnly])

  const mockup = useMemo(() => getMockupSource(isMediumOnly), [isMediumOnly])

  const { isVariant1 } = useFeatureFlagsByUserId()

  const isRestylePatientNavbarVariant = isVariant1('ff_restyle_patient_navbar')

  if (isNative) {
    return null
  }

  return (
    <Background
      background="white"
      justify="center"
      mdPb={0}
      pb={isRestylePatientNavbarVariant ? 112 : 0}
      size={isRestylePatientNavbarVariant && isSmallOnly}
      source={background}
    >
      <MaxWidth840px px={16}>
        <Flex align="center" direction="row" justify="center" lgPx={0} px={16}>
          <Flex grow={1} shrink={1}>
            <BannerContent px={isSmallOnly ? 16 : 0}>
              <Text fontWeight="600" kind="h2">
                <Translation id="downloadApp.banner.title" />
              </Text>
              <Flex pt={16}>
                <Text fontWeight="400" kind="paragraph">
                  <Translation id="downloadApp.banner.body" />
                </Text>
              </Flex>
              <Flex direction="row" pt={32} wrap="wrap">
                <Flex pr={16}>
                  <Link href={getEnv('SERENIS_APP_STORE')} rel="noopener noreferrer" target="_blank">
                    <Image height={66} src="appHookBanner/apple-store-badge.png" width={120} />
                  </Link>
                </Flex>
                <Flex>
                  <Link href={getEnv('SERENIS_PLAY_STORE')} rel="noopener noreferrer" target="_blank">
                    <Image height={60} src="appHookBanner/google-play-badge.png" width={133} />
                  </Link>
                </Flex>
              </Flex>
            </BannerContent>
          </Flex>
          <Flex hide mdHide={false} pr={64}>
            <Image height={350} src={mockup} width={232} />
          </Flex>
        </Flex>
      </MaxWidth840px>
    </Background>
  )
}
