import styled from 'styled-components'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'

const weekDays = [
  { id: 'uh37whueofxt0kq5', value: 'L' },
  { id: 'taqevt2i2afz6lbq', value: 'M' },
  { id: '5y3fusc8qxetri58', value: 'M' },
  { id: 'zx6yzc0am0luu1wa', value: 'G' },
  { id: '64zj4tdeqrg906rc', value: 'V' },
  { id: '3l6b9dela4jpc4mm', value: 'S' },
  { id: '555cyz8nm95swjkb', value: 'D' },
]

const Wrapper = styled(Flex)`
  width: 14.28%;
`

export const CalendarHeader = () => (
  <Flex direction="row">
    {weekDays.map(({ id, value }) => (
      <Wrapper key={id}>
        <Flex align="center" py={16}>
          <Text fontWeight="600" kind="caption">
            {value}
          </Text>
        </Flex>
      </Wrapper>
    ))}
  </Flex>
)
