import { ReactNode, useMemo } from 'react'
import { PositionAbsolute } from 'ui-deprecated'
import { useIsNativeApp } from '~/domains/appNative/hooks/useIsNativeApp'

type Props = {
  children: ReactNode
}

type Bottom = `${number}px`

export const TabNavigationHorizontalWrapper = ({ children }: Props) => {
  const isNativeApp = useIsNativeApp()

  const bottom: Bottom = useMemo(() => (isNativeApp ? '0px' : '16px'), [isNativeApp])

  return (
    <PositionAbsolute bottom={bottom} left="16px" right="16px">
      {children}
    </PositionAbsolute>
  )
}

TabNavigationHorizontalWrapper.displayName = 'TabNavigationHorizontalWrapper'
