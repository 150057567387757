import { Icon } from 'icons'
import { matchPath, useLocation } from 'react-router-dom'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { MenuLink } from '~/domains/navigation/TabNavigation/components/MenuLink'

const to = '/settings/payments'

export const MenuItemPayments = () => {
  const { pathname } = useLocation()
  const active = !!matchPath(pathname, { path: to })

  return (
    <MenuLink to={to}>
      <Flex pr={12}>
        <Icon colorName="grey-08" name="credit-card" size={24} />
      </Flex>

      <Flex grow={1} pt={2} shrink={1}>
        <Text colorName={active ? 'purple-08' : undefined} fontWeight="400" kind="paragraph">
          <Translation id="menu.payments" />
        </Text>
      </Flex>
    </MenuLink>
  )
}
