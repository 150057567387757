import { getHours, getMinutes } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { noop } from 'functions'
import { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react'
import { Calendar } from 'ui-deprecated'
import { ReactHookFormProvider, useReactHookForm } from '~/domains/react-hook-form'
import { TherapySessionStatus, TherapySessionTypeCost, TherapyTherapyPathType } from '~/types/graphql'
import { ModalRescheduleTherapySession } from './components/ModalRescheduleTherapySession'
import { ModalRescheduleTherapySessionConfirm } from './components/ModalRescheduleTherapySessionConfirm'
import { ModalRescheduleTherapySessionUnder24 } from './components/ModalRescheduleTherapySessionUnder24'
import { ModalRescheduleTherapySessionWarning } from './components/ModalRescheduleTherapySessionWarning'
import { FormValues } from './types/formValues'

type Day = Parameters<ComponentProps<typeof Calendar>['onChange']>[number]

type Props = {
  duration: number
  onClose?: () => void
  id: string
  initialStartAt: Date
  patientFirstName?: string
  patientFullName?: string
  patientId?: string
  recurrency?: boolean
  status: TherapySessionStatus
  typeCost: TherapySessionTypeCost
  therapyPathType: TherapyTherapyPathType | null
}

export const RescheduleTherapySessionModals = ({
  duration,
  id,
  initialStartAt,
  onClose = noop,
  patientFirstName,
  patientFullName,
  patientId,
  recurrency = false,
  status,
  typeCost,
  therapyPathType,
}: Props) => {
  const [selectedDay, setSelectedDay] = useState<Date>(initialStartAt)

  const onSelectedDay = useCallback(
    ({ date }: Day) => {
      setSelectedDay(date)
    },
    [setSelectedDay],
  )

  const formDefaultValues = useMemo(
    () => ({
      duration: duration === 2 ? '2' : '1',
      startAtHours: pipe(initialStartAt, getHours),
      startAtMinutes: pipe(initialStartAt, getMinutes),
    }),
    [duration, initialStartAt],
  )

  const form = useReactHookForm<FormValues>({
    defaultValues: formDefaultValues,
  })

  // Note: we need to reset form when default values change because it means we are updating a new therapy session; without this the the fields will contain previous session data
  useEffect(() => {
    form.reset(formDefaultValues)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDefaultValues])

  return (
    <ReactHookFormProvider {...form}>
      <ModalRescheduleTherapySessionConfirm
        id={id}
        initialDuration={duration}
        initialStartAt={initialStartAt}
        onClose={onClose}
        patientFullName={patientFullName}
        selectedDay={selectedDay}
        therapyPathType={therapyPathType}
      />

      <ModalRescheduleTherapySession
        duration={duration}
        id={id}
        initialStartAt={initialStartAt}
        onClose={onClose}
        onSelectedDay={onSelectedDay}
        patientFullName={patientFullName}
        recurrency={recurrency}
        selectedDay={selectedDay}
        therapyPathType={therapyPathType}
      />

      <ModalRescheduleTherapySessionWarning
        id={id}
        initialStartAt={initialStartAt}
        onClose={onClose}
        patientFirstName={patientFirstName}
        status={status}
        typeCost={typeCost}
      />

      <ModalRescheduleTherapySessionUnder24
        id={id}
        patientFullName={patientFullName}
        patientId={patientId}
        selectedDay={selectedDay}
      />
    </ReactHookFormProvider>
  )
}
