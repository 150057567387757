import { Redirect } from 'react-router-dom'
import { PageRoute } from '~/components/PageRoute'
import { Head } from '~/domains/reservation/components/Head'
import { useRegisterSelectedTimeSlot } from '~/domains/reservation/hooks/useRegisterSelectedTimeSlot'
import { useMatchedTherapistsForCurrentUser } from '~/hooks/useMatchedTherapistsForCurrentUser'
import { TherapistProvider } from '~/hooks/useTherapist'
import { PageScrollEffect } from '~/routes/PageScrollEffect'
import { RecalculateAvailableTherapistsModal } from '../../components/RecalculateAvailableTherapistsModal'
import { RecalculateAvailableTherapistsTrigger } from '../../components/RecalculateAvailableTherapistsTrigger'
import { useReservationFlow } from '../../hooks/useReservationFlow'
import { getReservationRoutes } from '../../utils/getReservationRoutes'
import { BookBySuggestedTherapists } from './BookBySuggestedTherapists'

export const BookBySuggestedTherapist = () => {
  useRegisterSelectedTimeSlot('book-by-therapist')

  const { selectedTherapist } = useReservationFlow()

  const { suggestedTherapistsIdsChanged } = useMatchedTherapistsForCurrentUser()

  if (!selectedTherapist) {
    return <Redirect to={getReservationRoutes('/book-by-matched-therapists')} />
  }

  return (
    <>
      <PageScrollEffect />

      <PageRoute id="reservation.booking">
        <Head translationId="reservation.booking" />
        <TherapistProvider therapist={selectedTherapist}>
          <BookBySuggestedTherapists />

          {suggestedTherapistsIdsChanged && <RecalculateAvailableTherapistsTrigger />}
          <RecalculateAvailableTherapistsModal />
        </TherapistProvider>
      </PageRoute>
    </>
  )
}
