import { memo } from 'react'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { useBreakpoints } from '~/hooks/useBreakpoints'
import { TherapistTabNavigationHorizontal } from './TherapistTabNavigationHorizontal'
import { TherapistTabNavigationHorizontalVariant } from './TherapistTabNavigationHorizontalVariant'
import { TherapistTabNavigationVertical } from './TherapistTabNavigationVertical'
import { TabNavigationMenuProvider } from './hooks/useTabNavigationMenu'
import { NavigationProps } from './types'

export const TherapistTabNavigation = memo(({ direction }: NavigationProps) => {
  const { isMedium, isSmallOnly } = useBreakpoints()

  const horizontal = isSmallOnly && direction === 'horizontal'
  const vertical = isMedium && direction === 'vertical'

  const { isVariant1 } = useFeatureFlagsByUserId()

  if (!horizontal && !vertical) {
    return null
  }

  const isRestylePatientNavbarVariant = isVariant1('ff_restyle_patient_navbar')

  if (isRestylePatientNavbarVariant) {
    return (
      <TabNavigationMenuProvider>
        {horizontal && <TherapistTabNavigationHorizontalVariant />}
        {vertical && <TherapistTabNavigationVertical />}
      </TabNavigationMenuProvider>
    )
  }

  return (
    <TabNavigationMenuProvider>
      {horizontal && <TherapistTabNavigationHorizontal />}
      {vertical && <TherapistTabNavigationVertical />}
    </TabNavigationMenuProvider>
  )
})

TherapistTabNavigation.displayName = 'TherapistTabNavigation'
