import { noop } from 'functions'
import { useEffect } from 'react'
import { useTrackEventImpression } from '~/domains/analytics/hooks/useTrackEventImpression'
import { useReservationFlow } from '../hooks/useReservationFlow'

export const ReservationImpressionTracker = () => {
  const { bookType, loading, matchedTherapistIds, selectedTherapist, suggestedTherapistsProfiles } =
    useReservationFlow()
  const trackEvent = useTrackEventImpression('reservation.therapists')

  useEffect(() => {
    const run = async () => {
      if (loading || !selectedTherapist || !suggestedTherapistsProfiles.length) {
        return
      }

      await trackEvent({
        bookType: bookType ?? 'book-by-therapist',
        matchedTherapistIds,
        selectedTherapistId: selectedTherapist?.id,
        suggestedTherapistsProfilesIds: suggestedTherapistsProfiles.map(({ id }) => id),
      })
    }

    run().catch(noop)
  }, [bookType, loading, matchedTherapistIds, selectedTherapist, suggestedTherapistsProfiles, trackEvent])

  return null
}
