import { matchPath } from 'react-router-dom'
import { useRootLocation } from '~/hooks/useRootHistory'
import { getRoute } from '~/utils/getRoute'
import { useBookAndAssign } from './useBookAndAssign'
import { useReassignTherapySession } from './useReassignTherapySession'

export const useReservationRouteStrategy = () => {
  const { pathname } = useRootLocation()

  const isReassign = !!matchPath(pathname, { path: [getRoute('/change-therapist/:therapyId/booking')] })

  return isReassign ? useReassignTherapySession : useBookAndAssign
}
