import { Icon } from 'icons'
import { ReactNode } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link } from 'ui'
import { Flex } from 'ui-deprecated'
import { Route } from '~/utils/getRoute'

type Props = {
  children: ReactNode
  to: Route
}

export const MenuLink = ({ children, to }: Props) => (
  <Link as={ReactRouterLink} colorName="black" colorNameHover="purple-08" to={to}>
    <Flex align="center" direction="row">
      <Flex align="center" direction="row" grow={1} pr={8} shrink={1}>
        {children}
      </Flex>

      <Flex>
        <Icon colorName="purple-08" name="arrow-right" size={24} />
      </Flex>
    </Flex>
  </Link>
)
