import { useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { Text } from 'ui'
import { Flex, Pressable, media } from 'ui-deprecated'
import { Day } from '../types'
import { getBackgroundColorName, getBorderColor, getColorName } from '../utils/getCalendarDayColors'

type Props = {
  day: Day
  onSelect?: (day: Day) => void
}

const Wrapper = styled(Flex)`
  width: 14.28%;
`

const calendarDaySizes = css`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  ${media.medium`
    height: 50px;
    width: 50px;
  `}
`

const StyledPressable = styled(Pressable)`
  display: flex;
  justify-content: center;
  margin: 0 4px 8px;
  ${media.medium`
    margin-bottom: 20px;
  `}
  ${calendarDaySizes}
`

const StyledCalendarDay = styled(Flex)`
  ${calendarDaySizes}
`

export const CalendarDay = ({ day, onSelect }: Props) => {
  const onClick = useCallback(() => {
    if (!onSelect) {
      return
    }

    onSelect(day)
  }, [day, onSelect])

  const { background, borderColor, colorName } = useMemo(
    () => ({
      colorName: getColorName(day),
      background: getBackgroundColorName(day),
      borderColor: getBorderColor(day),
    }),
    [day],
  )

  return (
    <Wrapper align="center">
      <StyledPressable data-test-id="calendar-day" disabled={!day.hasEvent} onClick={onClick}>
        <StyledCalendarDay
          align="center"
          background={background}
          borderColor={borderColor}
          borderSize={1}
          justify="center"
        >
          <Text colorName={colorName} fontWeight="600" kind="paragraph" textAlign="center">
            {day.belongsToCurrentMonth ? day.date.getDate() : ''}
          </Text>
        </StyledCalendarDay>
      </StyledPressable>
    </Wrapper>
  )
}
