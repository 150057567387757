import { isAfter, startOfDay, toDate } from 'date-fns/fp'
import { nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'

export const shouldAskForReview = () => {
  const reviewAskAt = getLocalStorage('review-ask-at')
  const reviewAskedAt = getLocalStorage('review-asked-at')
  const reviewAskedCount = pipe('review-asked-count', getLocalStorage, Number)

  if (!reviewAskAt || !!reviewAskedAt || reviewAskedCount > 3) {
    return false
  }

  return pipe(pipe(nowInMilliseconds(), startOfDay), isAfter(pipe(reviewAskAt, Number, toDate, startOfDay)))
}
