import {
  isTaxId,
  registerDecorator,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from 'class-validator'

const regexNoPersonaFisica = /^\d{11}$/

export const isTaxCode = (value: unknown) => {
  if (typeof value !== 'string') {
    return false
  }

  return isTaxId(value.trim(), 'it-IT') || regexNoPersonaFisica.test(value.trim())
}

@ValidatorConstraint({ name: 'taxCode', async: false })
export class IsTaxCodeConstraint implements ValidatorConstraintInterface {
  validate = isTaxCode

  defaultMessage = () => 'Input is not a valid tax code'
}

export function IsTaxCode<T extends object>(validationOptions?: ValidationOptions) {
  return function (object: T, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsTaxCodeConstraint,
    })
  }
}
