import { Flex, MountOn } from 'cdk'
import { differenceInYears, setYear } from 'date-fns/fp'
import { nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import { Icon } from 'icons'
import { useCallback, useMemo } from 'react'
import Zoom from 'react-medium-image-zoom'
import styled from 'styled-components'
import { Avatar, Button, Divider, Text, TextProps } from 'ui'
import { CardBox } from 'ui-deprecated'
import { getTherapistJobTitleTranslationId } from '~/components/TherapistDetailCard/utils/getTherapistJobTitleTranslationId'
import { Translation } from '~/components/Translation'
import { useTherapistSpecializations } from '~/domains/agenda/hooks/useTherapistSpecializations'
import { useModals } from '~/domains/modals'
import { TherapistBadgeCertifications } from '~/domains/therapist/components/TherapistBadgeCertifications'
import { TherapistOrientation } from '~/domains/therapist/components/TherapistOrientation'
import { TherapistProfileModal } from '~/domains/therapist/components/TherapistProfileModal'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useTherapist } from '~/hooks/useTherapist'
import { useTherapistProfessionTypeValue } from '~/hooks/useTherapistProfessionTypeValue'
import { shouldShowYearsOfExperience } from '~/utils/shouldShowYearsOfExperience'
import { useTherapyPathById } from '../../hooks/useTherapyPathById'

const TherapistCardBox = styled(CardBox)`
  z-index: 1;
`

const TherapistFullName = ({ fullName, kind }: { fullName: string; kind: TextProps['kind'] }) => (
  <Text colorName="black" data-test-id="therapist-full-name" fontWeight="600" kind={kind} textAlign="left">
    {fullName}
  </Text>
)

export const TherapistCard = () => {
  const {
    id,
    fullName,
    therapist: {
      numberOfPatients,
      profileImage,
      registerRegistrationYearPsychologist,
      sex,
      therapySchool: { orientation },
    },
  } = useTherapist()

  const { emailVerified } = useCurrentUser()

  const { therapyPath } = useTherapyPathById()

  const jobTitleTranslationId = getTherapistJobTitleTranslationId({
    sex,
    therapyPathType: therapyPath.type,
  })

  const years = useMemo(() => {
    if (!registerRegistrationYearPsychologist) {
      return 0
    }

    return pipe(
      nowInMilliseconds(),
      differenceInYears(pipe(nowInMilliseconds(), setYear(registerRegistrationYearPsychologist))),
    )
  }, [registerRegistrationYearPsychologist])

  const { specializations } = useTherapistSpecializations()

  const { open } = useModals()

  const openTherapistModal = useCallback(() => {
    open(`therapistProfile:${id}`)
  }, [id, open])

  const showYearsOfExperience = useMemo(() => shouldShowYearsOfExperience(years), [years])

  const isTherapyCoaching = therapyPath.type === 'PATH_COACHING'

  const { isPsychiatrist } = useTherapistProfessionTypeValue()
  const showNumberOfPatients = !isPsychiatrist && !isTherapyCoaching && numberOfPatients >= 10

  const showOrientation =
    therapyPath.type !== 'PATH_COACHING' && therapyPath.type !== 'MYSELF_PSYCHIATRY' && !!orientation

  return (
    <>
      <TherapistCardBox background="white" borderColor="purple06" borderSize={1} grow={1} shrink={1}>
        <MountOn mediaQuery="lt-lg">
          <>
            <MountOn mediaQuery="sm">
              <Flex>
                <Flex $direction="row">
                  <Zoom zoomImg={{ src: profileImage?.l }}>
                    <Avatar image={profileImage?.s} name={fullName} size="sm" />
                  </Zoom>
                  <Flex $shrink={1}>
                    <Flex $px={8}>
                      <TherapistFullName fullName={fullName} kind="paragraph" />
                    </Flex>
                    <Flex $mdAlign="flex-start" $pt={4} $px={8}>
                      <Text colorName="grey-11" kind="caption" textAlign="left">
                        <Translation id={jobTitleTranslationId} />
                      </Text>

                      {showOrientation && <TherapistOrientation orientation={orientation} textKind="caption" />}
                    </Flex>
                  </Flex>
                </Flex>
                <TherapistBadgeCertifications onClick={openTherapistModal} />
              </Flex>
            </MountOn>
            <MountOn mediaQuery="md">
              <Flex>
                <Flex $direction="row">
                  <Zoom zoomImg={{ src: profileImage?.l }}>
                    <Avatar image={profileImage?.s} name={fullName} size="lg" />
                  </Zoom>
                  <Flex $shrink={1}>
                    <Flex $px={8}>
                      <TherapistFullName fullName={fullName} kind="h3" />
                    </Flex>
                    <Flex $mdAlign="flex-start" $pt={4} $px={8}>
                      <Text colorName="grey-11" kind="paragraph">
                        <Translation id={jobTitleTranslationId} />
                      </Text>

                      {showOrientation && <TherapistOrientation orientation={orientation} />}
                    </Flex>
                  </Flex>
                </Flex>
                <TherapistBadgeCertifications onClick={openTherapistModal} />
              </Flex>
            </MountOn>
            <Flex $direction="row" $py={16}>
              {showYearsOfExperience && (
                <Flex $align="center" $direction="row" $pr={16} $pt={4} $shrink={1}>
                  <Flex $pr={8}>
                    <Icon colorName="grey-11" name="brain" size={24} />
                  </Flex>
                  <Text kind="caption" textAlign="left">
                    <Translation id="therapist.ourChoice.yearOfExperience" values={{ years }} />
                  </Text>
                </Flex>
              )}

              {!isTherapyCoaching && showNumberOfPatients && (
                <Flex $align="center" $direction="row" $pt={4} $shrink={1}>
                  <Flex $pr={8}>
                    <Icon colorName="grey-11" name="group" size={24} />
                  </Flex>
                  <Text kind="caption" textAlign="left">
                    <Translation id="therapist.ourChoice.numberOfPatients" values={{ numberOfPatients }} />
                  </Text>
                </Flex>
              )}
              {isTherapyCoaching && (
                <Flex $align="center" $direction="row" $pt={4}>
                  <Flex $pr={8}>
                    <Icon colorName="grey-11" name="user-star" size={24} />
                  </Flex>
                  <Text kind="caption" textAlign="left">
                    <Translation id="therapist.ourChoice.certification" />
                  </Text>
                </Flex>
              )}
            </Flex>
            {!isTherapyCoaching && (
              <>
                <Divider color="purple06" />

                {specializations.length > 0 && (
                  <Flex $pt={16}>
                    <Text fontWeight="600" kind="paragraph" textAlign="left">
                      <Translation id="therapist.ourChoice.specializations" />
                    </Text>
                    {specializations.slice(0, 3).map(({ name, id }) => (
                      <Flex key={id} $align="center" $direction="row" $pt={4}>
                        <Flex $pr={8}>
                          <Icon colorName="grey-11" name="book-stack" size={24} />
                        </Flex>
                        <Text kind="caption">{name}</Text>
                      </Flex>
                    ))}
                  </Flex>
                )}
              </>
            )}
          </>
          {!!emailVerified && (
            <Flex $pt={16}>
              <Button kind="secondary" onClick={openTherapistModal} size="sm" type="button">
                <Translation id="suggestedTherapist.moreInfo" />
              </Button>
            </Flex>
          )}
        </MountOn>
        <MountOn mediaQuery="gt-md">
          <Flex $direction="row">
            <Flex $basis="40%">
              <Flex $direction="row">
                <Zoom zoomImg={{ src: profileImage?.l }}>
                  <Avatar image={profileImage?.s} name={fullName} size="lg" />
                </Zoom>
                <Flex $shrink={1}>
                  <Flex $px={8}>
                    <TherapistFullName fullName={fullName} kind="h3" />
                  </Flex>
                  <Flex $mdAlign="flex-start" $pt={4} $px={8}>
                    <Text colorName="grey-11" kind="paragraph">
                      <Translation id={jobTitleTranslationId} />
                    </Text>

                    {showOrientation && <TherapistOrientation orientation={orientation} />}
                  </Flex>
                </Flex>
              </Flex>
              <TherapistBadgeCertifications onClick={openTherapistModal} />
            </Flex>

            <Flex $basis="30%" $pl={16} $shrink={1}>
              {(showYearsOfExperience || showNumberOfPatients) && (
                <Text fontWeight="600" kind="paragraph" textAlign="left">
                  <Translation id="therapist.ourChoice.shortExperience" />
                </Text>
              )}
              {showYearsOfExperience && (
                <Flex $align="center" $direction="row" $pt={4}>
                  <Flex $pr={8}>
                    <Icon colorName="grey-11" name="brain" size={24} />
                  </Flex>
                  <Text kind="caption" textAlign="left">
                    <Translation id="therapist.ourChoice.yearOfExperience" values={{ years }} />
                  </Text>
                </Flex>
              )}
              {!isTherapyCoaching && showNumberOfPatients && (
                <Flex $align="center" $direction="row" $pt={4}>
                  <Flex $pr={8}>
                    <Icon colorName="grey-11" name="group" size={24} />
                  </Flex>
                  <Text kind="caption" textAlign="left">
                    <Translation id="therapist.ourChoice.numberOfPatients" values={{ numberOfPatients }} />
                  </Text>
                </Flex>
              )}
              {isTherapyCoaching && (
                <Flex $align="center" $direction="row" $pt={4}>
                  <Flex $pr={8}>
                    <Icon colorName="grey-11" name="user-star" size={24} />
                  </Flex>
                  <Text kind="caption" textAlign="left">
                    <Translation id="therapist.ourChoice.certification" />
                  </Text>
                </Flex>
              )}
            </Flex>
            {!isTherapyCoaching && (
              <Flex $basis="30%">
                {specializations.length > 0 && (
                  <Flex $pl={16} $shrink={1}>
                    <Text fontWeight="600" kind="paragraph" textAlign="left">
                      <Translation id="therapist.ourChoice.specializations" />
                    </Text>
                    {specializations.slice(0, 3).map(({ name }) => (
                      <Flex key={name} $align="center" $direction="row" $pt={4}>
                        <Flex $pr={8}>
                          <Icon colorName="grey-11" name="book-stack" size={24} />
                        </Flex>
                        <Text kind="caption" textAlign="left">
                          {name}
                        </Text>
                      </Flex>
                    ))}
                  </Flex>
                )}
              </Flex>
            )}
          </Flex>
          {!!emailVerified && (
            <Flex $pt={16}>
              <Button kind="secondary" onClick={openTherapistModal} size="sm" type="button">
                <Translation id="suggestedTherapist.moreInfo" />
              </Button>
            </Flex>
          )}
        </MountOn>
      </TherapistCardBox>
      <TherapistProfileModal therapyPathType={therapyPath.type} />
    </>
  )
}
