import {
  addSeconds,
  eachHourOfInterval,
  eachMinuteOfIntervalWithOptions,
  getHours,
  getMinutes,
  subMilliseconds,
} from 'date-fns/fp'
import { format, FormatDateEnum } from 'dates'
import { pipe } from 'fp-ts/function'
import { getTherapySessionAvailabilityDurationInSeconds } from '~/utils/getTherapySessionAvailabilityDurationInSeconds'
import { firstAllowedAvailabilityStartAt, lastAllowedAvailabilityStartAt } from '../constants/availabilityAllowed'

export const getAvailabilityHours = () =>
  eachHourOfInterval({
    start: firstAllowedAvailabilityStartAt,
    end: pipe(lastAllowedAvailabilityStartAt, addSeconds(getTherapySessionAvailabilityDurationInSeconds())),
  }).map((date) => ({
    label: pipe(date, format(FormatDateEnum.HOURS)),
    value: pipe(date, getHours),
  }))

export const getAvailabilityMinutes = () =>
  eachMinuteOfIntervalWithOptions({ step: 15 })({
    start: firstAllowedAvailabilityStartAt,
    end: pipe(
      firstAllowedAvailabilityStartAt,
      addSeconds(getTherapySessionAvailabilityDurationInSeconds()),
      subMilliseconds(1),
    ),
  }).map((date) => ({
    label: pipe(date, format(FormatDateEnum.MINUTES)),
    value: pipe(date, getMinutes),
  }))
