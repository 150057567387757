import { Flex } from 'ui-deprecated'
import { PatientTabMenu } from './PatientTabMenu'
import { MenuVerticalWrapper } from './components/MenuVerticalWrapper'
import { MenuVerticalWrapperFullScreen } from './components/MenuVerticalWrapperFullScreen'
import { MenuVerticalWrapperOverlay } from './components/MenuVerticalWrapperOverlay'
import { useTabNavigationMenu } from './hooks/useTabNavigationMenu'
import { RouteChatMenuItem } from './routes/ChatMenuItem'
import { RouteHomeMenuItem } from './routes/HomeMenuItem'
import { RouteJournalingMenuItem } from './routes/JournalingMenuItem'
import { RouteSettingsMenuItem } from './routes/SettingsMenuItem'
import { RouteTherapiesMenuItem } from './routes/TherapiesMenuItem'

export const PatientTabNavigationVertical = () => {
  const { open } = useTabNavigationMenu()

  return (
    <>
      <Flex background="purple08" basis="78px" justify="center">
        <RouteHomeMenuItem direction="vertical" />

        <RouteTherapiesMenuItem direction="vertical" />

        <RouteChatMenuItem direction="vertical" />

        <RouteJournalingMenuItem direction="vertical" />

        <RouteSettingsMenuItem direction="vertical" />
      </Flex>

      {open && (
        <MenuVerticalWrapperFullScreen direction="row">
          <MenuVerticalWrapper background="white" basis="360px">
            <PatientTabMenu />
          </MenuVerticalWrapper>

          <MenuVerticalWrapperOverlay />
        </MenuVerticalWrapperFullScreen>
      )}
    </>
  )
}
