import loadable from '@loadable/component'
import { Flex, MountOn, OverflowAuto } from 'cdk'
import { BORDER_WIDTH_0_5 } from 'design-tokens'
import { PropsWithChildren, useMemo } from 'react'
import styled from 'styled-components'
import { Divider } from 'ui'
import { useTherapistSpecializations } from '~/domains/agenda/hooks/useTherapistSpecializations'
import { useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { useTherapist } from '~/hooks/useTherapist'
import { useLanguage } from '~/i18n/hooks/useLanguage'
import { TherapyTherapyPathType } from '~/types/graphql'
import { Reviews } from './Reviews'
import { TherapistAbout } from './TherapistAbout'
import { TherapistAboutCoach } from './TherapistAboutCoach'
import { TherapistCertifications } from './TherapistCertifications'
import { TherapistCurriculum } from './TherapistCurriculum'
import { TherapistSummary } from './TherapistSummary'
import { TherapistWhatDoes } from './TherapistWhatDoes'
import { TherapistWhatYouShouldKnow } from './TherapistWhatYouShouldKnow'

const LazyTherapistCarousel = loadable(() => import('./TherapistCarousel/index.lazy'), {
  resolveComponent: (components) => components.TherapistCarousel,
})

const ModalCloseButtonContainer = styled(Flex).attrs({
  $mdPb: 0,
  $px: 16,
  $py: 8,
  $borderColorName: 'violet-30',
  $borderSizeBottom: BORDER_WIDTH_0_5,
})`
  /* stylelint-disable-next-line no-empty-source */
`

type TherapistPresentationCardOneColumnLayout = PropsWithChildren<{
  therapyPathType: TherapyTherapyPathType
}>

export const TherapistPresentationCardOneColumnLayout = ({
  children,
  therapyPathType,
}: TherapistPresentationCardOneColumnLayout) => {
  const { specializations } = useTherapistSpecializations()
  const { id, pathologies, therapist } = useTherapist()
  const { isOpen } = useModals()
  const { language } = useLanguage()

  const isInModal = useMemo(() => isOpen(`therapistProfile:${id}`), [id, isOpen])
  const isTherapyCoaching = therapyPathType === 'PATH_COACHING'
  const showCurriculum = !!therapist.professionalExperiences.length
  const showTherapistCertifications = !!therapist.therapistCertifications?.length

  return (
    <>
      {isInModal && (
        <Flex>
          <ModalCloseButtonContainer>
            <ModalCloseButton id={`therapistProfile:${id}`} />
          </ModalCloseButtonContainer>
        </Flex>
      )}
      <OverflowAuto>
        <Flex $pb={16} $pt={8} $px={24}>
          <TherapistSummary isOneColumnLayout therapyPathType={therapyPathType} />
        </Flex>
        <Flex $p={24} className="one-column-layout">
          <TherapistWhatYouShouldKnow therapyPathType={therapyPathType} />
          {showTherapistCertifications && (
            <>
              <Flex $py={24}>
                <Divider />
              </Flex>
              <TherapistCertifications />
            </>
          )}
          {!isTherapyCoaching && [...pathologies, ...specializations].length > 0 && (
            <>
              <Flex $py={24}>
                <Divider />
              </Flex>
              <TherapistWhatDoes therapyPathType={therapyPathType} />
            </>
          )}
          {showCurriculum && (
            <>
              <Flex $py={24}>
                <Divider />
              </Flex>
              <TherapistCurriculum />
            </>
          )}
          <Flex $py={24}>
            <Divider />
          </Flex>

          {isTherapyCoaching && language === 'it' && <TherapistAboutCoach />}
          {!isTherapyCoaching && language === 'it' && <TherapistAbout />}

          <Flex $py={24}>
            <LazyTherapistCarousel therapyPathType={therapyPathType} />
          </Flex>
          <Flex $pb={24}>
            <Reviews />
          </Flex>
          {children}
        </Flex>
      </OverflowAuto>
    </>
  )
}

type Props = {
  therapyPathType: TherapyTherapyPathType
}

export const TherapistPresentationCard = ({ therapyPathType }: Props) => {
  const { id, pathologies, therapist } = useTherapist()
  const { specializations } = useTherapistSpecializations()
  const { language } = useLanguage()

  const isTherapyCoaching = therapyPathType === 'PATH_COACHING'
  const showCurriculum = !!therapist.professionalExperiences.length
  const showTherapistCertifications = !!therapist.therapistCertifications?.length

  return (
    <>
      <MountOn mediaQuery="lt-lg">
        <TherapistPresentationCardOneColumnLayout therapyPathType={therapyPathType} />
      </MountOn>
      <MountOn mediaQuery="gt-md">
        <ModalCloseButtonContainer>
          <ModalCloseButton id={`therapistProfile:${id}`} />
        </ModalCloseButtonContainer>
        <OverflowAuto $direction="row" $p={24} $pb={24}>
          <Flex $basis="33%" $gap={16} $pr={24}>
            <Flex>
              <TherapistSummary therapyPathType={therapyPathType} />
            </Flex>
            <Flex>
              <LazyTherapistCarousel therapyPathType={therapyPathType} />
            </Flex>
            <Flex $pb={16}>
              <Reviews />
            </Flex>
          </Flex>
          <Flex $grow={1} $shrink={1}>
            <TherapistWhatYouShouldKnow therapyPathType={therapyPathType} />
            {showTherapistCertifications && (
              <>
                <Flex $py={24}>
                  <Divider />
                </Flex>
                <TherapistCertifications />
              </>
            )}
            {!isTherapyCoaching && [...pathologies, ...specializations].length > 0 && (
              <>
                <Flex $py={24}>
                  <Divider />
                </Flex>
                <TherapistWhatDoes therapyPathType={therapyPathType} />
              </>
            )}
            {showCurriculum && (
              <>
                <Flex $py={24}>
                  <Divider />
                </Flex>
                <TherapistCurriculum />
              </>
            )}
            <Flex $py={24}>
              <Divider />
            </Flex>

            {isTherapyCoaching && language === 'it' && (
              <Flex $pb={24}>
                <TherapistAboutCoach />
              </Flex>
            )}
            {!isTherapyCoaching && language === 'it' && (
              <Flex $pb={24}>
                <TherapistAbout />
              </Flex>
            )}
          </Flex>
        </OverflowAuto>
      </MountOn>
    </>
  )
}
