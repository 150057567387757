import { colors } from '@serenis-health/tokens'
import styled from 'styled-components'
import { Flex } from 'ui-deprecated'

export const ConventionCodeDescription = styled(Flex)`
  border-top: 1px solid ${colors.purple08};
  border-right: 1px solid ${colors.purple08};
  border-bottom: 1px solid ${colors.purple08};
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
`
