import { Flex } from 'cdk'
import { Icon } from 'icons'
import { useMemo } from 'react'
import { Chip } from 'ui'
import { Translation } from '~/components/Translation'
import { useTherapist } from '~/hooks/useTherapist'
import { TranslationId } from '~/i18n/types'

type LabelTranslationId = Extract<TranslationId, `therapist.presentationCard.certifications.${string}.title.${string}`>

export const TherapistChipOnlineTherapy = () => {
  const {
    therapist: { sex },
  } = useTherapist()

  const labelId = useMemo((): LabelTranslationId => {
    const gender = sex === 1 ? 'male' : 'female'
    return `therapist.presentationCard.certifications.online.title.${gender}`
  }, [sex])

  return (
    <Chip kind="success">
      <Flex $align="center" $direction="row">
        <Flex $pr={4}>
          <Icon colorName="green-10" name="laptop" size={16} />
        </Flex>
        <Translation id={labelId} />
      </Flex>
    </Chip>
  )
}
