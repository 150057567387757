import loadable from '@loadable/component'
import { useCallback } from 'react'
import styled from 'styled-components'
import { Text } from 'ui'
import { Flex, OverflowAuto, PositionAbsolute, PositionRelative, Pressable } from 'ui-deprecated'
import { TertiaryButtonIcon } from '~/components/TertiaryButton/TertiaryButtonIcon'
import { TimelineItem, VerticalDash } from '~/components/Timeline'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { Modal } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { MenuExternalLink } from '~/domains/navigation/TabNavigation/components/MenuExternalLink'
import { useBreakpoints } from '~/hooks/useBreakpoints'
import { useCopyToClipboard } from '~/hooks/useCopyToClipboard'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useToasts } from '~/hooks/useToasts'
import { useTranslate } from '~/i18n/hooks/useTranslation'
import { buildReferralSharedLink } from '../../utils/buildReferralSharedLink'

const Fallback = styled.div`
  width: 100%;
  height: 150px;
`

const LazyModalHeader = loadable(() => import('./ModalHeader.lazy'), {
  resolveComponent: (components) => components.ModalHeader,
  fallback: <Fallback />,
})

const FullWidthOverflow = styled(OverflowAuto)`
  margin: 8px -24px -24px;
`

const ModalFooter = styled(Flex)`
  z-index: 999;
  margin: 0 -24px -24px;
  padding-bottom: 0;
`

export const ShareReferralCodeModal = () => {
  const { referralCode } = useCurrentUser()

  const { addToast } = useToasts()

  const [, copy] = useCopyToClipboard()

  const { isSmallOnly } = useBreakpoints()

  const handleCopyLink = useCallback(async () => {
    if (referralCode) {
      const result = await copy(buildReferralSharedLink(referralCode))

      addToast(
        result
          ? { translationId: 'referralCode.share.copied', type: 'success' }
          : { translationId: 'generic.error', type: 'alert' },
      )
    }
  }, [addToast, copy, referralCode])

  const translate = useTranslate()

  const handleShareLink = useCallback(async () => {
    try {
      if (referralCode) {
        navigator.share({
          text: translate('referralCode.shared.body'),
          url: buildReferralSharedLink(referralCode),
        })
      }
    } catch (error) {
      addToast({ translationId: 'generic.error', type: 'alert' })
    }
  }, [addToast, referralCode, translate])

  return (
    <Modal id="referralShare">
      <ModalCloseButton id="referralShare" />
      <FullWidthOverflow>
        <LazyModalHeader />
        <Flex pb={32} px={24}>
          <Flex pt={24}>
            <Text fontWeight="600" kind="h3">
              <Translation id="referralCode.share.modal.title" />
            </Text>
          </Flex>
          <Flex pt={8}>
            <Text fontWeight="400" kind="paragraph">
              <Translation id="referralCode.share.modal.body" />
            </Text>
          </Flex>
          <Flex pt={16}>
            <Text fontWeight="600" kind="paragraph">
              <Translation id="referralCode.share.modal.subtitle" />
            </Text>
          </Flex>
          <Flex pt={8}>
            <PositionRelative>
              <PositionAbsolute bottom="0" top="0">
                <Flex pt={16} />

                <VerticalDash />
              </PositionAbsolute>

              <Flex>
                <TimelineItem circleOuterColorName="purple-06">
                  <TranslationMarkdown colorName="black" id="referralCode.share.modal.bulletPoint.1" kind="paragraph" />
                </TimelineItem>
                <TimelineItem circleOuterColorName="purple-06">
                  <TranslationMarkdown colorName="black" id="referralCode.share.modal.bulletPoint.2" kind="paragraph" />
                </TimelineItem>
                <TimelineItem circleOuterColorName="purple-06">
                  <TranslationMarkdown colorName="black" id="referralCode.share.modal.bulletPoint.3" kind="paragraph" />
                </TimelineItem>
              </Flex>
            </PositionRelative>

            <TimelineItem circleOuterColorName="purple-06">
              <TranslationMarkdown colorName="black" id="referralCode.share.modal.bulletPoint.4" kind="paragraph" />
            </TimelineItem>
          </Flex>

          <Flex pt={24}>
            <Text fontWeight="600" kind="caption">
              <Translation id="referralCode.share.modal.disclaimer" />
            </Text>

            <Flex pt={24}>
              <MenuExternalLink to="https://www.serenis.it/terms">
                <Text colorName="purple-08" fontWeight="600" kind="caption" textDecoration="underline">
                  <Translation id="referralCode.share.modal.terms" />
                </Text>
              </MenuExternalLink>
            </Flex>
          </Flex>
        </Flex>
      </FullWidthOverflow>
      <ModalFooter>
        <Flex align="center" background="grey02" direction="row" justify="space-between" px={24} py={24}>
          <Text fontWeight="600" kind="paragraph">
            <Translation id="referralCode.share.modal.footer" />
          </Text>
          <Flex align="center" direction="row">
            <Pressable autoWidth onClick={handleCopyLink}>
              <TertiaryButtonIcon borderColor="purple06" colorName="purple-08" iconName="copy" />
            </Pressable>
            {isSmallOnly && (
              <Flex pl={8}>
                <Pressable autoWidth onClick={handleShareLink}>
                  <TertiaryButtonIcon borderColor="purple06" colorName="purple-08" iconName="send-diagonal" />
                </Pressable>
              </Flex>
            )}
          </Flex>
        </Flex>
      </ModalFooter>
    </Modal>
  )
}
