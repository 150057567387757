import { Icon, IconName, IconSize } from 'icons'
import { TherapyTherapyPathType } from '~/types/graphql'

type StartTherapyPathIconProps = {
  size: IconSize
  therapyPathType: TherapyTherapyPathType | 'PATH_NUTRITION_DCA' | 'PATH_NUTRITION_WEIGHT_LOSS' | null
}

const iconByTherapyPathIcon: Record<
  NonNullable<StartTherapyPathIconProps['therapyPathType']>,
  Extract<IconName, `path-${string}`>
> = {
  COUPLES_PSYCHOTHERAPY: 'path-couples',
  MYSELF_PSYCHIATRY: 'path-psychiatry',
  MYSELF_PSYCHOTHERAPY: 'path-myself',
  PATH_COACHING: 'path-coaching',
  PATH_NUTRITION_DCA: 'path-nutrition-dca',
  PATH_NUTRITION_WEIGHT_LOSS: 'path-nutrition-weight-loss',
  PATH_SEXOLOGY: 'path-sexology',
  PATH_ASSERTIVE_COMMUNICATION: 'path-myself',
  PATH_SLEEP: 'path-myself',
  UNDERAGE_PSYCHOTHERAPY: 'path-myself',
}

export const StartTherapyPathIcon = ({ size, therapyPathType }: StartTherapyPathIconProps) => {
  if (therapyPathType) {
    return <Icon name={iconByTherapyPathIcon[therapyPathType]} size={size} />
  }

  return null
}
