import { Icon } from 'icons'
import { memo } from 'react'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { useAuthMethods } from '~/domains/auth/hooks/useAuthMethods'
import { MenuAction } from '~/domains/navigation/TabNavigation/components/MenuAction'

export const MenuItemLogout = memo(() => {
  const { signOut } = useAuthMethods()

  return (
    <MenuAction onClick={signOut}>
      <Flex pr={12}>
        <Icon colorName="grey-08" name="log-out" size={24} />
      </Flex>

      <Flex align="flex-start" grow={1} shrink={1}>
        <Text fontWeight="400" kind="paragraph">
          <Translation id="menu.logout" />
        </Text>
      </Flex>
    </MenuAction>
  )
})

MenuItemLogout.displayName = 'MenuItemLogout'
