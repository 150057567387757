import { colors } from '@serenis-health/tokens'
import { useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import styled, { css } from 'styled-components'
import { Avatar, Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { UserTherapist } from '~/domains/therapist/types'
import { useAvailableTimeSlotsByDayAndTherapists } from '../../hooks/useTimeSlots'
import { getAvailabilityDayHeaderId } from '../../utils/getAvailabilityDayHeaderId'
import { AvailabilityDayHeaderOnMultipleLines } from './AvailabilityDayHeader'
import { BookingAvailabilitiesSkeleton } from './BookingLoaderSkeleton'
import { EmptySlot, Slot } from './Slot'

const FlexRowWithGap = styled(Flex).attrs({
  direction: 'row',
  grow: 1,
})`
  gap: 8px 24px;
`

const FlexColumnWithGap = styled(Flex).attrs({
  wrap: 'wrap',
  justify: 'center',
})<{ col: 2 | 3 }>`
  flex-basis: ${({ col }) => (col === 2 ? '50%' : 'calc(33.333% - 4px)')};
  row-gap: 8px;
  margin-right: -12px;
  padding-right: 12px;

  &:not(:last-of-type) {
    border-right: 1px solid ${colors.purple06};
  }
`

const PositionSticky = styled(Flex)<{ stuck?: boolean }>`
  position: sticky;
  top: 0;
  background: ${colors.white};
  ${({ stuck }) =>
    stuck &&
    css`
      border-bottom: 2px solid ${colors.purple04};
    `}
`

type Props = {
  therapists: UserTherapist[]
  onTimeSlotSelected: (timeSlot: Date, therapistId: string) => void
  filterByTimeInterval: boolean
}

export const MultipleSuggestionAvailabilitiesMedium = ({
  filterByTimeInterval,
  therapists,
  onTimeSlotSelected,
}: Props) => {
  const { loading, slotsByDayAndTherapist, slotsByDayAndTherapistFilteredByTimeInterval } =
    useAvailableTimeSlotsByDayAndTherapists()

  const selectedSlots = useMemo(
    () => (filterByTimeInterval ? slotsByDayAndTherapistFilteredByTimeInterval : slotsByDayAndTherapist),
    [filterByTimeInterval, slotsByDayAndTherapist, slotsByDayAndTherapistFilteredByTimeInterval],
  )

  const { ref, inView } = useInView({
    threshold: 0,
  })

  const col = therapists.length === 2 ? 2 : 3

  if (loading) {
    return (
      <Flex pt={32}>
        <BookingAvailabilitiesSkeleton />
      </Flex>
    )
  }
  return (
    <>
      <Flex ref={ref} basis="1px" />
      <PositionSticky direction="row" grow={1} stuck={!inView}>
        <Flex basis="120px" />
        <FlexRowWithGap>
          {therapists.map(({ fullName, id, therapist: { profileImage } }) => (
            <FlexColumnWithGap key={id} col={col} py={16}>
              <Flex align="center" direction="row">
                <Avatar image={profileImage?.s} name={fullName} size="xs" />
                <Flex pl={8} shrink={1}>
                  <Text fontWeight="600" kind="caption" textAlign="left">
                    {fullName}
                  </Text>
                </Flex>
              </Flex>
            </FlexColumnWithGap>
          ))}
        </FlexRowWithGap>
      </PositionSticky>
      {selectedSlots.map(([day, slotsByTherapist]) => (
        <Flex key={day} direction="row" id={getAvailabilityDayHeaderId(day)}>
          <PositionSticky basis="120px">
            <AvailabilityDayHeaderOnMultipleLines day={day} pt={16} />
          </PositionSticky>
          <FlexRowWithGap>
            {slotsByTherapist.map(([therapistId, slots], therapistIndex) => (
              <FlexColumnWithGap key={therapistIndex} col={col} pb={32} pt={16}>
                {slots.map((slot, index) => {
                  if (slot === 'PLACEHOLDER' || therapistId === null) {
                    return (
                      <Flex key={index}>
                        <EmptySlot />
                      </Flex>
                    )
                  }

                  return (
                    <Flex key={`${index}-${slot.getTime()}`}>
                      <Slot onSelected={onTimeSlotSelected} slot={slot} therapistId={therapistId} />
                    </Flex>
                  )
                })}
              </FlexColumnWithGap>
            ))}
          </FlexRowWithGap>
        </Flex>
      ))}
    </>
  )
}
