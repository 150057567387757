import { isSameDay } from 'date-fns/fp'
import { nowInMilliseconds } from 'dates'
import { Day } from '../types'
import { getCurrentMonthDays } from './getCurrentMonthDays'
import { getPreviousMonthDays } from './getPreviousMonthDays'
import { isCalendarDayDisabled } from './isCalendarDayDisabled'

type Param = {
  date: Date
  disabledBeforeDate: Date
  events: Date[]
  /*
  uncontrolled state for calendar selection
  */
  selectedDay: Date
  /*
  controlled day - overrides selectedDay when available
  */
  selectedDate?: Date
}

export const getCalendarDays = ({ date, disabledBeforeDate, events, selectedDay, selectedDate }: Param): Day[] => {
  const today = nowInMilliseconds()

  const previousMonthDays = getPreviousMonthDays(date)

  const currentMonthDays = getCurrentMonthDays(date)

  const isDisabled = isCalendarDayDisabled({ disabledBeforeDate })

  return previousMonthDays.concat(currentMonthDays).map((day) => ({
    ...day,
    disabled: isDisabled(day),
    hasEvent: events.filter((date) => isSameDay(date, day.date)).length,
    selected: isSameDay(day.date, selectedDate ?? selectedDay),
    today: isSameDay(day.date, today),
  }))
}
