import { COLOR_VIOLET_40, COLOR_VIOLET_50 } from 'design-tokens'
import ContentLoader from 'react-content-loader'
import { CardBox } from 'ui-deprecated'

const props = {
  backgroundColor: COLOR_VIOLET_40,
  foregroundColor: COLOR_VIOLET_50,
  speed: 1.5,
  viewBox: '0 0 400 24',
}

export const PaymentMethodElementSkeleton = () => (
  <CardBox background="purple02">
    <ContentLoader {...props}>
      <circle cx="12" cy="12" r="10" />
      <rect height="6" rx="3" ry="3" width="100" x="40" y="8" />
      <rect height="6" rx="3" ry="3" width="160" x="240" y="8" />
    </ContentLoader>
  </CardBox>
)
