import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { ReactHookFormCheckboxField } from '../components'

export const FieldMarketingEmails = () => {
  const { labels } = useFormTranslations()

  return (
    <ReactHookFormCheckboxField name="marketingEmails">
      <Flex pt={2}>
        <Text as="label" fontWeight="400" htmlFor="marketingEmails" kind="caption">
          {labels.marketingEmailsVariant}
        </Text>
      </Flex>
    </ReactHookFormCheckboxField>
  )
}
