import { Spacing } from '@serenis-health/tokens'
import styled from 'styled-components'
import { Flex } from 'ui-deprecated'
import { EmptySlot, Slot } from './Slot'

const FlexRowWithGapWrap = styled(Flex).attrs({
  direction: 'row',
  wrap: 'wrap',
  shrink: 1,
  grow: 1,
})`
  gap: 8px 24px;
  ${Flex} {
    flex-basis: calc((100% - 48px) / 3);
  }
`

export const SlotsByDay = ({
  onTimeSlotSelected,
  slots,
  therapistId,
  py = 0,
}: {
  onTimeSlotSelected: (timeSlot: Date, therapistId: string) => void
  slots: (Date | 'PLACEHOLDER')[]
  therapistId: string
  py?: Spacing
}) => (
  <FlexRowWithGapWrap py={py}>
    {slots.map((slot, index) => {
      if (slot === 'PLACEHOLDER' || therapistId === null) {
        return (
          <Flex key={index}>
            <EmptySlot />
          </Flex>
        )
      }

      return (
        <Flex key={`${index}-${slot.getTime()}`}>
          <Slot onSelected={onTimeSlotSelected} slot={slot} therapistId={therapistId} />
        </Flex>
      )
    })}
  </FlexRowWithGapWrap>
)
