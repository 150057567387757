import { throttle } from 'lodash-es'
import { useEffect, useState } from 'react'

type WindowSize = {
  width: number
  height: number
}

export const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const handler = throttle(
      () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      },
      500,
      {
        leading: true,
      },
    )

    handler()

    window.addEventListener('resize', handler)

    return () => {
      window.removeEventListener('resize', handler)
    }
  }, [])

  return windowSize
}
