import { ReactNode } from 'react'
import { PositionRelative } from 'ui-deprecated'

type Props = {
  children: ReactNode
}

export const TabNavigationHorizontalContainer = ({ children }: Props) => (
  <PositionRelative background="purple08" basis="78px" borderRadius={16} direction="row" justify="center" zIndex={101}>
    {children}
  </PositionRelative>
)

TabNavigationHorizontalContainer.displayName = 'TabNavigationHorizontalContainer'
