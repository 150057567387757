import { ColorName } from '@serenis-health/tokens'
import { ConventionCode } from '../types/conventionCode'

export const getBackgroundColorName = (status: ConventionCode['status']): ColorName => {
  switch (status) {
    case 'INACTIVE':
    case 'EXPIRED':
    case 'COMPLETED':
      return 'purple02'
    case 'ACTIVE':
    default:
      return 'purple08'
  }
}
