import { addSeconds, isSameDay } from 'date-fns/fp'
import { nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Text } from 'ui'
import { CardBox, Divider, Flex, Form, MaxWidth640px, PageLayout, View } from 'ui-deprecated'
import { Calendar } from '~/components/Calendar'
import { useCalendarAvailabilities } from '~/components/Calendar/hooks/useCalendarAvailabilites'
import { Day } from '~/components/Calendar/types'
import { PageRoute } from '~/components/PageRoute'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { CalendarBookingTimeslotSkeleton } from '~/domains/reservation/components/CalendarBookingTimeslotSkeleton'
import { Head } from '~/domains/reservation/components/Head'
import { TimeSlotList } from '~/domains/reservation/components/TimeSlotList'
import { useRegisterSelectedTimeSlot } from '~/domains/reservation/hooks/useRegisterSelectedTimeSlot'
import { useReservationFlow } from '~/domains/reservation/hooks/useReservationFlow'
import { ReservationFormValues } from '~/domains/reservation/types'
import { useScrollIntoViewOnce } from '~/hooks/useScrollIntoViewOnce'
import { useTherapySessionMeetingDuration } from '~/hooks/useTherapySessionMeetingDuration'
import { PageScrollEffect } from '~/routes/PageScrollEffect'
import { getTherapySessionCancellationPolicyInSeconds } from '~/utils/getTherapySessionCancellationPolicyInSeconds'
import { useTherapyPathById } from '../hooks/useTherapyPathById'

export const BookByMatchedTherapists = memo(() => {
  useRegisterSelectedTimeSlot('book-by-availabilities')

  const { therapyPath } = useTherapyPathById()

  const initialStartAt = useMemo(
    () => pipe(nowInMilliseconds(), addSeconds(getTherapySessionCancellationPolicyInSeconds(therapyPath.type))),
    [therapyPath.type],
  )

  const { scrollElementRef, scrollIntoViewOnce } = useScrollIntoViewOnce()

  const form = useReactHookFormContext<ReservationFormValues>()

  const { minutes } = useTherapySessionMeetingDuration({
    duration: 1,
    firstTherapySession: true,
    therapyPathType: therapyPath.type,
  })

  const { matchedTherapistIds, onDayAndTimeSlotSelected } = useReservationFlow()

  const { availabilities, initialLoading, loading, start, goTo } = useCalendarAvailabilities(
    matchedTherapistIds,
    initialStartAt,
    therapyPath.type,
  )

  const [selectedDate, setSelectedDate] = useState<Date | null>(null)

  const [selectedTimeSlot, setSelectedTimeSlot] = useState<Date | null>(null)

  const selectedDayAvailabilities = useMemo(
    () => availabilities.filter((timeSlotDate) => isSameDay(timeSlotDate, selectedDate ?? start)),
    [availabilities, selectedDate, start],
  )

  const updateSelectedTimeSlotFormState = useCallback(
    (selectedTimeSlot: Date | null) => {
      form.setValue('selectedTimeSlot', selectedTimeSlot)
      form.trigger('selectedTimeSlot')
    },
    [form],
  )

  const onTimeSlotSelected = useCallback(
    (timeSlot: Date) => {
      setSelectedDate(timeSlot)
      setSelectedTimeSlot(timeSlot)
      updateSelectedTimeSlotFormState(timeSlot)
    },
    [updateSelectedTimeSlotFormState],
  )

  const onChangeDay = useCallback(
    (newSelectedDay: Day) => {
      setSelectedTimeSlot(null)
      updateSelectedTimeSlotFormState(null)
      setSelectedDate(newSelectedDay.date)
      scrollIntoViewOnce()
    },
    [scrollIntoViewOnce, updateSelectedTimeSlotFormState],
  )

  const onChangeMonth = useCallback(
    (newStartAt: Date) => {
      setSelectedDate(null)
      setSelectedTimeSlot(null)
      updateSelectedTimeSlotFormState(null)
      goTo(newStartAt)
      scrollIntoViewOnce()
    },
    [goTo, scrollIntoViewOnce, updateSelectedTimeSlotFormState],
  )

  const onSubmit = useCallback(
    ({ selectedTimeSlot }: ReservationFormValues) => {
      if (!selectedTimeSlot) {
        return
      }

      onDayAndTimeSlotSelected(selectedTimeSlot)
    },
    [onDayAndTimeSlotSelected],
  )

  useEffect(() => {
    if (!start || selectedDate) {
      return
    }

    setSelectedDate(start)
  }, [selectedDate, start])

  return (
    <>
      <PageScrollEffect />

      <PageRoute id="reservation.book-by-matching-therapists">
        <PageLayout maxWidth="1260px" pb={32} pt={0} px={24}>
          <Head translationId="reservation.booking" />

          <MaxWidth640px pt={32}>
            <Flex pb={24}>
              <Text fontWeight="600" kind="h2">
                <Translation id="reservation.calendar.title" />
              </Text>
            </Flex>

            <Flex pb={24}>
              <TranslationMarkdown colorName="black" id="reservation.availabilities.calendar.body" kind="paragraph" />
            </Flex>

            <Form grow={1} id="reservationFlow" onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
              {initialLoading ? (
                <CalendarBookingTimeslotSkeleton />
              ) : (
                <CardBox px={16}>
                  <Calendar
                    disabledBeforeDate={initialStartAt}
                    events={availabilities}
                    initialDate={start}
                    loading={loading}
                    onChange={onChangeDay}
                    onChangeMonth={onChangeMonth}
                    selectedDate={selectedDate ?? start}
                  />

                  <Flex py={16}>
                    <Divider />
                  </Flex>

                  <Flex py={8}>
                    <View ref={scrollElementRef} />

                    <Text colorName="black" fontWeight="600" kind="paragraph" textAlign="center">
                      <Translation id="reservation.availabilities.sessionInfoTitle" />
                    </Text>

                    <Flex pt={4}>
                      <Text colorName="grey-11" kind="paragraph" textAlign="center">
                        <Translation id="reservation.availabilities.sessionInfo" values={{ minutes }} />
                      </Text>
                    </Flex>
                  </Flex>

                  <Flex pt={16}>
                    <TimeSlotList
                      onTimeSlotSelected={onTimeSlotSelected}
                      selectedTimeSlot={selectedTimeSlot}
                      timeSlots={selectedDayAvailabilities}
                    />
                  </Flex>
                </CardBox>
              )}
            </Form>
          </MaxWidth640px>
        </PageLayout>
      </PageRoute>
    </>
  )
})
