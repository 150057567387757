import { useInbox } from '@trycourier/react-inbox'
import { Space } from 'cdk'
import {
  BORDER_RADIUS_CIRCLE,
  BORDER_WIDTH_1,
  COLOR_LIGHTER,
  COLOR_RED_60,
  COLOR_VIOLET_40,
  SPACING_XS,
} from 'design-tokens'
import { Icon } from 'icons'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Badge, Divider, Text } from 'ui'
import { Flex, PositionRelative, Pressable } from 'ui-deprecated'
import { CenteredLoader } from '~/components/CenteredLoader'
import { Popover, PopoverContent, PopoverTrigger } from '~/components/FloatingUI/Popover'
import { usePopoverContext } from '~/components/FloatingUI/Popover/PopoverContext'
import { Translation } from '~/components/Translation'
import { getRoute } from '~/utils/getRoute'
import { Notification } from './Notification'
import { NotificationsEmptyState } from './NotificationsEmptyState'

const BadgeInTitle = styled(Flex).attrs({ align: 'center', justify: 'center' })`
  width: 20px;
  height: 20px;
  border-radius: ${BORDER_RADIUS_CIRCLE};
  background-color: ${COLOR_RED_60};
  color: ${COLOR_LIGHTER};
`

const IconContainer = styled.div`
  padding: ${SPACING_XS};
  border: ${BORDER_WIDTH_1} solid ${COLOR_VIOLET_40};
  border-radius: ${BORDER_RADIUS_CIRCLE};
  line-height: 0;
`

const CloseButton = () => {
  const { close } = usePopoverContext()

  return (
    <Pressable autoWidth onClick={close}>
      <IconContainer>
        <Icon colorName="purple-08" name="xmark" size={24} />
      </IconContainer>
    </Pressable>
  )
}

const ReadAllLink = () => {
  const { close } = usePopoverContext()

  return (
    <Link onClick={close} to={getRoute('/communications')}>
      <Text colorName="purple-08" fontWeight="600" kind="paragraph" textDecoration="underline">
        <Translation id="notifications.inbox.readAll" />
      </Text>
    </Link>
  )
}

export const InboxIcon = () => {
  const { unreadMessageCount } = useInbox()

  return (
    <PositionRelative>
      <Badge sup={unreadMessageCount}>
        <IconContainer>
          <Icon colorName="purple-08" name="bell" size={24} />
        </IconContainer>
      </Badge>
    </PositionRelative>
  )
}

export const Inbox = () => {
  const { isLoading, messages, unreadMessageCount } = useInbox()

  return (
    <Popover>
      <PopoverTrigger>
        <InboxIcon />
      </PopoverTrigger>
      <PopoverContent style={{ width: '400px', maxWidth: '400px' }}>
        <Flex align="center" direction="row" justify="space-between">
          <Flex align="center" direction="row" pr={16}>
            <Text fontWeight="600" kind="h3">
              <Translation id="notifications.inbox.title" />
            </Text>
            {!!unreadMessageCount && (
              <>
                <Space />
                <BadgeInTitle>
                  <Text fontWeight="600" kind="paragraph">
                    {unreadMessageCount}
                  </Text>
                </BadgeInTitle>
              </>
            )}
          </Flex>
          <CloseButton />
        </Flex>
        {messages && messages.length ? (
          <Flex pt={8}>
            {messages?.slice(0, 5).map((message, index) => (
              <Flex key={message.messageId}>
                {!!index && <Divider />}
                <Notification message={message} preview />
              </Flex>
            ))}
          </Flex>
        ) : isLoading ? (
          <Flex p={32}>
            <CenteredLoader />
          </Flex>
        ) : (
          <Flex py={48}>
            <NotificationsEmptyState filter="all" />
          </Flex>
        )}
        <Divider />
        <Flex align="center" pt={8}>
          <ReadAllLink />
        </Flex>
      </PopoverContent>
    </Popover>
  )
}
