import loadable from '@loadable/component'
import { BORDER_WIDTH_0, SPACING_MD } from 'design-tokens'
import { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Card, CardProps, Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { TranslationId } from '~/i18n/types'

const LazyClinicalDirectorAvatar = loadable(() => import('./ClinicalDirectorAvatar.lazy'), {
  resolveComponent: (components) => components.ClinicalDirectorAvatar,
})

const ClinicalDirectorQuoteCardContainer = styled(Card)`
  padding-top: ${SPACING_MD};
  border: ${BORDER_WIDTH_0};
`

type Props = PropsWithChildren<{
  backgroundColorName?: CardProps['backgroundColorName']
  quote?: TranslationId
}>

export const ClinicalDirectorQuoteCard = ({ backgroundColorName, children, quote }: Props) => (
  <ClinicalDirectorQuoteCardContainer backgroundColorName={backgroundColorName ?? 'grey-02'}>
    <Text as="div" fontStyle="italic" kind="paragraph">
      {quote ? <TranslationMarkdown id={quote} pt={8} /> : children}
    </Text>
    <Flex align="center" direction="row" pt={8}>
      <LazyClinicalDirectorAvatar size="xs" />
      <Flex pl={8}>
        <Text fontWeight="600" kind="paragraph">
          Domenico De Donatis
        </Text>
        <Text kind="caption">
          <Translation id="generic.clinicalDirector" />
        </Text>
      </Flex>
    </Flex>
  </ClinicalDirectorQuoteCardContainer>
)
