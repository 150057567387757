import { CenteredLoader } from '~/components/CenteredLoader'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { useMatchedTherapistsForCurrentUser } from '~/hooks/useMatchedTherapistsForCurrentUser'
import { usePaymentMethods } from '../payments/hooks/usePaymentMethods'
import { Reservation } from './components/Reservation'

export const ReservationPage = () => {
  // NOTE: query may return partial data
  const {
    loading: matchedTherapistsLoading,
    matchedTherapistIds,
    suggestedTherapistsIds,
  } = useMatchedTherapistsForCurrentUser()

  const { therapies, loading: therapiesLoading } = usePatientTherapies()

  const { items, loading: loadingPaymentMethods } = usePaymentMethods()

  if (matchedTherapistsLoading || therapiesLoading || loadingPaymentMethods) {
    return <CenteredLoader />
  }

  return (
    <>
      <Reservation
        matchedTherapistIds={matchedTherapistIds}
        paymentMethodsIds={items.map(({ id }) => id)}
        suggestedTherapistsIds={suggestedTherapistsIds}
        therapies={therapies}
      />
    </>
  )
}
