import { isBefore, startOfDay } from 'date-fns/fp'
import { Day } from '../types'

type Param = {
  disabledBeforeDate: Date
}

export const isCalendarDayDisabled =
  ({ disabledBeforeDate }: Param) =>
  ({ belongsToCurrentMonth, date }: Pick<Day, 'belongsToCurrentMonth' | 'date'>) => {
    if (!belongsToCurrentMonth) {
      return true
    }

    if (isBefore(startOfDay(disabledBeforeDate), date)) {
      return true
    }

    return false
  }
