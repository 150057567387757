import { Icon } from 'icons'
import { memo, useCallback } from 'react'
import { Flex } from 'ui-deprecated'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useModals } from '~/domains/modals'
import { MenuAction } from '~/domains/navigation/TabNavigation/components/MenuAction'
import { useTabNavigationMenu } from '../hooks/useTabNavigationMenu'

export const MenuItemConvention = memo(() => {
  const { open } = useModals()
  const { closeMenu } = useTabNavigationMenu()

  const onActivateConvention = useCallback(() => {
    closeMenu()

    open('activateConvention')
  }, [closeMenu, open])

  return (
    <MenuAction onClick={onActivateConvention}>
      <Flex pr={12}>
        <Icon colorName="grey-08" name="percentage-circle" size={24} />
      </Flex>

      <Flex align="flex-start" grow={1} shrink={1}>
        <TranslationMarkdown id="menu.activateConvention" kind="paragraph" textAlign="left" />
      </Flex>
    </MenuAction>
  )
})

MenuItemConvention.displayName = 'MenuItemConvention'
