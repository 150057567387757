import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Divider, Text } from 'ui'
import { Flex, Pressable } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { useModals } from '~/domains/modals'
import { isBeforeCancelationPolicyLimit } from '~/utils/dates/isBeforeCancelationPolicyLimit'
import { getRoute } from '~/utils/getRoute'
import { useTherapySession } from '../../../hooks/useTherapySession'
import { DeleteTherapySessionModal } from './DeleteTherapySession'
import { EditTherapySessionModal } from './EditTherapySessionModal'

export const EditAndDeleteAppointmentButtons = () => {
  const history = useHistory()
  const { open } = useModals()
  const { endAt, id, recurrency, startAt, therapistId } = useTherapySession()

  const handleDeleteAppointment = useCallback(() => {
    open(`deleteTherapySession:${id}`)
  }, [id, open])

  const handleEditAppointment = useCallback(() => {
    if (!startAt) {
      return
    }

    if (isBeforeCancelationPolicyLimit(startAt)) {
      history.push(getRoute(`/therapy-session/${id}/edit`))

      return
    }

    open(`editTherapySession:${id}`)
  }, [history, id, open, startAt])

  return (
    <Flex direction="row">
      <Flex>
        <Pressable data-test-id="patient-therapy-session-card-edit-appointment" onClick={handleEditAppointment}>
          <Text colorName="purple-08" fontWeight="600" kind="paragraph" textDecoration="underline">
            <Translation id="patientAgenda.therapySessionCard.editAppointment.cta" />
          </Text>
        </Pressable>
      </Flex>
      <Flex pl={16}>
        <Divider direction="vertical" />
      </Flex>
      <Flex pl={16}>
        <Pressable data-test-id="patient-therapy-session-card-delete-appointment" onClick={handleDeleteAppointment}>
          <Text colorName="purple-08" fontWeight="600" kind="paragraph" textDecoration="underline">
            <Translation id="actions.cancelTherapySession" />
          </Text>
        </Pressable>
        {!!endAt && !!startAt && (
          <DeleteTherapySessionModal
            endAt={endAt}
            id={id}
            recurrency={recurrency || false}
            startAt={startAt}
            therapistId={therapistId ?? null}
          />
        )}
        <EditTherapySessionModal id={id} therapistId={therapistId ?? null} />
      </Flex>
    </Flex>
  )
}
