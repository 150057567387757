import { Hub, HubCapsule } from '@aws-amplify/core'
import { noop } from 'functions'
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useReactNativePostMessage } from '~/domains/appNative/hooks/useReactNativePostMessage'
import { useCurrentUserNullable } from '~/hooks/useCurrentUser'
import { getEnv } from '~/utils/getEnv'
import { Route, getRoute } from '~/utils/getRoute'
import { useAuthConfigureEffect } from '../hooks/useAuthConfigureEffect'
import { useAuthMethods } from '../hooks/useAuthMethods'
import { AccessTokenPayload } from '../types/accessTokenPayload'
import { CognitoUser } from '../types/cognitoUser'
import { IdTokenPayload } from '../types/idTokenPayload'
import { toAccessTokenPayload } from '../utils/toAccessTokenPayload'
import { toIdTokenJwt } from '../utils/toIdTokenJwt'
import { toIdTokenPayload } from '../utils/toIdTokenPayload'

type Context = {
  accessTokenPayload: AccessTokenPayload | null
  idTokenJwt: string | null
  idTokenPayload: IdTokenPayload | null
  isAuthenticated: boolean
  isAuthenticating: boolean
}

const AuthStateContext = createContext<Context | null>(null)

type LocationState = {
  referrer?: Route
}

type Props = {
  children: ReactNode
}

export const AuthStateProvider = ({ children }: Props) => {
  const { onLoginCompleted, onLogoutCompleted, id: currentUserId } = useCurrentUserNullable()
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [configured, setConfigured] = useState(false)
  const [user, setUser] = useState<CognitoUser | null>(null)
  const { currentAuthenticatedUser } = useAuthMethods()
  const history = useHistory()
  const { state } = useLocation<LocationState | null>()
  const { dismissAuthSession } = useReactNativePostMessage()

  useAuthConfigureEffect()

  const handleConfigurationCompleted = useCallback(() => {
    setIsAuthenticating(false)

    dismissAuthSession()
  }, [dismissAuthSession])

  const redirectToGesto = useCallback((user: CognitoUser) => {
    try {
      const cognitoGroups = user.signInUserSession.accessToken.payload['cognito:groups']

      const isTherapist = cognitoGroups.includes('therapist')

      const isSaasTherapist = cognitoGroups.includes('saas-therapist')

      const isSaasTherapistExternal = isSaasTherapist && !isTherapist

      if (isSaasTherapistExternal) {
        window.location.replace(getEnv('GESTO_APP_URL'))

        return
      }
    } catch {}
  }, [])

  const hubCallback = useCallback(
    async (hubCapsule: HubCapsule) => {
      const {
        payload: { event, data },
      } = hubCapsule

      switch (event) {
        case 'configured': {
          if (configured) {
            return
          }

          setConfigured(true)

          currentAuthenticatedUser()
            .then((user) => {
              setUser(user)
              onLoginCompleted()

              redirectToGesto(user)
            })
            .catch(noop)
            .finally(handleConfigurationCompleted)

          return
        }
        case 'signIn': {
          const user = data as CognitoUser

          setUser(user)
          handleConfigurationCompleted()
          onLoginCompleted()

          redirectToGesto(user)

          if (!state?.referrer) {
            history.replace(getRoute('/'))

            return
          }

          history.replace(state.referrer)

          return
        }
        case 'signOut': {
          setUser(null)
          setIsAuthenticating(false)
          onLogoutCompleted()

          return
        }
        case 'tokenRefresh': {
          setIsAuthenticating(true)

          currentAuthenticatedUser()
            .then((user) => {
              setUser(user)
            })
            .catch(noop)
            .finally(handleConfigurationCompleted)

          return
        }
        default: {
        }
      }
    },
    [
      configured,
      currentAuthenticatedUser,
      handleConfigurationCompleted,
      history,
      onLoginCompleted,
      onLogoutCompleted,
      redirectToGesto,
      state?.referrer,
    ],
  )

  Hub.listen('auth', hubCallback)

  useEffect(() => () => Hub.remove('auth', hubCallback), [hubCallback])

  const value = useMemo(
    (): Context => ({
      accessTokenPayload: user?.signInUserSession ? toAccessTokenPayload(user) : null,
      idTokenPayload: user?.signInUserSession ? toIdTokenPayload(user) : null,
      idTokenJwt: user?.signInUserSession ? toIdTokenJwt(user) : null,
      isAuthenticated: !isAuthenticating && !!user && !!currentUserId,
      isAuthenticating,
    }),
    [currentUserId, isAuthenticating, user],
  )

  return <AuthStateContext.Provider value={value}>{children}</AuthStateContext.Provider>
}

export const useAuthState = (): Context => {
  const context = useContext(AuthStateContext)

  if (!context) {
    throw new Error('The `useAuthState` should be wrapped with `AuthStateProvider`.')
  }

  return context
}
