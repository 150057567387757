import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { useNativeAppVersion } from '~/domains/appNative/hooks/useNativeAppVersion'

export const MenuNativeAppVersion = () => {
  const appNativeVersion = useNativeAppVersion()

  if (!appNativeVersion) {
    return null
  }

  return (
    <Flex py={16}>
      <Text colorName="grey-11" fontWeight="400" kind="footnote" textAlign="center">
        {appNativeVersion}
      </Text>
    </Flex>
  )
}
