import loadable from '@loadable/component'
import { useCallback } from 'react'
import { matchPath } from 'react-router-dom'
import styled from 'styled-components'
import { Text } from 'ui'
import { Button, Flex, PageLayout, Pressable } from 'ui-deprecated'
import { PageRoute } from '~/components/PageRoute'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useTherapyIdLocationParam } from '~/domains/changeTherapist/hooks/useTherapyIdLocationParam'
import { useCreateChildFormFlow } from '~/domains/formFlow/hooks/useCreateChildFormFlow'
import { useRootHistory, useRootLocation } from '~/hooks/useRootHistory'
import { useToasts } from '~/hooks/useToasts'
import { PageScrollEffect } from '~/routes/PageScrollEffect'
import { getRoute } from '~/utils/getRoute'
import { Head } from '../components/Head'

const Fallback = styled.div`
  width: 182px;
  height: 140px;
`

const LazyNoMatchedTherapistsImage = loadable(() => import('../components/NoMatchedTherapistsImage.lazy'), {
  resolveComponent: (components) => components.NoMatchedTherapistsImage,
  fallback: <Fallback />,
})

export const NoMatchedTherapists = () => {
  const { addToast } = useToasts()
  const [createChildFormFlow] = useCreateChildFormFlow()
  const rootHistory = useRootHistory()

  const { pathname } = useRootLocation()

  const isChangingTherapist = !!matchPath(pathname, { path: getRoute('/change-therapist/:therapyId/booking') })

  const therapyId = useTherapyIdLocationParam()

  const onRepeatFormFlow = useCallback(async () => {
    try {
      const result = await createChildFormFlow()

      if (!result.data?.createChildFormFlowResponse.ok) {
        throw new Error('createChildFormFlow')
      }

      rootHistory.push(getRoute(isChangingTherapist ? `/change-therapist/${therapyId}` : '/start'))
    } catch (error) {
      addToast({
        translationId: 'generic.errorOccurred.title',
        type: 'alert',
      })
    }
  }, [addToast, createChildFormFlow, isChangingTherapist, rootHistory, therapyId])

  const onContinueWithoutReservation = useCallback(() => {
    rootHistory.push(getRoute('/'))
  }, [rootHistory])

  return (
    <>
      <PageScrollEffect />

      <PageRoute id="reservation.no-matched-therapists">
        <Head translationId="reservation.noMatchedTherapists" />

        <PageLayout maxWidth="640px" pt={16}>
          <Flex align="center">
            <LazyNoMatchedTherapistsImage />
          </Flex>
          <Flex mdAlign="center" pt={24}>
            <Text fontWeight="600" kind="h2" textAlign="left">
              <Translation id="reservation.noMatchedTherapists.title" />
            </Text>

            <TranslationMarkdown
              id="reservation.noMatchedTherapists.description"
              kind="paragraph"
              pt={24}
              textAlign="left"
            />

            <Flex align="center" pt={48}>
              <Button kind="primary" onClick={onRepeatFormFlow}>
                <Translation id="reservation.noMatchedTherapists.repeatFormFlow" />
              </Button>

              <Flex pt={32}>
                <Text colorName="black" kind="paragraph">
                  <Translation id="reservation.noMatchedTherapists.moreTime" />
                </Text>
              </Flex>
              <Flex align="center" pt={8}>
                <Pressable data-test-id="proceed-without-booking" onClick={onContinueWithoutReservation}>
                  <Text colorName="purple-08" fontWeight="600" kind="paragraph" textDecoration="underline">
                    <Translation id="reservation.noMatchedTherapists.proceedWithoutBooking" />
                  </Text>
                </Pressable>
              </Flex>
            </Flex>
          </Flex>
        </PageLayout>
      </PageRoute>
    </>
  )
}
