import { COLOR_GREY_60, COLOR_RED_80, COLOR_VIOLET_30, COLOR_VIOLET_50 } from 'design-tokens'
import { TextAreaStyleProps } from '../types'

export const getBorderColorFromProps =
  (focused: boolean) =>
  ({ disabled, invalid }: Omit<TextAreaStyleProps, 'minHeight'>) => {
    if (disabled) {
      return COLOR_GREY_60
    }

    if (invalid) {
      return COLOR_RED_80
    }

    if (focused) {
      return COLOR_VIOLET_50
    }

    return COLOR_VIOLET_30
  }
