import { COLOR_VIOLET_40, COLOR_VIOLET_50 } from 'design-tokens'
import ContentLoader from 'react-content-loader'
import { CardBox } from 'ui-deprecated'

const props = {
  backgroundColor: COLOR_VIOLET_40,
  foregroundColor: COLOR_VIOLET_50,
  speed: 1.5,
  viewBox: '0 0 400 10',
}

export const ServiceInfoSkeleton = () => (
  <CardBox>
    <ContentLoader {...props}>
      <rect height="6" rx="3" ry="3" width="380" x="0" y="2" />
    </ContentLoader>
  </CardBox>
)
