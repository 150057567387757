import { ReactNode } from 'react'
import { Popover, PopoverContent, PopoverTrigger } from '../FloatingUI/Popover'
import { DropdownMenuTrigger } from './Trigger'

type Props = {
  children: ReactNode
}

export const DropdownMenu = ({ children }: Props) => (
  <Popover placement="left">
    <PopoverTrigger>
      <DropdownMenuTrigger />
    </PopoverTrigger>
    <PopoverContent>{children}</PopoverContent>
  </Popover>
)
