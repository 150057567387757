import { colors } from '@serenis-health/tokens'
import { range } from 'arrays'
import ContentLoader from 'react-content-loader'
import { CardBox } from 'ui-deprecated'

const props = {
  backgroundColor: colors.purple02,
  foregroundColor: colors.purple06,
  speed: 1.5,
  viewBox: '0 0 400 500',
}

const size = 32
const slot = 72

export const CalendarBookingTimeslotSkeleton = () => (
  <CardBox p={8}>
    <ContentLoader {...props}>
      <rect height={size} rx={size} ry={size} width={size} x="12" y="8" />
      <rect height="8" rx="4" width="160" x="120" y="16" />
      <rect height={size} rx={size} ry={size} width={size} x="360" y="8" />

      {range(7, (index) => (
        <rect
          key={`2-${index}`}
          height={size}
          rx={size}
          ry={size}
          width={size}
          x={index * size + index * 26 + 12}
          y="48"
        />
      ))}

      {range(7, (index) => (
        <rect
          key={`3-${index}`}
          height={size}
          rx={size}
          ry={size}
          width={size}
          x={index * size + index * 26 + 12}
          y="88"
        />
      ))}

      {range(7, (index) => (
        <rect
          key={`4-${index}`}
          height={size}
          rx={size}
          ry={size}
          width={size}
          x={index * size + index * 26 + 12}
          y="128"
        />
      ))}

      {range(7, (index) => (
        <rect
          key={`5-${index}`}
          height={size}
          rx={size}
          ry={size}
          width={size}
          x={index * size + index * 26 + 12}
          y="168"
        />
      ))}

      {range(7, (index) => (
        <rect
          key={`6-${index}`}
          height={size}
          rx={size}
          ry={size}
          width={size}
          x={index * size + index * 26 + 12}
          y="208"
        />
      ))}

      {range(7, (index) => (
        <rect
          key={`7-${index}`}
          height={size}
          rx={size}
          ry={size}
          width={size}
          x={index * size + index * 26 + 12}
          y="248"
        />
      ))}

      <rect height="8" rx="4" width="160" x="120" y="308" />

      {range(4, (index) => (
        <rect key={`8-${index}`} height={size} rx="16" width={slot} x={index * slot + index * 32 + 12} y="348" />
      ))}

      {range(4, (index) => (
        <rect key={`9-${index}`} height={size} rx="16" width={slot} x={index * slot + index * 32 + 12} y="388" />
      ))}

      <rect height="40" rx="16" width="160" x="120" y="448" />
    </ContentLoader>
  </CardBox>
)
