import { COLOR_VIOLET_30, COLOR_VIOLET_40 } from 'design-tokens'
import ContentLoader from 'react-content-loader'
import { Flex } from 'ui-deprecated'

const props = {
  backgroundColor: COLOR_VIOLET_30,
  foregroundColor: COLOR_VIOLET_40,
  speed: 0.5,
  width: '100%',
  height: '100%',
  viewBox: '0 0 400 320',
}

const size = 28

export const BookingAvailabilitiesSkeleton = () => (
  <Flex mdDirection="row" pt={32}>
    <ContentLoader {...props} animate>
      <rect height={size} rx="8" ry="8" width="400" x="0" y="0" />

      <rect height={size} rx="8" ry="8" width="124" x="0" y="40" />
      <rect height={size} rx="8" ry="8" width="124" x="138" y="40" />
      <rect height={size} rx="8" ry="8" width="124" x="276" y="40" />

      <rect height={size} rx="8" ry="8" width="124" x="0" y="80" />
      <rect height={size} rx="8" ry="8" width="124" x="138" y="80" />
      <rect height={size} rx="8" ry="8" width="124" x="276" y="80" />

      <rect height={size} rx="8" ry="8" width="124" x="0" y="120" />
      <rect height={size} rx="8" ry="8" width="124" x="138" y="120" />
      <rect height={size} rx="8" ry="8" width="124" x="276" y="120" />

      <rect height={size} rx="8" ry="8" width="124" x="0" y="160" />
      <rect height={size} rx="8" ry="8" width="124" x="138" y="160" />
      <rect height={size} rx="8" ry="8" width="124" x="276" y="160" />

      <rect height={size} rx="8" ry="8" width="124" x="0" y="200" />
      <rect height={size} rx="8" ry="8" width="124" x="138" y="200" />
      <rect height={size} rx="8" ry="8" width="124" x="276" y="200" />

      <rect height={size} rx="8" ry="8" width="124" x="0" y="240" />
      <rect height={size} rx="8" ry="8" width="124" x="138" y="240" />
      <rect height={size} rx="8" ry="8" width="124" x="276" y="240" />

      <rect height={size} rx="8" ry="8" width="124" x="0" y="280" />
      <rect height={size} rx="8" ry="8" width="124" x="138" y="280" />
      <rect height={size} rx="8" ry="8" width="124" x="276" y="280" />
    </ContentLoader>
  </Flex>
)
