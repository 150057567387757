import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'ui'
import { Flex, Pressable } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { getRoute } from '~/utils/getRoute'

export const NextTherapySessionsModuleTitle = () => {
  const history = useHistory()

  const onSeeAll = useCallback(() => {
    history.push(getRoute('/therapies'))
  }, [history])

  return (
    <Flex align="center" direction="row" justify="space-between" pt={16}>
      <Text fontWeight="600" kind="h2">
        <Translation id="home.patient.nextTherapySessions.title" />
      </Text>
      <Pressable autoWidth onClick={onSeeAll}>
        <Text colorName="purple-08" fontWeight="600" kind="h3" textDecoration="underline">
          <Translation id="actions.seeAll" />
        </Text>
      </Pressable>
    </Flex>
  )
}
