import { memo } from 'react'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Opacity } from '~/components/Opacity'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { ConventionCode as ConventionCodeType } from '../types/conventionCode'
import { getBackgroundColorName } from '../utils/getBackgroundColorName'
import { getDescriptionColorName } from '../utils/getDescriptionColorName'
import { getDescriptionTranslationId } from '../utils/getDescriptionTranslationId'
import { getDescriptionTranslationValues } from '../utils/getDescriptionTranslationValues'
import { getNameColorName } from '../utils/getNameColorName'
import { getOpacity } from '../utils/getOpacity'
import { toConventionCost } from '../utils/toConventionCost'
import { ConventionCodeDash } from './ConventionCodeDash'
import { ConventionCodeDescription } from './ConventionCodeDescription'
import { ConventionCodeName } from './ConventionCodeName'

export const ConventionCode = memo(
  ({
    cost,
    name,
    size = 'sm',
    status,
    convention: { applicableTo, discount, therapySessionsAvailable },
  }: ConventionCodeType) => {
    const background = getBackgroundColorName(status)

    const conventionCost = toConventionCost(cost, discount ?? 0)

    return (
      <Opacity opacity={getOpacity(status)}>
        <Flex background={background} borderRadius={16} direction="row">
          <ConventionCodeName justify="center" px={16} py={2}>
            <Text
              breakWord
              colorName={getNameColorName(status)}
              fontWeight="600"
              kind={size === 'sm' ? 'footnote' : 'caption'}
              textAlign="center"
            >
              {name}
            </Text>
          </ConventionCodeName>

          <ConventionCodeDash status={status} />

          <ConventionCodeDescription grow={1} justify="center" px={16} py={2} shrink={1}>
            <Flex>
              <TranslationMarkdown
                colorName={getDescriptionColorName(status)}
                id={getDescriptionTranslationId(status, {
                  therapySessionsAvailable,
                })}
                kind={size === 'sm' ? 'footnote' : 'caption'}
                values={getDescriptionTranslationValues(status, {
                  applicableTo,
                  conventionCost,
                  therapySessionsAvailable,
                })}
              />
            </Flex>
          </ConventionCodeDescription>
        </Flex>
      </Opacity>
    )
  },
)
