import { useEffect } from 'react'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { ReservationFormValues } from '~/domains/reservation/types'
import { ReservationFlowBookType, useReservationFlow } from './useReservationFlow'

export const useRegisterSelectedTimeSlot = (bookType: ReservationFlowBookType) => {
  const { setBookType } = useReservationFlow()
  const form = useReactHookFormContext<ReservationFormValues>()

  useEffect(() => {
    setBookType(bookType)

    form.register('selectedTimeSlot', { required: true, valueAsDate: true })
    form.trigger('selectedTimeSlot')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
