import { Flex, MountOn, PositionSticky, Space } from 'cdk'
import { Link as ReactRouterLink, Route, Switch } from 'react-router-dom'
import { Divider, Link, Text } from 'ui'
import { FlowAppBar } from '~/components/Flow/FlowAppBar'
import { Translation } from '~/components/Translation'
import { useAuthState } from '~/domains/auth/components/AuthStateProvider'
import { useNavigation } from '~/domains/navigation/hooks/useNavigation'
import { LanguageSwitcherPopover } from '~/i18n/components/LanguageSwitcherPopover'
import { getRoute } from '~/utils/getRoute'
import { AppBarContent } from './components/AppBarContent'
import { AppBarLogoutButton } from './components/AppBarLogoutButton'

const GuestItem = () => (
  <Text kind="caption">
    <MountOn mediaQuery="gt-sm">
      <Translation id="generic.doYouAnAccountAlready" />
    </MountOn>
    <Space />
    <Link as={ReactRouterLink} to="/login">
      <Text fontWeight="600" kind="caption" textDecoration="underline">
        <Translation id="actions.signin" />
      </Text>
    </Link>
  </Text>
)

const LanguageSwitcherItem = () => (
  <Flex $direction="row" $pr={8}>
    <Flex $py={2}>
      <LanguageSwitcherPopover />
    </Flex>
    <Flex $pl={8}>
      <Divider colorName="neutral-50" direction="vertical" />
    </Flex>
  </Flex>
)

export const AppBar = () => {
  const { isAuthenticated } = useAuthState()
  const { isVisibleAppBar } = useNavigation()

  if (!isVisibleAppBar) {
    return null
  }

  return (
    <PositionSticky $left={0} $right={0} $top={0} $zIndex={10}>
      <Switch>
        <Route path={[getRoute('/start'), getRoute('/signup')]}>
          <FlowAppBar>
            <LanguageSwitcherItem />
            {!isAuthenticated && <GuestItem />}
            {isAuthenticated && <AppBarLogoutButton />}
          </FlowAppBar>
        </Route>

        <Route path={getRoute('/booking')}>
          <FlowAppBar>
            <LanguageSwitcherItem />
            {isAuthenticated && <AppBarLogoutButton />}
          </FlowAppBar>
        </Route>

        <Route>
          <AppBarContent />
        </Route>
      </Switch>
    </PositionSticky>
  )
}
