import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { ReactHookFormCheckboxField } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'

export const FieldStsEnabled = () => {
  const { labels } = useFormTranslations()

  return (
    <Flex>
      <ReactHookFormCheckboxField name="stsEnabled" validateOnSubmit={false}>
        <Text as="label" fontWeight="600" htmlFor="stsEnabled" kind="paragraph">
          {labels.stsEnabled}
        </Text>
        <Flex pt={8}>
          <Text as="label" fontWeight="400" htmlFor="stsEnabled" kind="paragraph">
            <Translation id="settings.payments.billing.invoice.details.description" />
          </Text>
        </Flex>
      </ReactHookFormCheckboxField>
    </Flex>
  )
}
