import { range } from 'arrays'
import { setHours, startOfMonth, subDays } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { Day } from '../types'
import { getFirstDateOfWeekOfMonth } from './getFirstDateOfWeekOfMonth'

export const getPreviousMonthDays = (date: Date): Pick<Day, 'belongsToCurrentMonth' | 'date'>[] =>
  pipe(
    date,
    getFirstDateOfWeekOfMonth,
    (number) =>
      range(number, (index) => ({
        belongsToCurrentMonth: false,
        date: pipe(date, startOfMonth, subDays(index + 1), setHours(12)),
      })),
    (days) => days.reverse(),
  )
