import loadable from '@loadable/component'
import { useMemo } from 'react'
import { Text } from 'ui'
import { Flex, PageLayout } from 'ui-deprecated'
import { BundleBanner } from '~/components/BundleBanner'
import { BundleIntroModal } from '~/components/BundleBanner/BundleIntroModal'
import { JournalingTutorialModal, JournalingTutorialSummary } from '~/components/JournalingTutorial'
import { MobileHookBanner } from '~/components/MobileHookBanner'
import { SentencesTutorialIntroductionModal } from '~/components/SentencesTutorial/Modal'
import { ServiceInfo } from '~/components/ServiceInfo'
import { TherapyBanner } from '~/components/TherapyBanner'
import { TherapySessionCardPatient } from '~/components/TherapySessionCardPatient'
import { Translation } from '~/components/Translation'
import { PushNotificationRequest } from '~/domains/appNative/components/PushNotificationRequest'
import { useIsGenericBundleModuleVisible } from '~/domains/bundlePurchaseFlow/hooks/useIsBundleVisible'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { ShareReferralCode } from '~/domains/referral/components/ShareReferralCode'
import { useActivateReferralEffect } from '~/domains/referral/hooks/useActivateReferralEffect'
import { AskForReview } from '~/domains/reviews'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { usePatientAgenda } from '~/hooks/usePatientAgenda'
import { useReferredUserIds } from '~/hooks/useReferredUserIds'
import { useLanguage } from '~/i18n/hooks/useLanguage'
import { JournalingCard } from '~/routes/journaling/components/JournalingCard'
import { WritingIsGoodCardSmall } from '~/routes/journaling/components/WritingIsGoodCardSmall'
import { useIsNativeApp } from '../appNative/hooks/useIsNativeApp'
import { CreatePartnerSuggestionBanner } from '../onboardingCouples/components/CreatePartnerSuggestionBanner'
import { CreateLegalGuardiansSuggestionBanner } from '../onboardingGuardian/components/CreateLegalGuardiansSuggestionBanner'
import { useResetSuggestedTherapistCount } from '../reservation/hooks/useResetSuggestedTherapistCount'
import { useSentenceOfTheDay } from '../sentenceOfTheDay/useSentenceOfTheDay'
import { AgendaPollingEffect } from './AgendaPollingEffect'
import { AgendaPatientEmptyState } from './components/AgendaPatientEmptyState'
import { LiveTherapySessionSuggestion } from './components/LiveTherapySessionSuggestion'
import { MemberGetMemberSuggestion } from './components/MemberGetMemberSuggestion'
import { NextTherapySessionsModuleTitle } from './components/NextTherapySessionsModuleTitle'
import { PaymentMethodWarningSuggestion } from './components/PaymentMethodWarningSuggestion'
import { StartTherapyPath } from './components/StartTherapyPath'
import { StickerModule } from './components/StickerModule'
import { WelcomeHeader } from './components/WelcomeHeader'

const LazySentenceOfTheDay = loadable(() => import('../sentenceOfTheDay'), {
  resolveComponent: (components) => components.SentenceOfTheDay,
})

const leftMdBasis: string = '50%'
const rightMdBasis: string = '45%'

export const PatientView = () => {
  const { firstName, referralCode } = useCurrentUser()
  const { agenda, error, loading, startPolling, stopPolling } = usePatientAgenda()
  const { therapies } = usePatientTherapies()
  const { remainingMemberGetMemberRewards } = useReferredUserIds()
  const { language } = useLanguage()
  const isNativeApp = useIsNativeApp()

  useResetSuggestedTherapistCount()

  useActivateReferralEffect()

  const isPsychiatryOnlyTherapy = useMemo(
    () => therapies.every((therapy) => therapy.therapyPath.type === 'MYSELF_PSYCHIATRY'),
    [therapies],
  )

  const isUnderageTherapy = therapies.find((therapy) => therapy.therapyPath.type === 'UNDERAGE_PSYCHOTHERAPY')

  const { text: sentenceOfTheDay, loading: sentenceOfTheDayLoading } = useSentenceOfTheDay()

  const nextTherapySession = !!agenda?.nextTherapySessions?.length ? agenda?.nextTherapySessions[0] : null

  const isBundleVisible = useIsGenericBundleModuleVisible()

  const showPaymentMethodWarning = useMemo(() => {
    if (!nextTherapySession) {
      return false
    }

    return [
      'NO_PAYMENT_METHOD',
      'PAYMENT_FAILED_FIRST_ATTEMPT',
      'PAYMENT_FAILED_FIRST_ATTEMPT_NO_PAYMENT_METHOD',
    ].includes(nextTherapySession.status)
  }, [nextTherapySession])

  const showLiveTherapySessionWarning = useMemo(() => {
    if (!nextTherapySession) {
      return false
    }

    return nextTherapySession.status === 'ONGOING'
  }, [nextTherapySession])

  return (
    <>
      <PageLayout background="white" maxWidth="1260px">
        {!loading && <PushNotificationRequest />}
        {!loading && <AskForReview />}

        <AgendaPollingEffect startPolling={startPolling} stopPolling={stopPolling} />

        <CreatePartnerSuggestionBanner />

        <CreateLegalGuardiansSuggestionBanner />

        {!loading && !!nextTherapySession && showPaymentMethodWarning && (
          <Flex pb={16}>
            <PaymentMethodWarningSuggestion
              id={nextTherapySession.id}
              startAt={nextTherapySession.startAt}
              status={nextTherapySession.status}
            />
          </Flex>
        )}

        {!loading && !!nextTherapySession && showLiveTherapySessionWarning && (
          <Flex pb={16}>
            <LiveTherapySessionSuggestion provider={nextTherapySession.provider} url={nextTherapySession.url} />
          </Flex>
        )}

        <WelcomeHeader firstName={firstName} />

        {!!remainingMemberGetMemberRewards && !isPsychiatryOnlyTherapy && <MemberGetMemberSuggestion />}

        {!therapies.length && (
          <Flex pt={16}>
            <TherapyBanner />
          </Flex>
        )}

        {!!therapies.length && <NextTherapySessionsModuleTitle />}

        <Flex pt={16}>
          {error && (
            <Text colorName="coral-10" fontWeight="600" kind="caption">
              <Translation id="generic.errorOccurred.title" />
            </Text>
          )}
          {!therapies.length && (
            <Flex pb={8}>
              <StartTherapyPath />
            </Flex>
          )}
          {!loading && !!therapies.length && !agenda?.nextTherapySessions?.length && (
            <>
              <Flex pb={8}>
                <AgendaPatientEmptyState />
              </Flex>
              {isBundleVisible && (
                <Flex pt={8}>
                  <BundleBanner />
                </Flex>
              )}
            </>
          )}

          {!loading && !!agenda?.nextTherapySessions?.length && (
            <>
              <Flex key={agenda.nextTherapySessions[0].id} pb={8}>
                <TherapySessionCardPatient {...agenda.nextTherapySessions[0]} isVeryNextTherapySession={true} />
              </Flex>

              {isBundleVisible && (
                <Flex pb={8} pt={8}>
                  <BundleBanner />
                </Flex>
              )}

              {agenda.nextTherapySessions.slice(1, 3).map((ts) => (
                <Flex key={ts.id} pb={8}>
                  <TherapySessionCardPatient {...ts} isVeryNextTherapySession={false} />
                </Flex>
              ))}
            </>
          )}
        </Flex>

        {!!therapies.length && <BundleIntroModal />}

        {!sentenceOfTheDayLoading && (
          <Flex pt={24}>
            <Text fontWeight="600" kind="h2">
              <Translation id="home.patient.journaling.title" />
            </Text>

            <Flex justify="space-between" mdDirection="row" pt={16}>
              <Flex mdBasis={leftMdBasis}>
                <JournalingCard />
              </Flex>

              <Flex mdHide pt={16}>
                <StickerModule />
              </Flex>

              <Flex mdBasis={rightMdBasis} mdPt={0} pt={16}>
                {!!sentenceOfTheDay && language === 'it' ? (
                  <>
                    <SentencesTutorialIntroductionModal />
                    <LazySentenceOfTheDay kind="paragraph" p={16} showSendButton={isNativeApp} />
                  </>
                ) : (
                  <>
                    {referralCode && !isPsychiatryOnlyTherapy ? <ShareReferralCode /> : <JournalingTutorialSummary />}
                    <Flex pt={16}>
                      {!!referralCode && !isPsychiatryOnlyTherapy ? (
                        <JournalingTutorialSummary />
                      ) : (
                        <WritingIsGoodCardSmall />
                      )}
                    </Flex>

                    <JournalingTutorialModal />
                  </>
                )}
              </Flex>
            </Flex>
          </Flex>
        )}

        <Flex hide mdHide={false} pt={16}>
          <StickerModule />
        </Flex>

        {referralCode && !isPsychiatryOnlyTherapy && (
          <Flex pt={32}>
            <Text fontWeight="600" kind="h3">
              <Translation id="referralCode.share.title" />
            </Text>
            <Flex pt={16}>
              <ShareReferralCode />
            </Flex>
          </Flex>
        )}

        <ServiceInfo />

        {!!therapies.length && !isUnderageTherapy && (
          <Flex pt={32}>
            <StartTherapyPath />
          </Flex>
        )}
      </PageLayout>

      <MobileHookBanner />
    </>
  )
}
