import { memo, useCallback, useState } from 'react'
import { matchPath } from 'react-router-dom'
import { Suggestion, Text } from 'ui'
import { Button, Flex, Pressable } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { useModals } from '~/domains/modals'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { ReservationFormValues } from '~/domains/reservation/types'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useRootLocation } from '~/hooks/useRootHistory'
import { useScrollRestore } from '~/hooks/useScrollRestore'
import { useTherapySessionMeetingDuration } from '~/hooks/useTherapySessionMeetingDuration'
import { TranslationId } from '~/i18n/types'
import { getRoute } from '~/utils/getRoute'
import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { setLocalStorage } from '~/utils/localStorage/setLocalStorage'
import { useReservationFlow } from '../../hooks/useReservationFlow'
import { useSelectedTimeInterval } from '../../hooks/useSelectedTimeInterval'
import { useTherapyPathById } from '../../hooks/useTherapyPathById'
import { NoMoreTherapistsModal } from '../NoMoreTherapistsModal'
import { ChangeSuggestedTherapistsCard } from './ChangeSuggestedTherapistsCard'
import { ChangeTherapistInfoModal } from './ChangeTherapistInfoModal'
import { MultipleSuggestionAvailabilities } from './MultipleSuggestionAvailabilities'
import { SingleSuggestionAvailabilities } from './SingleSuggestionAvailabilities'
import { TherapistCard } from './SuggestedTherapistCard'

export const BookBySuggestedTherapistsView = memo(() => {
  const form = useReactHookFormContext<ReservationFormValues>()

  const { addTherapist, suggestedTherapistsProfiles, selectedTherapist } = useReservationFlow()

  const { emailVerified } = useCurrentUser()

  const { scrollTo } = useScrollRestore()

  const { therapyPath } = useTherapyPathById()

  const { minutes } = useTherapySessionMeetingDuration({
    duration: 1,
    firstTherapySession: true,
    therapyPathType: therapyPath.type,
  })

  const { open } = useModals()

  const { pathname } = useRootLocation()

  const updateSelectedTimeSlotFormState = useCallback(
    (selectedTimeSlot: Date | null, therapistId: string | null) => {
      form.setValue('selectedTimeSlot', selectedTimeSlot)
      form.setValue('therapistId', therapistId)
      form.trigger('selectedTimeSlot')
    },
    [form],
  )

  const onChangeTherapist = useCallback(() => {
    updateSelectedTimeSlotFormState(null, null)

    const hasNewTherapist = addTherapist()

    if (hasNewTherapist) {
      scrollTo()
      return
    }

    open('noMoreTherapists')
    return
  }, [addTherapist, open, scrollTo, updateSelectedTimeSlotFormState])

  const onRequestChangeTherapist = () => {
    if (!getLocalStorage('booking-change-therapist-info-shown')) {
      open('changeTherapistInfo')
      setLocalStorage('booking-change-therapist-info-shown', 'true')

      return
    }

    onChangeTherapist()
  }

  const onTimeSlotSelected = useCallback(
    (timeSlot: Date, therapistId: string) => {
      updateSelectedTimeSlotFormState(timeSlot, therapistId)
    },
    [updateSelectedTimeSlotFormState],
  )

  const numberOfSuggestions = suggestedTherapistsProfiles.length

  const isChangeTherapistBooking = !!matchPath(pathname, {
    path: getRoute('/change-therapist/:therapyId/booking'),
  })

  const selectedTherapistIsPsychiatrist = selectedTherapist?.therapist.professionTypes.some(
    ({ professionType: { value } }) => value === 'PSYCHIATRIST',
  )

  const changeProfessionalTranslationId: TranslationId =
    therapyPath.type === 'PATH_COACHING'
      ? 'suggestedTherapist.changeCoach.cta'
      : 'suggestedTherapist.changeTherapist.cta'

  const bookFirstTranslationId: TranslationId =
    therapyPath.type === 'PATH_COACHING' ? 'reservation.bookFreeSession' : 'reservation.bookFreeTherapySession'

  const selectedTimeInterval = useSelectedTimeInterval()
  const [filterByTimeInterval, setFilterByTimeInterval] = useState(true)

  const toggleFilterByTimeInterval = useCallback(() => {
    setFilterByTimeInterval((value) => !value)

    scrollTo()
  }, [setFilterByTimeInterval, scrollTo])

  return (
    <>
      {!selectedTherapistIsPsychiatrist && !!emailVerified && (
        <Flex pb={16}>
          <Pressable onClick={onRequestChangeTherapist}>
            <Suggestion kind="info">
              <Flex direction="row">
                <Text colorName="black" kind="paragraph">
                  <Translation id="suggestedTherapist.changeTherapist.text" />
                </Text>
                <Flex pl={4}>
                  <Text colorName="purple-08" fontWeight="600" kind="paragraph" textDecoration="underline">
                    <Translation id={changeProfessionalTranslationId} />
                  </Text>
                </Flex>
              </Flex>
            </Suggestion>
          </Pressable>
        </Flex>
      )}

      <TherapistCard />

      <Flex pt={32}>
        <Text fontWeight="600" kind="h3">
          <Translation id={selectedTherapistIsPsychiatrist ? 'reservation.bookPaidVisit' : bookFirstTranslationId} />
        </Text>
      </Flex>
      <Flex pt={8}>
        <Text kind="paragraph">
          <Translation id="reservation.bookFreeTherapySession.description" values={{ minutes }} />
        </Text>
      </Flex>

      {numberOfSuggestions === 1 && (
        <SingleSuggestionAvailabilities
          filterByTimeInterval={filterByTimeInterval}
          onTimeSlotSelected={onTimeSlotSelected}
        />
      )}

      {numberOfSuggestions > 1 && (
        <MultipleSuggestionAvailabilities
          filterByTimeInterval={filterByTimeInterval}
          onTimeSlotSelected={onTimeSlotSelected}
          therapists={suggestedTherapistsProfiles}
        />
      )}

      {!!selectedTimeInterval?.length && (
        <Flex pt={32}>
          <Button kind="primary" onClick={toggleFilterByTimeInterval} size="small">
            <Translation id={filterByTimeInterval ? 'reservation.otherSlots' : 'reservation.onlyPreferredTimeSlots'} />
          </Button>
        </Flex>
      )}

      {!isChangeTherapistBooking && (
        <Flex pt={32}>
          <ChangeSuggestedTherapistsCard />
        </Flex>
      )}

      <NoMoreTherapistsModal />

      <ChangeTherapistInfoModal onConfirm={onChangeTherapist} />
    </>
  )
})
