import { ColorName } from 'design-tokens'
import { ConventionCode } from '../types/conventionCode'

export const getNameColorName = (status: ConventionCode['status']): ColorName => {
  switch (status) {
    case 'ACTIVE':
      return 'white'
    case 'INACTIVE':
    case 'EXPIRED':
    case 'COMPLETED':
    default:
      return 'purple-08'
  }
}
