import { Icon } from 'icons'
import { Text } from 'ui'
import { Flex, PageLayout } from 'ui-deprecated'
import { PageRoute } from '~/components/PageRoute'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { PageScrollEffect } from '~/routes/PageScrollEffect'
import { Head } from '../components/Head'

export const SkipBookingPsychiatry = () => (
  <>
    <PageScrollEffect />

    <Head translationId="reservation.skipBookingPsychiatry" />

    <PageRoute id="reservation.skipBookingPsychiatry">
      <PageLayout maxWidth="640px" pb={32} px={24}>
        <Flex pb={8}>
          <Text fontWeight="600" kind="h2">
            <Translation id="reservation.skipBooking.psychiatry.title" />
          </Text>
        </Flex>

        <Flex direction="row" pt={16}>
          <Flex pr={16}>
            <Icon colorName="grey-08" name="edit-pencil" size={32} />
          </Flex>
          <Flex grow={1} shrink={1}>
            <TranslationMarkdown
              colorName="black"
              fontWeight="400"
              id="reservation.skipBooking.psychiatry.description.1"
              kind="h3"
            />
          </Flex>
        </Flex>

        <Flex direction="row" pt={16}>
          <Flex pr={16}>
            <Icon colorName="grey-08" name="calendar" size={32} />
          </Flex>
          <Flex grow={1} shrink={1}>
            <TranslationMarkdown
              colorName="black"
              fontWeight="400"
              id="reservation.skipBooking.psychiatry.description.2"
              kind="h3"
            />
          </Flex>
        </Flex>

        <Flex direction="row" pt={16}>
          <Flex pr={16}>
            <Icon colorName="grey-08" name="people-tag" size={32} />
          </Flex>
          <Flex grow={1} shrink={1}>
            <TranslationMarkdown
              colorName="black"
              fontWeight="400"
              id="reservation.skipBooking.psychiatry.description.3"
              kind="h3"
            />
          </Flex>
        </Flex>
      </PageLayout>
    </PageRoute>
  </>
)
