import { Icon } from 'icons'
import { Flex } from 'ui-deprecated'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { NotificationFilter } from '~/routes/communications/components/Filters'

type NotificationsEmptyStateProps = {
  filter: NotificationFilter
}

export const NotificationsEmptyState = ({ filter }: NotificationsEmptyStateProps) => (
  <Flex align="center" grow={1} justify="center" px={16}>
    <Icon colorName="grey-08" name="bell" size={48} />

    <Flex pt={8}>
      <TranslationMarkdown
        colorName="grey-11"
        fontWeight="600"
        id={filter === 'toRead' ? 'notifications.inbox.toRead.emptyState' : 'notifications.inbox.emptyState'}
        kind="paragraph"
        textAlign="center"
      />
    </Flex>
  </Flex>
)
