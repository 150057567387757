import {
  addMinutes,
  addSeconds,
  differenceInSeconds,
  eachMinuteOfIntervalWithOptions,
  secondsToMinutes,
} from 'date-fns/fp'
import { isIntervalWithinInterval } from 'dates'
import { filter, flatten, map, some, sort, uniq } from 'fp-ts/Array'
import * as D from 'fp-ts/Date'
import { pipe } from 'fp-ts/function'
import { getTherapySessionAvailabilityDurationInSeconds } from '~/utils/getTherapySessionAvailabilityDurationInSeconds'

type Availability = {
  endAt: Date
  startAt: Date
}

const eachMinuteOfInterval = eachMinuteOfIntervalWithOptions({
  step: pipe(getTherapySessionAvailabilityDurationInSeconds(), secondsToMinutes),
})

export const getCalendarAvailableTimeSlotFromAvailabilities = (availabilities: Availability[]) =>
  pipe(
    availabilities,
    map(({ endAt: end, startAt: start }) => {
      const slotContainsOnlyOneTherapySession =
        pipe(end, differenceInSeconds(start)) < getTherapySessionAvailabilityDurationInSeconds() * 2

      if (
        slotContainsOnlyOneTherapySession &&
        pipe(end, differenceInSeconds(start)) > getTherapySessionAvailabilityDurationInSeconds()
      ) {
        return [
          ...pipe({ end, start }, eachMinuteOfInterval),
          ...pipe({ end, start: pipe(start, addMinutes(15)) }, eachMinuteOfInterval),
          ...pipe({ end, start: pipe(start, addMinutes(30)) }, eachMinuteOfInterval),
          ...pipe({ end, start: pipe(start, addMinutes(45)) }, eachMinuteOfInterval),
        ]
      }

      return pipe({ end, start }, eachMinuteOfInterval)
    }),
    flatten,
    filter((availabilityStartAt) => {
      const interval = {
        end: pipe(availabilityStartAt, addSeconds(getTherapySessionAvailabilityDurationInSeconds())),
        start: availabilityStartAt,
      }

      return pipe(
        availabilities,
        some(({ endAt: end, startAt: start }) => pipe(interval, isIntervalWithinInterval({ end, start }))),
      )
    }),
    uniq(D.Eq),
    sort(D.Ord),
  )
