import { Icon } from 'icons'
import { Text, Tile } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '../Translation'

export const ServiceInfoActiveConventionCard = () => {
  const startChildren = <Icon colorName="grey-11" name="badge-check" size={32} />

  return (
    <Flex pt={16}>
      <Tile startChildren={startChildren}>
        <Flex grow={1} shrink={1}>
          <Text fontWeight="600" kind="paragraph">
            <Translation id="referralCode.activation.success.title" />
          </Text>
          <Text fontWeight="400" kind="paragraph">
            <Translation id="referralCode.activation.success.body" />
          </Text>
        </Flex>
      </Tile>
    </Flex>
  )
}
