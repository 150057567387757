const baseUrl = 'http://localhost'

export function parsePath(path: string) {
  const { hash, pathname, search } = new URL(path, baseUrl)

  return {
    hash,
    pathname,
    search,
  }
}
