import { gql, useQuery } from '@apollo/client'
import { ConventionCodeByUserIdOrNameQuery, ConventionCodeByUserIdOrNameQueryVariables } from '~/types/graphql'
import { useConventionCodeName } from './useConventionCodeName'

const QUERY = gql`
  query conventionCodeByUserIdOrName($name: String!, $userId: String) {
    conventionCodeByUserIdOrName(name: $name, userId: $userId) {
      id
      name
      status
      convention {
        applicableTo
        id
        multiple
        discount
        therapySessionsAvailable
      }
    }
  }
`

export const useConventionCode = () => {
  const name = useConventionCodeName()

  const { data, error, loading, refetch } = useQuery<
    ConventionCodeByUserIdOrNameQuery,
    ConventionCodeByUserIdOrNameQueryVariables
  >(QUERY, {
    fetchPolicy: 'cache-first',
    variables: { name: name || '', userId: null },
  })

  return {
    conventionCode: data?.conventionCodeByUserIdOrName || null,
    error: !!error,
    loading,
    refetch,
  }
}
