import { FloatingFocusManager, FloatingPortal, useMergeRefs } from '@floating-ui/react'
import { BORDER_WIDTH_0, ELEVATION_SM } from 'design-tokens'
import { HTMLProps, forwardRef } from 'react'
import styled from 'styled-components'
import { Card } from 'ui'
import { usePopoverContext } from './PopoverContext'

// Note: Modals, Popovers, Tooltips, SelectNew must have the same zIndex.
const PopoverContentContainer = styled(Card)`
  z-index: 10;
  width: fit-content;
  max-width: 324px;
  border: ${BORDER_WIDTH_0};
  box-shadow: ${ELEVATION_SM};
`

export const PopoverContent = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(
  ({ children, ...props }, propRef) => {
    const { context, strategy, y, x, getFloatingProps, isMounted, refs, styles } = usePopoverContext()
    const ref = useMergeRefs([refs.setFloating, propRef])

    if (!isMounted) {
      return null
    }

    return (
      <FloatingPortal>
        <FloatingFocusManager context={context}>
          <PopoverContentContainer
            ref={ref}
            {...getFloatingProps({
              ...props,
              style: {
                position: strategy,
                top: y ?? 0,
                left: x ?? 16,
                right: 16,
                ...styles,
                ...props.style,
              },
            })}
          >
            {children}
          </PopoverContentContainer>
        </FloatingFocusManager>
      </FloatingPortal>
    )
  },
)
