import { TherapyTherapyPathType } from '~/types/graphql'
import { TherapyPathKey } from '../agenda/components/StartTherapyPath/components/StartTherapyPathCard'

export const therapyPathTypeToFormFlowPrefilledConceptId: Record<TherapyTherapyPathType, string> = {
  COUPLES_PSYCHOTHERAPY: 'fcp_shb8r10sn17jcg4c',
  MYSELF_PSYCHIATRY: 'fcp_d96b49iwat7eqym9',
  MYSELF_PSYCHOTHERAPY: 'fcp_01nupy985ryhjkfz',
  PATH_ASSERTIVE_COMMUNICATION: 'fcp_pcte7g1kzqtybxj2',
  PATH_COACHING: 'fcp_75n7jtbqpox2d2s9',
  PATH_SEXOLOGY: 'fcp_yi5jfjn5vwespxw3',
  PATH_SLEEP: 'fcp_sc50mrhiak6jhcvs',
  UNDERAGE_PSYCHOTHERAPY: 'fcp_1pplin5t1u43vud6',
}

export const validFirstTherapySessionFree: TherapyPathKey[] = [
  'MYSELF_PSYCHOTHERAPY',
  'COUPLES_PSYCHOTHERAPY',
  'PATH_COACHING',
  'PATH_SEXOLOGY',
]
