import { addWeeks, endOfMonth, secondsToHours, startOfMonth } from 'date-fns/fp'
import { endOfWeek, format, FormatDateEnum, nowInMilliseconds, startOfWeek } from 'dates'
import { pipe } from 'fp-ts/function'
import { noop } from 'functions'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Link, Text } from 'ui'
import { Button, Flex } from 'ui-deprecated'
import { CenteredLoader } from '~/components/CenteredLoader'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { Modal, useModals } from '~/domains/modals'
import { Route } from '~/utils/getRoute'
import { useShowAvailabilitiesInsertionReminder } from '../hooks/useShowAvailabilitiesInsertionReminder'
import { useTherapistAvailabilitiesGivenCount } from '../hooks/useTherapistAvailabilitiesGivenCount'

const blockedRoutes: Route[] = ['/availabilities/calendar', '/availabilities/weekly']

export const AvailabilitiesInsertionReminderModal = () => {
  const [path, setPath] = useState<string | null>(null)
  const { close, dismiss, open } = useModals()
  const { error, loading, data } = useTherapistAvailabilitiesGivenCount()
  const history = useHistory()
  const { pathname } = useLocation()
  const showAvailabilityInsertionReminder = useShowAvailabilitiesInsertionReminder()

  const currentFromWeek = useMemo(
    () => pipe(nowInMilliseconds(), startOfWeek, format(FormatDateEnum.DATE_MONTH_NAME)),
    [],
  )

  const currentToWeek = useMemo(() => pipe(nowInMilliseconds(), endOfWeek, format(FormatDateEnum.DATE_MONTH_NAME)), [])

  const currentFromMonth = useMemo(
    () => pipe(nowInMilliseconds(), startOfMonth, format(FormatDateEnum.DATE_MONTH_NAME)),
    [],
  )

  const currentToMonth = useMemo(
    () => pipe(nowInMilliseconds(), endOfMonth, format(FormatDateEnum.DATE_MONTH_NAME)),
    [],
  )

  const nextFromWeek = useMemo(
    () => pipe(nowInMilliseconds(), addWeeks(1), startOfWeek, format(FormatDateEnum.DATE_MONTH_NAME)),
    [],
  )

  const nextToWeek = useMemo(
    () => pipe(nowInMilliseconds(), addWeeks(1), endOfWeek, format(FormatDateEnum.DATE_MONTH_NAME)),
    [],
  )

  useEffect(() => {
    const unblock = history.block((prompt) => {
      if (
        blockedRoutes.some((blockedRoute) => blockedRoute === pathname) &&
        blockedRoutes.every((blockedRoute) => blockedRoute !== prompt.pathname) &&
        showAvailabilityInsertionReminder
      ) {
        setPath(prompt.pathname)

        open('availabilitiesInsertionReminder')

        return 'true'
      }
    })

    return unblock
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, showAvailabilityInsertionReminder])

  const handleAddAvailabilities = useCallback(async () => {
    setPath(null)

    dismiss('availabilitiesInsertionReminder')
  }, [dismiss, setPath])

  const handleContinueNavigation = useCallback(async () => {
    if (!path) {
      return null
    }

    setPath(null)

    close('availabilitiesInsertionReminder')

    history.block(noop)

    history.push(path)
  }, [close, path, history])

  if (
    error ||
    !data?.findTherapistProfileById?.therapist ||
    !data.findTherapistProfileById?.weeklyAvailabilitiesGivenCount ||
    !data.findTherapistProfileById?.monthlyAvailabilitiesGivenCount
  ) {
    return null
  }

  const {
    therapist: { availableHoursPerWeek },
    monthlyAvailabilitiesGivenCount,
    weeklyAvailabilitiesGivenCount,
  } = data.findTherapistProfileById

  const currentWeeklyRemainingHours =
    availableHoursPerWeek - pipe(weeklyAvailabilitiesGivenCount.current.available, secondsToHours)

  const nextWeeklyRemainingHours =
    availableHoursPerWeek - pipe(weeklyAvailabilitiesGivenCount.next.available, secondsToHours)

  const currentMonthlyRemainingHours =
    availableHoursPerWeek * 4 - pipe(monthlyAvailabilitiesGivenCount.current.available, secondsToHours)

  if (currentWeeklyRemainingHours <= 0 && nextWeeklyRemainingHours <= 0 && currentMonthlyRemainingHours <= 0) {
    return null
  }

  return (
    <Modal id="availabilitiesInsertionReminder">
      {loading && <CenteredLoader />}

      {!loading && (
        <>
          <Flex grow={1}>
            <Text fontWeight="600" kind="h3" textAlign="center">
              <Translation id="availabilities.insertionReminder.modal.title" />
            </Text>

            <Flex pt={32}>
              <Text colorName="grey-11" fontWeight="400" kind="paragraph" textAlign="center">
                <Translation id="availabilities.insertionReminder.modal.insert" />
              </Text>
            </Flex>

            {currentWeeklyRemainingHours > 0 && (
              <TranslationMarkdown
                colorName="grey-11"
                id={
                  currentWeeklyRemainingHours === 1
                    ? 'availabilities.insertionReminder.modal.missingHoursSingular'
                    : 'availabilities.insertionReminder.modal.missingHoursPlural'
                }
                kind="paragraph"
                textAlign="center"
                values={{
                  hours: currentWeeklyRemainingHours,
                  from: currentFromWeek,
                  to: currentToWeek,
                }}
              />
            )}

            {nextWeeklyRemainingHours > 0 && (
              <TranslationMarkdown
                colorName="grey-11"
                id={
                  nextWeeklyRemainingHours === 1
                    ? 'availabilities.insertionReminder.modal.missingHoursSingular'
                    : 'availabilities.insertionReminder.modal.missingHoursPlural'
                }
                kind="paragraph"
                textAlign="center"
                values={{
                  hours: nextWeeklyRemainingHours,
                  from: nextFromWeek,
                  to: nextToWeek,
                }}
              />
            )}

            {currentMonthlyRemainingHours > 0 && (
              <>
                <Flex pt={32}>
                  <Text colorName="grey-11" fontWeight="400" kind="paragraph" textAlign="center">
                    <Translation id="availabilities.insertionReminder.modal.insertOnMonth" />
                  </Text>
                </Flex>
                <TranslationMarkdown
                  colorName="grey-11"
                  id={
                    currentMonthlyRemainingHours === 1
                      ? 'availabilities.insertionReminder.modal.missingHoursSingular'
                      : 'availabilities.insertionReminder.modal.missingHoursPlural'
                  }
                  kind="paragraph"
                  textAlign="center"
                  values={{
                    hours: currentMonthlyRemainingHours,
                    from: currentFromMonth,
                    to: currentToMonth,
                  }}
                />
              </>
            )}
          </Flex>

          <Flex pt={48}>
            <Button kind="primary" onClick={handleAddAvailabilities}>
              <Translation id="availabilities.insertionReminder.modal.cta.addAvailabilities" />
            </Button>
          </Flex>

          <Flex align="center" pt={24}>
            <Link onClick={handleContinueNavigation}>
              <Text colorName="purple-08" kind="caption" textAlign="center" textDecoration="underline">
                <Translation id="availabilities.insertionReminder.modal.cta.continueNavigation" />
              </Text>
            </Link>
          </Flex>
        </>
      )}
    </Modal>
  )
}
