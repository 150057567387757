import loadable from '@loadable/component'
import { Flex, Pressable } from 'cdk'
import { BORDER_WIDTH_0, ELEVATION_XS } from 'design-tokens'
import { Icon } from 'icons'
import { useCallback, useState } from 'react'
import styled from 'styled-components'
import { Divider, Text } from 'ui'
import { CardNoPadding } from '~/components/CardNoPadding'
import { Translation } from '~/components/Translation'

const LazyReviewsButtonImageFallback = styled.div`
  width: 48px;
  height: 48px;
`

const LazyTrustpilotImageFallback = styled.div`
  width: 228px;
  height: 36px;
`

const LazyReviewsButtonImage = loadable(() => import('./ReviewsButtonImage.lazy'), {
  resolveComponent: (components) => components.ReviewsButtonImage,
  fallback: <LazyReviewsButtonImageFallback />,
})

const LazyTrustpilotImage = loadable(() => import('./TrustpilotImage.lazy'), {
  resolveComponent: (components) => components.TrustPilotImage,
  fallback: <LazyTrustpilotImageFallback />,
})

const PaddingZeroCard = styled(CardNoPadding)`
  overflow: hidden;
  border: ${BORDER_WIDTH_0};
  box-shadow: ${ELEVATION_XS};
`

export const Reviews = () => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  return (
    <PaddingZeroCard size="sm">
      <Pressable onClick={handleClick}>
        <Flex $align="center" $direction="row" $p={24}>
          <LazyReviewsButtonImage />
          <Flex $grow={1} $px={8} $shrink={1}>
            <Text fontWeight="600" kind="paragraph" textAlign="left">
              <Translation id="reviews.title" />
            </Text>
          </Flex>
          <Icon colorName="purple-08" name="nav-arrow-down" size={24} />
        </Flex>
      </Pressable>
      {isOpen && (
        <>
          <Divider />
          <Flex $p={24}>
            <Flex $align="center" $direction="row">
              <Flex $pr={16}>
                <Icon colorName="grey-11" name="three-stars" size={24} />
              </Flex>
              <Text kind="caption">
                <Translation id="reviews.content.1" />
              </Text>
            </Flex>
            <Flex $align="center" $direction="row" $pt={8}>
              <Flex $pr={16}>
                <Icon colorName="grey-11" name="report-columns" size={24} />
              </Flex>
              <Text kind="caption">
                <Translation id="reviews.content.2" />
              </Text>
            </Flex>
            <Flex $align="center" $direction="row" $pt={8}>
              <Flex $pr={16}>
                <Icon colorName="grey-11" name="user-badge-check" size={24} />
              </Flex>
              <Text kind="caption">
                <Translation id="reviews.content.3" />
              </Text>
            </Flex>
            <Flex $align="center" $direction="row" $pt={8}>
              <Flex $pr={16}>
                <Icon colorName="grey-11" name="user-love" size={24} />
              </Flex>
              <Text kind="caption">
                <Translation id="reviews.content.4" />
              </Text>
            </Flex>
          </Flex>
          <Flex $align="center" $backgroundColorName="grey-02" $justify="center" $py={12}>
            <LazyTrustpilotImage />
          </Flex>
        </>
      )}
    </PaddingZeroCard>
  )
}
