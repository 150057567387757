import { ConventionCode } from '../types/conventionCode'

export const getOpacity = (status: ConventionCode['status']): number => {
  switch (status) {
    case 'INACTIVE':
    case 'ACTIVE':
      return 1
    case 'EXPIRED':
    case 'COMPLETED':
    default:
      return 0.5
  }
}
