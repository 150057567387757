import { colors } from '@serenis-health/tokens'
import ContentLoader from 'react-content-loader'
import { CardBox } from 'ui-deprecated'

const props = {
  backgroundColor: colors.purple06,
  foregroundColor: colors.purple08,
  speed: 1.5,
  viewBox: '0 0 400 270',
}

export const TherapySessionCardSkeleton = () => (
  <CardBox>
    <ContentLoader {...props}>
      <circle cx="20" cy="20" r="20" />
      <rect height="6" rx="3" ry="3" width="88" x="48" y="8" />
      <rect height="6" rx="3" ry="3" width="52" x="48" y="26" />
      <rect height="6" rx="3" ry="3" width="410" x="0" y="56" />
      <rect height="6" rx="3" ry="3" width="380" x="0" y="72" />
      <rect height="6" rx="3" ry="3" width="178" x="0" y="88" />
      <rect height="6" rx="3" ry="3" width="410" x="0" y="114" />
      <rect height="6" rx="3" ry="3" width="410" x="0" y="130" />
      <rect height="6" rx="3" ry="3" width="410" x="0" y="114" />
      <rect height="6" rx="3" ry="3" width="380" x="0" y="178" />
      <rect height="6" rx="3" ry="3" width="52" x="0" y="210" />
      <rect height="6" rx="3" ry="3" width="88" x="0" y="226" />
      <rect height="6" rx="3" ry="3" width="380" x="0" y="242" />
      <rect height="6" rx="3" ry="3" width="178" x="0" y="258" />
    </ContentLoader>
  </CardBox>
)
