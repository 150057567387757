import { useCallback } from 'react'
import styled from 'styled-components'
import { Text } from 'ui'
import { Flex, Pressable } from 'ui-deprecated'
import { useModals } from '~/domains/modals'
import { CardBoxNoStroke } from '../CardBoxNoStroke'
import { TertiaryButtonIcon } from '../TertiaryButton/TertiaryButtonIcon'
import { Translation } from '../Translation'
import { imageStepIntro, modalId } from './constants'

const Image = styled(Flex)`
  width: 78px;
  height: 78px;
  background: url(${imageStepIntro}) center center no-repeat;
  background-size: cover;
`

export const JournalingTutorialSummary = () => {
  const { open } = useModals()

  const onOpen = useCallback(() => {
    open(modalId)
  }, [open])

  return (
    <CardBoxNoStroke background="purple02" px={16} py={8}>
      <Pressable align="center" direction="row" justify="space-between" onClick={onOpen}>
        <Flex>
          <Image />
        </Flex>
        <Flex align="center" direction="row" grow={1} justify="space-between" pl={8} shrink={1}>
          <Text fontWeight="600" kind="paragraph" textAlign="left">
            <Translation id="journaling.summaryBox.title" />
          </Text>
          <Flex pl={12}>
            <TertiaryButtonIcon colorName="purple-08" iconName="arrow-right" />
          </Flex>
        </Flex>
      </Pressable>
    </CardBoxNoStroke>
  )
}
