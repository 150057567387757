import { addMonths, startOfDay, startOfMonth, subMonths } from 'date-fns/fp'
import { nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import { memo, useCallback, useMemo, useState } from 'react'
import { useTrackEventClick } from '~/domains/analytics/hooks/useTrackEventClick'
import { View } from './View'
import { CalendarProvider } from './hooks/useCalendar'
import { Day } from './types'

type Props = {
  disabledBeforeDate: Date
  events?: Date[]
  initialDate?: Date
  loading: boolean
  onChange: (value: Day) => void
  onChangeMonth: (value: Date) => void
  selectedDate?: Date
}

export const Calendar = memo(
  ({ disabledBeforeDate, initialDate, events = [], loading, onChange, onChangeMonth, selectedDate }: Props) => {
    const startingDate = useMemo(() => initialDate || pipe(nowInMilliseconds(), startOfDay), [initialDate])
    const [date, setDate] = useState(startingDate)
    const [selectedDay, setSelectedDay] = useState(startingDate)
    const trackClick = useTrackEventClick()

    const handleSelect = useCallback(
      (day: Day) => {
        trackClick('reservation.calendar-day', { date: day.date })

        setSelectedDay(day.date)

        onChange(day)
      },
      [onChange, trackClick],
    )

    const handleGoToPreviousMonth = useCallback(() => {
      const selectedDate = subMonths(1)(date)

      setDate(selectedDate)

      onChangeMonth(pipe(selectedDate, startOfMonth))
    }, [date, onChangeMonth, setDate])

    const handleGoToNextMonth = useCallback(() => {
      const selectedDate = addMonths(1)(date)

      setDate(selectedDate)

      onChangeMonth(pipe(selectedDate, startOfMonth))
    }, [date, onChangeMonth, setDate])

    const value = useMemo(
      () => ({ date, disabledBeforeDate, events, loading, selectedDay, selectedDate }),
      [date, disabledBeforeDate, events, loading, selectedDay, selectedDate],
    )

    return (
      <CalendarProvider value={value}>
        <View onNext={handleGoToNextMonth} onPrevious={handleGoToPreviousMonth} onSelect={handleSelect} />
      </CalendarProvider>
    )
  },
)
