import { isEqual } from 'date-fns/fp'
import { format, FormatDateEnum } from 'dates'
import { pipe } from 'fp-ts/function'
import { memo, useCallback } from 'react'
import { Text } from 'ui'
import { Flex, Pressable } from 'ui-deprecated'
import { useTrackEventClick } from '~/domains/analytics/hooks/useTrackEventClick'

type Props = {
  onClick: (timeSlot: Date) => void
  timeSlot: Date
  selectedTimeSlot: Date | null
}

export const TimeSlotItem = memo(({ onClick, selectedTimeSlot, timeSlot }: Props) => {
  const selected = Boolean(selectedTimeSlot && isEqual(timeSlot, selectedTimeSlot))

  const trackClick = useTrackEventClick()

  const handleOnPress = useCallback(() => {
    trackClick('reservation.calendar-hour', { date: timeSlot })

    onClick(timeSlot)
  }, [onClick, timeSlot, trackClick])

  return (
    <Flex basis="33.3%" pb={8} px={8}>
      <Flex
        aria-selected={selected}
        background={selected ? 'purple08' : 'white'}
        backgroundHover={selected ? 'purple08' : 'purple02'}
        borderColor={selected ? 'purple08' : 'purple04'}
        borderRadius={32}
        borderSize={1}
        data-test-id="time-slot-item"
      >
        <Pressable onClick={handleOnPress}>
          <Flex align="center" py={8}>
            <Text colorName={selected ? 'white' : 'purple-08'} fontWeight="600" kind="paragraph" textAlign="center">
              {pipe(timeSlot, format(FormatDateEnum.HOURS_MINUTES))}
            </Text>
          </Flex>
        </Pressable>
      </Flex>
    </Flex>
  )
})
