import { ColorName as OldColorName } from '@serenis-health/tokens'
import { ColorName } from 'design-tokens'
import { Day } from '../types'

export const getBackgroundColorName = (day: Day): OldColorName => {
  if (!day || !day.belongsToCurrentMonth) {
    return 'white'
  }

  if (day.selected) {
    return 'purple08'
  }

  if (day.disabled) {
    return 'white'
  }

  return 'white'
}

export const getBorderColor = (day: Day): OldColorName | undefined => {
  if (day?.selected) {
    return 'purple08'
  }

  if (day?.hasEvent) {
    return 'purple06'
  }

  return 'transparent'
}

export const getColorName = (day: Day): ColorName => {
  if (!day.belongsToCurrentMonth || day.disabled || !day.hasEvent) {
    return 'purple-06'
  }

  if (day.selected) {
    return 'white'
  }

  return 'purple-08'
}
