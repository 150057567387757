import { format, FormatDateEnum } from 'dates'
import { pipe } from 'fp-ts/function'
import { toCurrency } from 'numbers'
import { ConventionCode } from '../types/conventionCode'

type ReturnType =
  | {
      date: string
      conventionCount: number
      conventionCost: string
    }
  | {
      conventionCount: number
      conventionCost: string
    }
  | undefined

type Param = Pick<ConventionCode['convention'], 'applicableTo' | 'therapySessionsAvailable'> & {
  conventionCost: number
}

export const getDescriptionTranslationValues = (
  status: ConventionCode['status'],
  { applicableTo, conventionCost, therapySessionsAvailable }: Param,
): ReturnType => {
  switch (status) {
    case 'ACTIVE':
      return {
        date: pipe(applicableTo, format(FormatDateEnum.DATE_MONTH_NAME_YEAR)),
        conventionCount: therapySessionsAvailable,
        conventionCost: toCurrency(conventionCost),
      }
    case 'INACTIVE':
      return {
        conventionCount: therapySessionsAvailable,
        conventionCost: toCurrency(conventionCost),
      }
    case 'EXPIRED':
    case 'COMPLETED':
    default:
      return undefined
  }
}
