import { Flex, MaxWidth640px } from 'cdk'
import { ReactNode } from 'react'
import { matchPath } from 'react-router-dom'
import styled from 'styled-components'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { useRootLocation } from '~/hooks/useRootHistory'
import { getRoute } from '~/utils/getRoute'

type Props = {
  children: ReactNode
}

const BoxShadowBottom = styled(Flex).attrs({ $backgroundColorName: 'lighter', $p: 16 })`
  box-shadow: 0 -5px 20px 0 rgb(80 57 216 / 10%);
`

export const StepBottomBarButton = ({ children }: Props) => {
  const { pathname } = useRootLocation()

  const matchJournalingPath = !!matchPath(pathname, {
    path: [getRoute('/journaling/logs/:journalingLogId')],
    exact: true,
  })

  const { isVariant1 } = useFeatureFlagsByUserId()

  const isRestylePatientNavbarVariant = isVariant1('ff_restyle_patient_navbar')

  if (isRestylePatientNavbarVariant && matchJournalingPath) {
    return (
      <BoxShadowBottom $mdPb={16} $pb={112}>
        <MaxWidth640px>{children}</MaxWidth640px>
      </BoxShadowBottom>
    )
  }

  return (
    <BoxShadowBottom>
      <MaxWidth640px>{children}</MaxWidth640px>
    </BoxShadowBottom>
  )
}
