import { Flex, MountOn, OverflowAuto, OverflowHidden, Space } from 'cdk'
import { Icon } from 'icons'
import { PropsWithChildren } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import styled from 'styled-components'
import { Divider, Link, Text } from 'ui'
import { AppBarLogo } from '~/components/AppBar/components/AppBarLogo'
import { Price } from '~/components/Price'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useConventionCodeName } from '~/domains/convention/hooks/useConventionCodeName'
import { NavigationHideAppBarEffect } from '~/domains/navigation/components/NavigationHideAppBarEffect'
import { NavigationShowAppBarEffect } from '~/domains/navigation/components/NavigationShowAppBarEffect'
import { usePartnershipQueryParam } from '~/hooks/usePartnershipQueryParam'
import { LanguageSwitcherPopover } from '~/i18n/components/LanguageSwitcherPopover'
import { useTranslation } from '~/i18n/hooks/useTranslation'
import { SignupForm } from '../components/SignupForm'
import { TrustpilotWidget } from '../components/TrustpilotWidget'
import { signupIntentHasFirstTherapySessionFree, useSignupIntent } from '../hooks/useSignupIntent'
import { useTranslationVariablesTherapySessionCosts } from '../hooks/useTranslationVariablesTherapySessionCosts'

const FlexWithBackground = styled(Flex)`
  background-image: url('/signup/background.webp');
  background-position: center;
  background-size: cover;
`

const FlexWithNegativeMargin = styled(Flex)`
  margin: -1px;
`

const ListItem = ({ children }: PropsWithChildren) => (
  <Flex $align="center" $direction="row" $gap={8}>
    <Flex $basis="24px">
      <Icon colorName="white" name="check" size={20} />
    </Flex>
    <Flex $shrink={1}>{children}</Flex>
  </Flex>
)

export const SignupPage = () => {
  const intent = useSignupIntent()
  const conventionCodeName = useConventionCodeName()
  const partnership = usePartnershipQueryParam()

  const paymentPlanLeftDetail = useTranslation(`paymentPlan.${intent}.left.detail`)
  const paymentPlanRightDetail = useTranslation(`paymentPlan.${intent}.right.detail`)
  const i18Variables = useTranslationVariablesTherapySessionCosts(intent)
  const hasIntentFirstTherapySessionFree = signupIntentHasFirstTherapySessionFree(intent)

  return (
    <OverflowAuto $pb={0}>
      <FlexWithBackground $grow={1} $lgDirection="row-reverse">
        <MountOn mediaQuery="lt-lg">
          <NavigationShowAppBarEffect />
        </MountOn>
        <MountOn mediaQuery="gt-md">
          <NavigationHideAppBarEffect />
        </MountOn>
        <Flex $lgBasis="50%">
          <MountOn mediaQuery="gt-md">
            <Flex $align="center" $direction="row" $gap={8} $justify="flex-end" $pr={64} $pt={32}>
              <LanguageSwitcherPopover colorName="white" />
              <Divider colorName="white" direction="vertical" />
              <Text colorName="white" kind="paragraph">
                <Translation id="generic.doYouAnAccountAlready" />
                <Space />
                <Link as={ReactRouterLink} to="/login">
                  <Text colorName="white" fontWeight="600" kind="paragraph" textDecoration="underline">
                    <Translation id="actions.signin" />
                  </Text>
                </Link>
              </Text>
            </Flex>
          </MountOn>
          <Flex
            $gap={16}
            $grow={1}
            $lgJustify="space-between"
            $lgPx={64}
            $lgPy={64}
            $maxHeight="calc(100vh - 24px)"
            $px={16}
            $py={24}
            $shrink={1}
          >
            <Flex $gap={16}>
              <Text colorName="white" fontWeight="600" kind="public-h3">
                <Translation id={`signup.${intent}.title.variant`} />
              </Text>
              <MountOn mediaQuery="gt-md">
                <TranslationMarkdown
                  colorName="white"
                  fontWeight="400"
                  id={`signup.${intent}.subtitle.variant`}
                  kind="h3"
                />
                <Flex $pt={24}>
                  <TrustpilotWidget />
                </Flex>
              </MountOn>
            </Flex>
            {conventionCodeName || partnership ? (
              <Flex $gap={8}>
                {(['1', '2', '3'] as const).map((item) => (
                  <ListItem key={item}>
                    <TranslationMarkdown
                      colorName="white"
                      id={`signup.firstSessionFree.ul.convention.${item}`}
                      kind="paragraph"
                      values={{
                        ...i18Variables,
                        convention: conventionCodeName || partnership || '',
                      }}
                    />
                  </ListItem>
                ))}
              </Flex>
            ) : (
              <Flex $align="flex-start" $gap={8}>
                <Text colorName="white" kind="paragraph">
                  {!hasIntentFirstTherapySessionFree ? (
                    <MountOn mediaQuery="lt-lg">
                      <Translation id={`signup.${intent}.subtitle.variant`} />
                    </MountOn>
                  ) : (
                    <Translation id={`signup.${intent}.subtitleOverPrice.variant`} />
                  )}
                </Text>
                <Flex $direction="row">
                  <OverflowHidden
                    $basis={400}
                    $borderColorName="white"
                    $borderRadius={16}
                    $borderSize={1}
                    $direction="row"
                    $maxWidth="100%"
                    $minWidth={320}
                    $wrap="wrap"
                  >
                    <Flex $grow={1} $lgBasis="50%" $p={16} $shrink={1}>
                      <Price
                        $color="white"
                        amount={
                          i18Variables.bundleTherapySessionCost
                            ? i18Variables.therapySessionCost
                            : i18Variables.firstTherapySessionCost
                        }
                        detail={paymentPlanLeftDetail}
                      />
                      <Text colorName="white" kind="caption">
                        <Translation id={`paymentPlan.${intent}.left.planName`} />
                      </Text>
                    </Flex>
                    <FlexWithNegativeMargin
                      $borderColorName="white"
                      $borderSizeLeft={1}
                      $borderSizeTop={1}
                      $grow={1}
                      $lgBasis="50%"
                      $p={16}
                      $shrink={1}
                    >
                      <Price
                        $color="white"
                        amount={
                          i18Variables.bundleTherapySessionCost
                            ? i18Variables.bundleTherapySessionCost
                            : i18Variables.therapySessionCost
                        }
                        detail={paymentPlanRightDetail}
                      />
                      <Text colorName="white" kind="caption">
                        <Translation id={`paymentPlan.${intent}.right.planName`} />
                      </Text>
                    </FlexWithNegativeMargin>
                  </OverflowHidden>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex $backgroundColorName="lighter" $grow={1} $shrink={1}>
          <MountOn mediaQuery="gt-md">
            <Flex $align="flex-start" $pl={64} $pt={32}>
              <AppBarLogo />
            </Flex>
          </MountOn>
          <Flex $gap={16} $grow={1} $lgPx={64} $lgPy={64} $px={16} $py={24} $shrink={1} $xlPx={128}>
            <Text fontWeight="600" kind="h2">
              <Translation id={`generic.signup.${intent}.title`} />
            </Text>
            <SignupForm />
          </Flex>
        </Flex>
      </FlexWithBackground>
    </OverflowAuto>
  )
}
