import { isNeitherNullNorUndefined } from 'functions'
import { Icon } from 'icons'
import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Avatar, Text } from 'ui'
import { Button, Flex, Form, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { ReactHookFormCheckboxField, useReactHookFormContext } from '~/domains/react-hook-form'
import { useJournalingLogCanShare } from '~/routes/journalingLog/hooks/useJournalingLogCanShare'
import { FormValues } from '../types/formValues'
import { getJournalingLogShareWithMultipleTherapistsRoute } from '../utils/getJournalingLogShareWithMultipleTherapistsRoute'

type Props = {
  journalingLogId: string
  sharedWithTherapistUserIds?: string[] | null
}

export const StepChooseTherapists = ({ journalingLogId, sharedWithTherapistUserIds }: Props) => {
  const { ongoing } = usePatientTherapies()

  const sharableTherapists = ongoing
    .filter((therapy) => !['MYSELF_PSYCHIATRY', 'PATH_COACHING'].includes(therapy.therapyPath.type))
    .map((therapy) => therapy.therapist)
    .filter(isNeitherNullNorUndefined)

  const history = useHistory()

  const { close, open } = useModals()

  const modalId = `journalingLogShareWithMultipleTherapists:${journalingLogId}` as const

  const form = useReactHookFormContext<FormValues>()

  const checkboxes = form.watch()

  const { journalingLogCanShare } = useJournalingLogCanShare()

  const checkedAmount = useMemo(() => Object.values(checkboxes).filter(Boolean).length, [checkboxes])

  const onSubmit = useCallback(async () => {
    const { data } = await journalingLogCanShare()

    const checkedTherapists = form.watch()

    const checkedTherapistsIds = Object.entries(checkedTherapists)
      .filter(([, value]) => value)
      .map(([key]) => key)

    if (!data) {
      return
    }

    const filteredTherapistsIds = data.journalingLogCanShare.filter(
      ({ therapistId, status }) => therapistId && checkedTherapistsIds.includes(therapistId) && status,
    )

    if (data.journalingLogCanShare.map((el) => el.status).includes('CANNOT_SHARE')) {
      return
    }

    if (data.journalingLogCanShare.map((el) => el.status).includes('NO_THERAPIST')) {
      close(modalId)

      open('journalingLogShareNoTherapist')

      return
    }

    if (filteredTherapistsIds.map((el) => el.status).includes('NOT_BOOKED')) {
      history.replace(getJournalingLogShareWithMultipleTherapistsRoute('/not-booked'))

      return
    }

    if (filteredTherapistsIds.map((el) => el.status).includes('BOOKED_FIRST')) {
      history.replace(getJournalingLogShareWithMultipleTherapistsRoute('/booked-first'))

      return
    }

    history.replace(getJournalingLogShareWithMultipleTherapistsRoute('/booked-second'))
  }, [history, close, open, modalId, journalingLogCanShare, form])

  const isTherapistShared = (therapistId: string) =>
    !!sharedWithTherapistUserIds && sharedWithTherapistUserIds.includes(therapistId)

  return (
    <>
      <Form grow={1} onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
        <ModalCloseButton id={modalId} />

        <Text fontWeight="600" kind="h2">
          <Translation id="share.journaling.chooseTherapists.modal.title" />
        </Text>
        <Flex pt={8}>
          <Text kind="paragraph">
            <Translation id="share.journaling.chooseTherapists.modal.description" />
          </Text>
        </Flex>
        <OverflowAuto>
          <Flex pt={24}>
            {sharableTherapists.map((therapist, index) => (
              <Flex key={therapist?.id} pt={index > 0 ? 8 : 0}>
                <Flex
                  as="label"
                  background={isTherapistShared(therapist?.id) ? 'grey02' : 'white'}
                  borderColor="purple04"
                  borderRadius={16}
                  borderSize={1}
                  id={therapist?.id}
                  p={16}
                >
                  <ReactHookFormCheckboxField disabled={isTherapistShared(therapist.id)} name={therapist.id}>
                    <Flex direction="row" justify="space-between">
                      <Flex direction="row">
                        <Avatar name={therapist.fullName} size="xs" />

                        <Flex pl={8}>
                          <Text fontWeight="600" kind="paragraph">
                            {therapist.fullName}
                          </Text>
                        </Flex>
                      </Flex>

                      {isTherapistShared(therapist.id) && (
                        <Flex align="center" direction="row">
                          <Icon colorName="green-08" name="link" size={24} />
                          <Flex pl={8}>
                            <Text colorName="green-08" fontWeight="600" kind="caption">
                              <Translation id="journaling.shared" />
                            </Text>
                          </Flex>
                        </Flex>
                      )}
                    </Flex>
                  </ReactHookFormCheckboxField>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </OverflowAuto>

        <Flex pt={16}>
          <Button disabled={checkedAmount === 0} kind="primary" type="submit">
            <Translation id="actions.proceed" />
          </Button>
        </Flex>
      </Form>
    </>
  )
}
