import { SPACING_3XL, SPACING_XL } from 'design-tokens'
import { ReactNode } from 'react'
import styled from 'styled-components'
import { Card, Text } from 'ui'
import { Flex, media } from 'ui-deprecated'
import { Translation } from '~/components/Translation'

const DashedCard = styled(Card)`
  padding: ${SPACING_XL};
  border: 0;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23B5B2EAFF' stroke-width='4' stroke-dasharray='10' stroke-linecap='round'/%3e%3c/svg%3e");

  ${media.medium`
    padding: ${SPACING_3XL};
  `}
`

type Props = {
  children?: ReactNode
}

export const AgendaPatientEmptyStateCard = ({ children }: Props) => (
  <DashedCard>
    <Flex align="center">
      <Text colorName="grey-11" fontWeight="600" kind="h3" textAlign="center">
        <Translation id="agenda.noTherapySessionsBooked.title" />
      </Text>
    </Flex>

    {children}
  </DashedCard>
)
