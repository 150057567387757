import { gql, useQuery } from '@apollo/client'
import { toDate } from 'date-fns/fp'
import { nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import { useEffect, useState } from 'react'
import { MemoryRouter, Route, Switch } from 'react-router-dom'
import { Modal, useModals } from '~/domains/modals'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { MeEmailVerifiedQuery, MeEmailVerifiedQueryVariables } from '~/types/graphql'
import { useEmailVerificationContext } from '../hooks/useEmailVerificationContext'
import { getEmailVerificationRoute } from '../utils/getEmailVerificationRoute'
import { EmailVerificationModalDefaultRoute } from './EmailVerificationModalDefaultRoute'
import { EmailVerificationModalEditRoute } from './EmailVerificationModalEditRoute'

const QUERY = gql`
  query meEmailVerified {
    meEmailVerified
  }
`

const pollInterval = 5000

const initialEntries = [getEmailVerificationRoute('/')]

export const EmailVerificationModal = () => {
  const [polling, setPolling] = useState(false)
  const [verified, setVerified] = useState(false)
  const { email, updateQuery } = useCurrentUser()
  const { emailVerificationRequest } = useEmailVerificationContext()
  const { open } = useModals()

  const { data, startPolling, stopPolling } = useQuery<MeEmailVerifiedQuery, MeEmailVerifiedQueryVariables>(QUERY, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    open('emailVerification')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (polling) {
      return
    }

    emailVerificationRequest(email)

    setPolling(true)

    startPolling(pollInterval)
  }, [email, emailVerificationRequest, polling, startPolling])

  useEffect(() => {
    if (verified || !!data?.meEmailVerified) {
      setVerified(true)

      stopPolling()

      updateQuery((data) =>
        data?.me
          ? {
              ...data,
              me: { ...data.me, id: data.me?.id, emailVerifiedAt: pipe(nowInMilliseconds(), toDate) },
            }
          : data,
      )
    }
  }, [data, stopPolling, updateQuery, polling, verified])

  if (!!data?.meEmailVerified) {
    return null
  }

  return (
    <Modal closeOnClickOutside={false} id="emailVerification">
      <MemoryRouter initialEntries={initialEntries}>
        <Switch>
          <Route path={getEmailVerificationRoute('/edit')}>
            <EmailVerificationModalEditRoute />
          </Route>

          <Route path={getEmailVerificationRoute('/')}>
            <EmailVerificationModalDefaultRoute />
          </Route>
        </Switch>
      </MemoryRouter>
    </Modal>
  )
}
