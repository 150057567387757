import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'ui'
import { Button, Flex, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { ActivateConventionSuccessIcon } from '~/domains/convention/components/ActivateConventionSuccessIcon'
import { useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { getRoute } from '~/utils/getRoute'
import { removeLocalStorage } from '~/utils/localStorage/removeLocalStorage'

export const ReferralActivationModalSuccess = () => {
  const { close } = useModals()
  const history = useHistory()

  const cleanupLocalStorage = useCallback(() => {
    removeLocalStorage('referral-code-name')
  }, [])

  const onClose = useCallback(() => {
    cleanupLocalStorage()

    close('referralActivation')
  }, [cleanupLocalStorage, close])

  const onGoToSettingsPayments = useCallback(() => {
    cleanupLocalStorage()
    close('referralActivation')

    history.push(getRoute('/settings/payments'))
  }, [cleanupLocalStorage, close, history])

  return (
    <Flex>
      <ModalCloseButton id="referralActivation" onClose={cleanupLocalStorage} />

      <OverflowAuto>
        <Flex align="center" pt={16}>
          <ActivateConventionSuccessIcon />
        </Flex>

        <Flex pt={32}>
          <Text colorName="green-08" fontWeight="600" kind="h3" textAlign="center">
            <Translation id="referralCode.activation.success.title" />
          </Text>
        </Flex>

        <Flex pt={16}>
          <TranslationMarkdown id="referralCode.activation.success.body" kind="paragraph" textAlign="center" />
        </Flex>
      </OverflowAuto>

      <Flex pt={32}>
        <Button kind="primary" onClick={onGoToSettingsPayments}>
          <Translation id="referralCode.activation.success.goToSettingsPayments" />
        </Button>
        <Flex pt={8}>
          <Button kind="secondary" onClick={onClose}>
            <Translation id="referralCode.activation.success.close" />
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
