import { ButtonSize, ColorName, colors } from '@serenis-health/tokens'
import { Icon, IconProps } from 'icons'
import { ReactNode } from 'react'
import styled from 'styled-components'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'

const ArrowWrapper = styled(Flex).attrs({
  align: 'center',
  justify: 'center',
})`
  width: 40px;
  height: 40px;
`

type Attrs = {
  disabled: boolean
  iconBackground: ColorName
  iconBackgroundActive: ColorName
  iconBackgroundHover: ColorName
  iconColorName: ColorName
  iconColorNameActive: ColorName
  iconColorNameHover: ColorName
}

const Press = styled(Flex).attrs({
  as: 'button',
})<Attrs>`
  padding: 0;
  border: none;
  background: ${colors.transparent};
  outline: 0;
  font-family: inherit;
  cursor: pointer;
  appearance: none;

  .arrow {
    background-color: ${({ iconBackground }) => colors[iconBackground]};

    svg {
      color: ${({ iconColorName }) => colors[iconColorName]};
    }
  }

  &:hover {
    &:not([disabled]) {
      .arrow {
        background-color: ${({ iconBackgroundHover }) => colors[iconBackgroundHover]};

        svg {
          color: ${({ iconColorNameHover }) => colors[iconColorNameHover]};
        }
      }
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  &:active {
    &:not([disabled]) {
      .arrow {
        background-color: ${({ iconBackgroundActive }) => colors[iconBackgroundActive]};

        svg {
          color: ${({ iconColorNameActive }) => colors[iconColorNameActive]};
        }
      }
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  &:disabled,
  &[disabled] {
    opacity: 0.4;
    cursor: auto;
  }
`

type Props = {
  children: ReactNode
  disabled?: boolean
  iconBackground?: ColorName
  iconBackgroundActive?: ColorName
  iconBackgroundHover?: ColorName
  iconColorName?: ColorName
  iconColorNameActive?: ColorName
  iconColorNameHover?: ColorName
  iconName: IconProps['name']
  onClick: () => void
  size?: ButtonSize
  tabIndex?: number
  type?: 'button' | 'submit' | 'reset'
}

export const TertiaryButtonIconLeft = ({
  children,
  disabled = false,
  iconBackground = 'transparent',
  iconBackgroundActive = 'purple10',
  iconBackgroundHover = 'purple08',
  iconColorName = 'purple08',
  iconColorNameActive = 'white',
  iconColorNameHover = 'white',
  iconName,
  onClick,
  size,
  tabIndex,
  type = 'button',
}: Props) => (
  <Press
    align="center"
    direction="row"
    disabled={disabled}
    iconBackground={iconBackground}
    iconBackgroundActive={iconBackgroundActive}
    iconBackgroundHover={iconBackgroundHover}
    iconColorName={iconColorName}
    iconColorNameActive={iconColorNameActive}
    iconColorNameHover={iconColorNameHover}
    onClick={onClick}
    tabIndex={tabIndex}
    type={type}
  >
    <Flex pr={12}>
      <ArrowWrapper borderColor="purple04" borderRadius={40} borderSize={1} className="arrow">
        <Icon name={iconName} size={24} />
      </ArrowWrapper>
    </Flex>

    <Text colorName="purple-08" fontWeight="600" kind={size === 'small' ? 'caption' : 'paragraph'}>
      {children}
    </Text>
  </Press>
)
