import { MaxWidth640px, PositionRelative } from 'cdk'
import { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

export const FlowNavigationBar = ({ children }: Props) => (
  <PositionRelative $backgroundColorName="white" $py={16}>
    <MaxWidth640px $px={24}>{children}</MaxWidth640px>
  </PositionRelative>
)
