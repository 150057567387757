import { ColorName } from 'design-tokens'
import { ConventionCode } from '../types/conventionCode'

export const getDescriptionColorName = (status: ConventionCode['status']): ColorName => {
  switch (status) {
    case 'ACTIVE':
      return 'white'
    case 'INACTIVE':
    case 'EXPIRED':
    case 'COMPLETED':
    default:
      return 'black'
  }
}
