import { ColorName as UiColorName } from '@serenis-health/tokens'
import { ColorName } from 'design-tokens'
import { Icon, IconProps } from 'icons'
import styled from 'styled-components'
import { Flex, FlexProps } from 'ui-deprecated'

type Props = {
  borderColor?: UiColorName
  colorName?: ColorName
  iconName: IconProps['name']
  backgroundColor?: UiColorName
}

const ArrowWrapper = styled(Flex).attrs<FlexProps>(({ background }) => ({
  align: 'center',
  justify: 'center',
  background,
}))`
  width: 40px;
  height: 40px;
`

export const TertiaryButtonIcon = ({
  backgroundColor = 'transparent',
  borderColor = 'purple04',
  colorName,
  iconName,
}: Props) => (
  <ArrowWrapper
    background={backgroundColor}
    borderColor={borderColor}
    borderRadius={40}
    borderSize={1}
    className="arrow"
  >
    <Icon colorName={colorName} name={iconName} size={24} />
  </ArrowWrapper>
)
