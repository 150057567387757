import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { ProgressBar } from '~/components/ProgressBar'
import { getReservationRoutes } from '../utils/getReservationRoutes'

export const ReservationFlowProgressBar = () => {
  const location = useLocation()

  const value = useMemo((): number => {
    if (
      location.pathname.includes(getReservationRoutes('/book-by-matched-therapists')) ||
      location.pathname.includes(getReservationRoutes('/book-by-suggested-therapist'))
    ) {
      return 1
    }

    if (location.pathname.includes(getReservationRoutes('/send-verification-code'))) {
      return 2
    }

    if (location.pathname.includes(getReservationRoutes('/check-verification-code'))) {
      return 3
    }

    if (
      location.pathname.includes(getReservationRoutes('/submit')) ||
      location.pathname.includes(getReservationRoutes('/skip-booking'))
    ) {
      return 4
    }

    return 0
  }, [location])

  if (!value) {
    return null
  }

  return <ProgressBar total={4} value={value} />
}
