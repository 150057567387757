import { gql, useMutation } from '@apollo/client'
import { EmailVerificationVerifyMutation, EmailVerificationVerifyMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation emailVerificationVerify($input: EmailVerificationVerifyInput!) {
    emailVerificationVerify(input: $input) {
      ok
    }
  }
`

export const useEmailVerificationVerify = () =>
  useMutation<EmailVerificationVerifyMutation, EmailVerificationVerifyMutationVariables>(MUTATION)
