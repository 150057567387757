import { parse } from 'query-string'
import { useLocation } from 'react-router-dom'
import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { setLocalStorage } from '~/utils/localStorage/setLocalStorage'

export const useReferralCodeName = () => {
  const { search } = useLocation()

  const referralCodeName = getLocalStorage('referral-code-name')

  if (referralCodeName) {
    return referralCodeName
  }

  const { rfrrl } = parse(search)

  if (!rfrrl) {
    return null
  }

  const name = String(rfrrl)

  setLocalStorage('referral-code-name', name)

  return name
}
