import { gql, useMutation } from '@apollo/client'
import { EmailVerificationRequestMutation, EmailVerificationRequestMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation emailVerificationRequest($input: EmailVerificationRequestInput!) {
    emailVerificationRequest(input: $input) {
      ok
    }
  }
`

export const useEmailVerificationRequest = () =>
  useMutation<EmailVerificationRequestMutation, EmailVerificationRequestMutationVariables>(MUTATION)
