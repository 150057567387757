import { useMemo } from 'react'
import { useTherapist } from './useTherapist'

export const useTherapistProfessionTypeValue = () => {
  const { therapist } = useTherapist()

  const professionTypeValues = useMemo(
    () => therapist?.professionTypes.map(({ professionType }) => professionType.value),
    [therapist],
  )

  return {
    isCoach: !!professionTypeValues?.includes('COACH'),
    isPsychiatrist: !!professionTypeValues?.includes('PSYCHIATRIST'),
    isPsychotherapist: !!professionTypeValues?.includes('PSYCHOTHERAPIST'),
    isSexologist: !!professionTypeValues?.includes('SEXOLOGIST'),
  }
}
