import { breakpoints } from '@serenis-health/tokens'
import { useWindowSize } from './useWindowSize'

export const useBreakpoints = () => {
  const { width } = useWindowSize()

  return {
    isLarge: width > breakpoints.large,
    isMedium: width > breakpoints.medium,
    isMediumOnly: width > breakpoints.medium && width <= breakpoints.large,
    isSmall: width > breakpoints.small,
    isSmallOnly: width > breakpoints.small && width <= breakpoints.medium,
  }
}
