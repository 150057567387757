import { gql, useMutation } from '@apollo/client'
import { noop } from 'functions'
import { useCallback } from 'react'
import { EventType, TrackEventInput, TrackEventMutation, TrackEventMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation trackEvent($input: TrackEventInput!) {
    trackEvent(input: $input) {
      ok
    }
  }
`

type Param = {
  name: string
  payload?: TrackEventInput['payload']
}

export const useTrackEvent = (type: EventType) => {
  const [trackEvent] = useMutation<TrackEventMutation, TrackEventMutationVariables>(MUTATION)

  return useCallback(
    ({ name, payload }: Param) => {
      trackEvent({
        variables: {
          input: {
            name,
            type,
            payload,
          },
        },
      }).catch(noop)
    },
    [type, trackEvent],
  )
}
