import { COLOR_LIGHTER, COLOR_VIOLET_30, ColorName, cssvarColor } from 'design-tokens'
import { ReactNode } from 'react'
import styled from 'styled-components'
import { Flex, PositionAbsolute, PositionRelative } from 'ui-deprecated'

const spaceBetweenItems = 16

const bulletSize = 16

const dashedLineWidth = 2

export const VerticalDash = styled(Flex)`
  height: 100%;
  padding-left: ${bulletSize / 2 - 1}px;
  border-right: ${dashedLineWidth}px dashed ${COLOR_VIOLET_30};
`

type CircleProps = {
  colorName: ColorName
}

type BulletProps = {
  circleInnerColorName?: ColorName
  circleOuterColorName: ColorName
}

const InnerCircle = styled(Flex).attrs(() => ({
  borderRadius: 5,
}))<CircleProps>`
  width: 10px;
  height: 10px;
  background: ${({ colorName }) => cssvarColor(colorName)};
`

const OuterCircle = styled(Flex).attrs(() => ({
  borderRadius: bulletSize / 2,
  borderSize: 1,
}))<CircleProps>`
  width: ${bulletSize}px;
  height: ${bulletSize}px;
  border-color: ${({ colorName }) => cssvarColor(colorName)};
  background-color: ${COLOR_LIGHTER};
`

const Bullet = ({ circleInnerColorName, circleOuterColorName }: BulletProps) => (
  <PositionRelative>
    <OuterCircle colorName={circleInnerColorName ?? circleOuterColorName} />

    <PositionAbsolute left="3px" top="3px" zIndex={100}>
      <InnerCircle colorName={circleOuterColorName} />
    </PositionAbsolute>
  </PositionRelative>
)

type ItemProps = BulletProps & {
  children: ReactNode
}

export const TimelineItem = ({ children, circleInnerColorName, circleOuterColorName }: ItemProps) => (
  <Flex align="flex-start" direction="row" pt={spaceBetweenItems}>
    <Bullet circleInnerColorName={circleInnerColorName} circleOuterColorName={circleOuterColorName} />

    <Flex grow={1} pl={16} shrink={1}>
      {children}
    </Flex>
  </Flex>
)
