import { Icon } from 'icons'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Tooltip, TooltipContent, TooltipTrigger } from '~/components/FloatingUI/Tooltip'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'

export const KeepYourPhoneClose = () => (
  <Tooltip>
    <TooltipTrigger>
      <Icon colorName="purple-08" name="info-circle" size={24} />
    </TooltipTrigger>
    <TooltipContent>
      <Text fontWeight="600" kind="paragraph">
        <Translation id="payments.3ds.title" />
      </Text>

      <Flex pt={8}>
        <TranslationMarkdown colorName="black" id="payments.3ds.description" kind="paragraph" />
      </Flex>

      <Flex pt={24}>
        <Text fontWeight="600" kind="paragraph">
          <Translation id="payments.3ds.subtitle" />
        </Text>
      </Flex>

      <Flex pt={8}>
        <TranslationMarkdown colorName="black" id="payments.3ds.chargeDescription" kind="paragraph" />
      </Flex>
    </TooltipContent>
  </Tooltip>
)
