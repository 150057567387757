import { range } from 'arrays'
import { COLOR_VIOLET_40, COLOR_VIOLET_50 } from 'design-tokens'
import ContentLoader from 'react-content-loader'
import { CardBox } from 'ui-deprecated'

const props = {
  backgroundColor: COLOR_VIOLET_40,
  foregroundColor: COLOR_VIOLET_50,
  speed: 1.5,
  viewBox: '0 0 400 240',
}

const size = 32

export const CalendarSkeleton = () => (
  <CardBox borderColor="white" p={8}>
    <ContentLoader {...props}>
      {range(7, (index) => (
        <rect
          key={`1-${index}`}
          height={size}
          rx={size}
          ry={size}
          width={size}
          x={index * size + index * 26 + 12}
          y="8"
        />
      ))}

      {range(7, (index) => (
        <rect
          key={`2-${index}`}
          height={size}
          rx={size}
          ry={size}
          width={size}
          x={index * size + index * 26 + 12}
          y="48"
        />
      ))}

      {range(7, (index) => (
        <rect
          key={`3-${index}`}
          height={size}
          rx={size}
          ry={size}
          width={size}
          x={index * size + index * 26 + 12}
          y="88"
        />
      ))}

      {range(7, (index) => (
        <rect
          key={`4-${index}`}
          height={size}
          rx={size}
          ry={size}
          width={size}
          x={index * size + index * 26 + 12}
          y="128"
        />
      ))}

      {range(7, (index) => (
        <rect
          key={`5-${index}`}
          height={size}
          rx={size}
          ry={size}
          width={size}
          x={index * size + index * 26 + 12}
          y="168"
        />
      ))}
    </ContentLoader>
  </CardBox>
)
