import { isSameMonth } from 'date-fns/fp'
import { format, FormatDateEnum } from 'dates'
import { pipe } from 'fp-ts/function'
import { Icon } from 'icons'
import { Text } from 'ui'
import { Flex, Pressable } from 'ui-deprecated'
import { useCalendar } from '../hooks/useCalendar'

type Props = {
  onNext: () => void
  onPrevious: () => void
}

export const CalendarNavigation = ({ onNext, onPrevious }: Props) => {
  const { date, disabledBeforeDate, loading } = useCalendar()

  return (
    <Flex align="center" direction="row" grow={1} justify="space-between" pb={8} shrink={1}>
      <Flex align="center" basis="14.28%">
        {!isSameMonth(disabledBeforeDate, date) && (
          <Pressable
            align="center"
            colorName="purple08"
            colorNameHover="purple10"
            disabled={loading}
            onClick={onPrevious}
          >
            <Icon name="arrow-left" size={20} />
          </Pressable>
        )}
      </Flex>

      <Flex align="center" grow={1} px={16}>
        <Text fontWeight="600" kind="paragraph">
          {pipe(date, format(FormatDateEnum.MONTH_NAME))}
        </Text>
      </Flex>

      <Flex align="center" basis="14.28%">
        <Pressable align="center" colorName="purple08" colorNameHover="purple10" disabled={loading} onClick={onNext}>
          <Icon name="arrow-right" size={20} />
        </Pressable>
      </Flex>
    </Flex>
  )
}
