import { Spacing } from '@serenis-health/tokens'
import { parseISO } from 'date-fns/fp'
import { FormatDateEnum, format } from 'dates'
import { SPACING_MD_VALUE, SPACING_XL_VALUE } from 'design-tokens'
import { pipe } from 'fp-ts/function'
import { capitalize } from 'strings'
import styled from 'styled-components'
import { Text } from 'ui'
import { Flex, media } from 'ui-deprecated'

const Container = styled(Flex)`
  position: sticky;
  top: 0;

  ${media.smallOnly`
    padding-top: ${SPACING_MD_VALUE};
    margin: 0 -${SPACING_XL_VALUE};
    background: white;
    z-index: 1000;
  `}
`

type Props = {
  day: string
  pt?: Spacing
}

export const AvailabilityDayHeader = ({ day, pt = 0 }: Props) => (
  <Container
    borderColor="purple02"
    borderSize={2}
    mdBorderSize={0}
    mdPb={0}
    mdPl={0}
    mdPr={8}
    mdPt={pt}
    pb={16}
    pl={32}
    pr={0}
  >
    <Text data-test-id="availability-day-header" fontWeight="600" kind="paragraph">
      {`${pipe(day, parseISO, format(FormatDateEnum.WEEK_DAY), capitalize)} ${pipe(
        day,
        parseISO,
        format(FormatDateEnum.DATE_MONTH_NAME),
        capitalize,
      )}`}
    </Text>
  </Container>
)

export const AvailabilityDayHeaderOnMultipleLines = ({ day, pt = 0 }: Props) => (
  <Container
    borderColor="purple02"
    borderSize={2}
    mdBorderSize={0}
    mdPb={0}
    mdPl={0}
    mdPr={8}
    mdPt={pt}
    pb={16}
    pl={32}
    pr={0}
  >
    <Text data-test-id="availability-day-header" fontWeight="600" kind="paragraph">
      <Flex>{pipe(day, parseISO, format(FormatDateEnum.WEEK_DAY), capitalize)}</Flex>
      <Flex pt={4}>{pipe(day, parseISO, format(FormatDateEnum.DATE_MONTH_NAME), capitalize)}</Flex>
    </Text>
  </Container>
)
