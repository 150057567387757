import { useAuthState } from '~/domains/auth/components/AuthStateProvider'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { NavigationProps } from '~/domains/navigation/TabNavigation/types'
import { useNavigation } from '../hooks/useNavigation'
import { PatientTabNavigation } from './PatientTabNavigation'
import { TherapistTabNavigation } from './TherapistTabNavigation'

export const TabNavigation = ({ direction }: NavigationProps) => {
  const { isAuthenticated } = useAuthState()
  const { isVisibleTabNavigation } = useNavigation()
  const isTherapist = useUserActingAsGroup() === 'therapist'

  if (!isVisibleTabNavigation) {
    return null
  }

  if (!isAuthenticated) {
    return null
  }

  if (isTherapist) {
    return <TherapistTabNavigation direction={direction} />
  }

  return <PatientTabNavigation direction={direction} />
}
